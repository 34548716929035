<template>
    <div class="d-flex">
        <div>
            <h5 class="form-label tx-14 mg-b-5" :class="mandatory?'required':''">
                {{label}}<small v-if="description" id="sub-helper-text" class="form-text text-muted">({{description}})</small>
            </h5>
            <p style="margin-inline-start: 1rem;">{{data || data === 0? roundOff(data): 'NA'}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DisplayJsonField",
        props: ["label", "data", "mandatory", "description"],
        methods: {
            roundOff(n){
                if(!isNaN(n) && Number(n) % 1 !== 0) {
                    n = Number(n);
                    return n.toFixed(2)
                }else {
                    return n
                }
            }
        }
    }
</script>

<style scoped>

</style>