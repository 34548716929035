<template>
    <router-view/>
</template>

<script>
    export default {
        name: 'App',
        mounted() {
            this.$store.commit('TERMS_AND_CONDITIONS_SEEN', true)
        }
    }
</script>

<style>
</style>
