<template>
    <div class="d-flex">
        <h5 class="form-label tx-14" style="width: 20px;" v-if="$t(`${label}.pointer`)">
            <span class="form-order">{{$t(`${label}.pointer`)}}</span>
        </h5>
        <div>
            <h5 class="form-label tx-14 mg-b-5" :class="mandatory?'required':''">{{$t(`${label}.text`)}}</h5>
            <p>{{data}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DisplayField",
        props: ["label", "data", "mandatory"]
    }
</script>

<style scoped>

</style>