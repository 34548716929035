<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card card-dashboard-twelve">
                <div class="card-header border-bottom">
                    <h5 class="card-title">{{header}} : {{total}}</h5>
                </div>
                <div class="card-body">
                    <h5 class="text-center text-muted m-5" v-if="errorMessage">
                        {{errorMessage}}
                    </h5>
                    <template v-else>
                        <div class="table-responsive" v-if="jobs.length > 0">
                            <slot name="table" :jobs="jobs" :get_jobs="getJobs"></slot>
                        </div>
                        <h5 class="text-center text-muted m-5" v-else>
                            {{$t('page.job.not_found')}}
                        </h5>
                    </template>
                </div>

                <div class="card-footer">
                    <pagination v-if="jobs.length > 0" :page="page" :total="total" @pager-change="handlePagerChange"/>
                    <div class="row ht-40" v-else></div>
                </div>
            </div><!-- card -->
        </div><!-- col -->
    </div>
</template>

<script>
    import Pagination from "../../Pagination";

    export default {
        name: "JobListTemplate",
        props: ['header', 'status', 'result'],
        components: {Pagination},
        data() {
            return {
                jobs: [],
                total: 0,
                page: 0,
                perPage: localStorage.getItem('pageSize') || 10,
                errorMessage: "Loading ...",
            };
        },
        mounted() {
            this.getJobs();
        },
        methods: {
            async getJobs() {
                try {
                    let {data}        = await this.$api.get('jobs', {params: {page: this.page, per_page: this.perPage, status: this.status, result: this.result}});
                    this.jobs         = data.jobs;
                    this.total        = data.total;
                    this.errorMessage = null;
                } catch(e) {
                    this.errorMessage = "Failed to load jobs";
                }

            },
            async handlePagerChange(e) {
                this.page    = e.page;
                this.perPage = e.per_page;
                await this.getJobs();
            }
        }
    }
</script>

<style scoped>
</style>