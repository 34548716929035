<template>
    <a class="badge badge-primary ptpx-6" href="javascript:void(0);" v-if="status === 'Draft'">{{$t('statuses.draft')}}</a>
    <a class="badge badge-light ptpx-6" href="javascript:void(0);" v-else-if="status === 'Cancelled'">{{$t('statuses.cancelled')}}</a>
    <a class="badge badge-warning ptpx-6" href="javascript:void(0);" v-else-if="status === 'AwaitingApproval'">{{$t('statuses.awaiting_approval')}}</a>
    <a class="badge badge-info ptpx-6" href="javascript:void(0);" v-else-if="status === 'Revision'">{{$t('statuses.revision')}}</a>
    <a class="badge badge-success ptpx-6" href="javascript:void(0);" v-else-if="status === 'Approved'">{{$t('statuses.approved')}}</a>
    <a class="badge badge-danger ptpx-6" href="javascript:void(0);" v-else-if="status === 'Rejected'">{{$t('statuses.rejected')}}</a>
    <span v-else></span>
</template>

<script>
    export default {
        name: "CoopStatus",
        props: ["status"]
    }
</script>

<style scoped>
    .ptpx-6 {
        padding-top: 6px;
    }
</style>
