<template>
    <div class="row row-sm">
        <div class="col-12">
            <Form @submit="upload()" ref="form" v-slot="{errors}">
            <div class="card card-dashboard-twelve">
                <div class="card-header border-bottom">
                    <h5 class="card-title">{{$t('page.shg.card_label.import_shg')}}</h5>
                    <p class="card-subtitle text-muted mg-b-15">{{$t('page.shg.card_label.import_sub')}}
                        <a @click="downloadTemplate" href="javascript:void(0)" class="font-weight-bold ml-2" title="Download template">
                            <i class="fa fa-download"/>
                        </a>
                    </p>
                </div>
                <div class="card-body">
                    <div class="row align-items-center mg-b-10">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">{{$t('page.shg.coop.form_label')}}</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <Multiselect
                                    v-model="coop_id"
                                    :placeholder="$t('page.shg.coop.placeholder')"
                                    :close-on-select="true"
                                    :options="coops"
                                    class="multiselect-blue"
                                    @search-change="getCoops($event)"
                                    :searchable="true"/>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="font-weight-bold form-label mg-b-0">{{$t('page.shg.select_files')}}</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <div class="custom-file">
                                <input @change="handleFileChange" accept=".xls,.xlsx" class="custom-file-input" id="customFile"
                                       ref="file" type="file">
                                <label class="custom-file-label" for="customFile" v-if="file_name">{{file_name}} {{$t('page.shg.file_selected')}}</label>
                                <label class="custom-file-label" for="customFile" v-else>{{$t('page.shg.choose_files')}}</label>
                            </div>
                        </div><!-- col -->
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right btn-toolbar">
                        <button type="submit" class="btn btn-success mr-2">{{$t('page.shg.button.submit')}}</button>
                        <button type="reset" class="btn btn-light">{{$t('page.shg.button.reset')}}</button>
                    </div>
                </div>
            </div><!-- card -->
            </Form>
        </div><!-- col -->
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import XLSX from "xlsx";
    import Multiselect from '@vueform/multiselect'

    export default {
        name: "ImportShgs",
        components: {Form, Field, Multiselect},
        data() {
            return {
                template_path: '/COOP_SHGS.xlsx',
                coop_id: null,
                publicPath: process.env.BASE_URL,
                uploading: false,
                calls: [],
                file_name: null,
                shg_array: [],
                coops: [],
                coop: null
            }
        },
        mounted() {
            this.getCoops()
        },
        methods: {
            async getCoops(search) {
                try {
                    let {data} = await this.$api('coops', {
                        params: {
                            is_valid: true,
                            page: 0,
                            per_page: 1000,
                            search: search
                        }
                    });
                    this.coops = data.coops.map(coop=> ({
                        value: coop.coop_id,
                        label: coop.name+' ('+coop.registration.number+')'
                    }));
                } catch (e) {

                }
            },
            async handleFileChange() {
                if(this.$refs.file.files.length>0) {
                    try {
                        let file = this.$refs.file.files[0];
                        this.file_name = file.name;
                        let reader = new FileReader();
                        reader.onload = async (e) => {
                            try {
                                let data = new Uint8Array(e.target.result);
                                let workbook = XLSX.read(data, {type: 'array'});
                                this.shg_array = XLSX.utils.sheet_to_json(workbook.Sheets["SHG"]);
                            } catch (e) {
                                console.error(e);
                            } finally {
                            }
                        };
                        reader.readAsArrayBuffer(file);
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            async upload() {
                console.log(this.coop)
                if(this.$refs.file.files.length>0) {
                    try {
                        this.shg_array.forEach(shg => {
                            this.calls.push(this.addShgs({
                                coop_id: this.coop_id,
                                name: shg.Name,
                                address: shg.Address,
                                contact_number: shg['Contact Number'].toString(),
                                no_of_members: shg['No of Members'],
                                notes: shg['Notes'],
                            }))
                        });
                        await Promise.all(this.calls);
                        this.$toast.success(this.$t('toastr.create-shg-bulk'));
                        await this.$router.push({name: 'shgs'});
                    } catch (e) {
                        console.error(e)
                    }
                }else{
                    this.$toast.warning(this.$t('toastr.shg-upload-warning'));
                }
            },
            async addShgs(coop) {
                try {
                    await this.$api.post('/shgs', coop);
                } catch (e) {

                }
            },
            reset() {
                this.uploading = false;
                this.$refs.file = null;
                this.file_name = null;
            },
            downloadTemplate() {
                const link = document.createElement('a');
                // link.href = '/COOP_SHGS.xlsx';
                link.download = 'COOP_SHGS.xlsx';
                link.click();
            }
        }
    }
</script>

<style scoped>

</style>