<template>
    <div>
        <div class="az-content-header d-block d-md-flex">
            <div>
                <h2 class="mg-b-5 mg-b-lg-8" v-if="component === 'viewcoop'">{{coop_number}}</h2>
                <h2 class="mg-b-5 mg-b-lg-8" v-else>{{$t($route.meta.title)}}</h2>
            </div>
            <i class="far fa-2x fa-arrow-alt-circle-left back d-none d-lg-block" :title="$t('layout.back')" @click="back" />
        </div><!-- az-content-header -->

        <div class="az-content-body">
            <router-view/>
        </div><!-- az-content-body -->
    </div>
</template>

<script>
    export default {
        name: "Content",
        watch: {
            $route(to, from) {
                this.component = to.name;
                this.coop_number = to.params ? to.params.coopnum : null;
            }
        },
        data() {
            return {
                component: null,
                title: null,
                coop_number: null
            }
        },
        created() {
            this.component = this.$route.name;
            this.coop_number = this.$route.params ? this.$route.params.coopnum : null;
        },
        methods: {
            back() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>

</style>