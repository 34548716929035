<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
                <router-link :to="{name: 'home'}" class="nav-item nav-link">
                    <i class="fa fa-home mr-2"/>{{$t('menu.dashboard')}}
                </router-link>
                <li class="nav-item dropdown">
                    <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#id1" id="coop_management">
                        <i class="fa fa-users-cog mr-2"/>{{$t('menu.coop_management')}}
                    </a>
                    <div aria-labelledby="id1" class="dropdown-menu" id="id1">
                        <router-link :to="{name: 'coops'}" class="dropdown-item">
                            <i class="fa fa-users mr-2"/>{{$t('menu.coops')}}
                        </router-link>
                        <router-link :to="{name: 'importcoop'}" class="dropdown-item" v-if="!currentUser.roles.includes('ZoneOfficer')">
                            <i class="fa fa-file-import mr-2"/>{{$t('menu.import')}}
                        </router-link>
                        <router-link :to="{name: 'addcoop'}" class="dropdown-item" v-if="!currentUser.roles.includes('ZoneOfficer')">
                            <i class="fa fa-user-plus mr-2"/>{{$t('menu.add')}}
                        </router-link>
                        <!--                                                    <router-link :to="{name: 'coops-report'}" class="dropdown-item">-->
                        <!--                                                        <i class="fa fa-clipboard-check mr-2"/>{{$t('menu.report')}}-->
                        <!--                                                    </router-link>-->
                    </div>
                </li>
                <li class="nav-item dropdown" v-if="!currentUser.roles.includes('ZoneOfficer')">
                    <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#id2" id="navbarDropdownMenuLink">
                        <i class="fa fa-tasks mr-2"/>{{$t('menu.jobs')}}
                    </a>
                    <div aria-labelledby="id2" class="dropdown-menu" id="id2">
                        <router-link :to="{ name: 'jobs' }" class="dropdown-item">
                            <i class="fa fa-clipboard-list mr-2"/>{{$t('menu.all')}}
                        </router-link>
                        <router-link :to="{ name: 'jobsfailed' }" class="dropdown-item">
                            <i class="fa fa-exclamation-circle mr-2"/>{{$t('menu.failed')}}
                        </router-link>
                        <router-link :to="{ name: 'jobspending' }" class="dropdown-item">
                            <i class="fa fa-hourglass-half mr-2"/>{{$t('menu.pending')}}
                        </router-link>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#id3" id="coop_report">
                        <i class="fa fa-clipboard-list mr-2"/>{{$t('menu.report')}}
                    </a>
                    <div aria-labelledby="id3" class="dropdown-menu" id="id3">
                        <router-link :to="{name: 'coops-report'}" class="dropdown-item">
                            <i class="fa fa-clipboard-check mr-2"/>{{$t('menu.view_report')}}
                        </router-link>
                    </div>
                </li>
                <a class="nav-item nav-link" href="https://help.wb-coopcensus.in/" target="_blank">
                    <i class="fa fa-lg fa-question-circle mr-2"/>{{$t('menu.help')}}
                </a>
            </ul>
        </div>
    </nav>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Sidebar",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                selected_menu: 'dashboard',
                menu: this.menu
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
            },
        }
    }
</script>

<style scoped>
    .bd-rnd {
        height: 40px !important;
        width: 40px !important;
        border: 2px solid #ccc;
        background-color: white;
    }

    .custom-logo {
        width: 205px;
    }

    .nav-link {
        color: #1c273c;
        font-size: 15px;
        font-weight: 500;
    }

    .navbar-light {
        box-shadow: 0 0 30px rgb(28 39 60 / 8%);
        border-bottom: 1px solid rgba(28, 39, 60, 0.12);
    }

    .navbar-light .navbar-nav .nav-link.active {
        color: #3a86ff;
    }

    .nav-item {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px dotted #e3e7ed;
    }


</style>
