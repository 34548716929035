<template>
    <h1>About Page</h1>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

</style>