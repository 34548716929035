<template>
    <div class="az-error-wrapper">
        <h1>404</h1>
        <h2>Oopps. The page you were looking for doesn't exist.</h2>
        <h6>You may have mistyped the address or the page may have moved.</h6>
        <router-link :to="{name: 'home'}" class="btn btn-outline-indigo">Back to Home</router-link>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>