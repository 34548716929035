<template>
    <div class="card card-dashboard-twelve">
        <div class="card-header">
            <div class="row align-items-center mg-b-10">
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <label class="form-label font-weight-bold required">Select Template</label>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-6">
                    <Field as="select" class="form-control" name="template"
                           rules="required" v-model="selected_template">
                        <option :value="null">Choose and select template</option>
                        <option :value="template.id" v-for="template in templates">{{ template.type_of_coop }}</option>
                    </Field>
                </div>
            </div>
            <div class="row align-items-center mg-b-10">
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <label class="form-label font-weight-bold required">Select Year</label>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-6">
                    <Field as="select" class="form-control" name="template"
                           rules="required" v-model="selected_year">
                        <option :value="null">Choose and select year</option>
                        <option :value="year" v-for="year in years">{{ year }}</option>
                    </Field>
                </div>
            </div>
        </div>
        <div class="card-body" v-if="tabs.length>0">
            <Form @submit="submit" ref="form" v-slot="{errors}">
                <div class="row">
                    <div class="col-lg-4 col-xl-3">
                        <!-- Nav tabs -->
                        <div aria-orientation="vertical" class="nav flex-column nav-pills" id="v-pills-tab" role="tablist">
                            <a :class="{active: activeTab === tab.index}"
                               :key="index" @click="navigate(tab.index)"
                               aria-selected="true" class="nav-link" data-toggle="pill" v-bind:href="'#'+tab.name" v-for="(tab, index) in tabs">{{ tab.name }}</a>
                        </div>
                    </div>
                    <div class="col-lg-8 col-xl-9">
                        <div class="tab-content" id="v-pills-tabContent">
                            <div :class="{'d-none': activeTab !== tab.index}" :id="tab.name" :key="index" class="tab-pane active" v-for="(tab, index) in tabs">
                                <div class="row row-sm" v-if="index === 0">
                                    <div class="col-12">
                                        <div class="card-header border-bottom">
                                            <h5 class="card-title">General</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold required">{{ $t('page.coop.range.text') }}</label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.range }" @change="getBlocks(coop.range)" as="select" class="form-control"
                                                           name="range" rules="required"
                                                           v-model="coop.range">
                                                        <option :value="null">{{ $t('page.coop.range.placeholder') }}</option>
                                                        <option :value="range.range_id" v-for="range in ranges">{{ range.name }}</option>
                                                    </Field>
                                                    <span class="invalid-feedback" v-if="errors.range">{{ errors.range }}</span>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold required">{{ $t('page.coop.municipality.text') }}</label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.municipality }" as="select" class="form-control"
                                                           name="municipality" rules="required"
                                                           v-model="coop.municipality">
                                                        <option :value="null">{{ $t('page.coop.municipality.placeholder') }}</option>
                                                        <option :value="block.name" v-for="block in blocks">{{ block.name }}</option>
                                                    </Field>
                                                    <span class="invalid-feedback" v-if="errors.municipality">{{ errors.municipality }}</span>
                                                </div>
                                            </div>
                                            <h6 class="text-divider text-left"><span class="pl-0">{{ $t('page.coop.basic.text') }}</span></h6>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.name.pointer') }}</span>{{ $t('page.coop.name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.name }" :placeholder="$t('page.coop.name.placeholder')" as="input"
                                                           class="form-control" name="name"
                                                           rules="required"
                                                           v-model="coop.name"/>
                                                    <span class="invalid-feedback" v-if="errors.name">{{ errors.name }}</span>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.address.pointer') }}</span>{{ $t('page.coop.address.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.address }" :placeholder="$t('page.coop.address.placeholder')" as="textarea"
                                                           class="form-control" name="address" rows="3"
                                                           rules="required"
                                                           v-model="coop.address"/>
                                                    <span class="invalid-feedback" v-if="errors.address">{{ errors.address }}</span>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.class.pointer') }}</span>{{ $t('page.coop.class.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.class }"
                                                           as="select"
                                                           class="form-control"
                                                           name="class"
                                                           v-model="coop.class">
                                                        <option :value="null">{{ $t('page.coop.class.placeholder') }}</option>
                                                        <option :value="classes" v-for="classes in society ">{{ classes }}</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.area.pointer') }}</span>{{ $t('page.coop.area.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.area.placeholder')" as="input" class="form-control"
                                                           name="area" v-model="coop.area"/>
                                                </div>
                                            </div>
                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.registration.pointer') }} {{ $t('page.coop.registration.text') }}
                                                </span>
                                            </h6>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.registration_number.pointer') }}</span>{{ $t('page.coop.registration_number.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.registration_number }" :placeholder="$t('page.coop.registration_number.placeholder')" as="input"
                                                           class="form-control" name="registration_number"
                                                           rules="required"
                                                           v-model="coop.registration.number"/>
                                                    <span class="invalid-feedback" v-if="errors.registration_number">{{ errors.registration_number }}</span>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.registration_date.pointer') }}</span>{{ $t('page.coop.registration_date.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.registration_date }" :placeholder="$t('page.coop.registration_date.pointer')" as="input" class="form-control"
                                                           name="registration_date" rules="required"
                                                           type="date"
                                                           v-model="coop.registration.date"/>
                                                    <span class="invalid-feedback" v-if="errors.registration_date">{{ errors.registration_date }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.registration_addon_number.pointer') }}</span>{{ $t('page.coop.registration_addon_number.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{ 'is-invalid' : errors.registration_addon_number }" :placeholder="$t('page.coop.registration_addon_number.placeholder')"
                                                           :rules="validateAddonNumber" as="input"
                                                           class="form-control"
                                                           name="registration_addon_number" v-model="coop.registration.addon_number"/>
                                                    <span class="invalid-feedback" v-if="errors.registration_addon_number">{{ errors.registration_addon_number }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.registration_addon_date.pointer') }}</span>{{ $t('page.coop.registration_addon_date.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.registration_addon_date.placeholder')" as="input" class="form-control" name="registration_addon_date"
                                                           type="date" v-model="coop.registration.addon_date"/>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                              <span class="pl-0">
                                                {{ $t('page.coop.members_and_voters.pointer') }} {{ $t('page.coop.members_and_voters.text') }}
                                                </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.members.pointer') }}</span>{{ $t('page.coop.members.text') }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.individual_voters.pointer') }}</span>{{ $t('page.coop.individual_voters.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.individual_voters }" :placeholder="$t('page.coop.individual_voters.placeholder')" as="input"
                                                           class="form-control"
                                                           name="individual_voters" rules="required|integer|min_value:0"
                                                           type="number"
                                                           v-model="coop.members.individual_voters.total"/>
                                                    <span class="invalid-feedback" v-if="errors.individual_voters">{{ errors.individual_voters }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.nominal.pointer') }}</span>{{ $t('page.coop.nominal.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.nominal }" :placeholder="$t('page.coop.nominal.placeholder')" as="input" class="form-control"
                                                           name="nominal" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.nominal"/>
                                                    <span class="invalid-feedback" v-if="errors.nominal">{{ errors.nominal }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.members_groups.pointer') }}</span>{{ $t('page.coop.members_groups.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.members_groups }" :placeholder="$t('page.coop.members_groups.placeholder')" as="input" class="form-control"
                                                           name="members_groups" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.self_help_groups"/>
                                                    <span class="invalid-feedback" v-if="errors.members_groups">{{ errors.members_groups }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.members_societies.pointer') }}</span>{{ $t('page.coop.members_societies.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.members_societies }" :placeholder="$t('page.coop.members_societies.placeholder')" as="input"
                                                           class="form-control"
                                                           name="members_societies" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.coop_societies"/>
                                                    <span class="invalid-feedback" v-if="errors.members_societies">{{ errors.members_societies }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10 mg-t-30">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.delegates.pointer') }}</span>{{ $t('page.coop.delegates.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.delegates }" :placeholder="$t('page.coop.delegates.placeholder')" as="input" class="form-control"
                                                           name="delegates" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.delegates"/>
                                                    <span class="invalid-feedback" v-if="errors.delegates">{{ errors.delegates }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10 mg-t-30">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.genders.pointer') }}</span>{{ $t('page.coop.genders.text') }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_male.pointer') }}</span>{{ $t('page.coop.voters_male.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_male }" :placeholder="$t('page.coop.voters_male.placeholder')" as="input" class="form-control"
                                                           name="voters_male" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.male"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_male">{{ errors.voters_male }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_female.pointer') }}</span>{{ $t('page.coop.voters_female.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_female }" :placeholder="$t('page.coop.voters_male.placeholder')" as="input" class="form-control"
                                                           name="voters_female" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.female"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_female   ">{{ errors.voters_female }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10 mg-t-30">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.castes.pointer') }}</span>{{ $t('page.coop.castes.text') }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_general.pointer') }}</span>{{ $t('page.coop.voters_general.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_general }" :placeholder="$t('page.coop.voters_general.placeholder')" as="input" class="form-control"
                                                           name="voters_general" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.general"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_general   ">{{ errors.voters_general }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_sc.pointer') }}</span>{{ $t('page.coop.voters_sc.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_sc }" :placeholder="$t('page.coop.voters_sc.placeholder')" as="input" class="form-control"
                                                           name="voters_sc" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.sc"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_sc">{{ errors.voters_sc }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_st.pointer') }}</span>{{ $t('page.coop.voters_st.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_st }" :placeholder="$t('page.coop.voters_st.placeholder')" as="input" class="form-control"
                                                           name="voters_st" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.st"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_st">{{ errors.voters_st }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_obc.pointer') }}</span>{{ $t('page.coop.voters_obc.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_obc }" :placeholder="$t('page.coop.voters_obc.placeholder')" as="input" class="form-control"
                                                           name="voters_obc" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.obc"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_obc">{{ errors.voters_obc }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.voters_minority.pointer') }}</span>{{ $t('page.coop.voters_minority.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.voters_minority }" :placeholder="$t('page.coop.voters_minority.placeholder')" as="input" class="form-control"
                                                           name="voters_minority" rules="integer|min_value:0"
                                                           type="number" v-model="coop.members.individual_voters.minority"/>
                                                    <span class="invalid-feedback" v-if="errors.voters_minority">{{ errors.voters_minority }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                              <span class="pl-0">
                                                {{ $t('page.coop.employees.pointer') }} {{ $t('page.coop.employees.text') }}
                                            </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.employees_total.pointer') }}</span>{{ $t('page.coop.employees_total.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_total }" :placeholder="$t('page.coop.employees_total.placeholder')" :rules="employeeTotalValidation"
                                                           as="input"
                                                           class="form-control" name="employees_total"
                                                           type="number" v-model="coop.employees.total"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_total">{{ errors.employees_total }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10 mg-t-30">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.employees_division.pointer') }}</span>{{ $t('page.coop.employees_division.text') }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.employees_permanent.pointer') }}</span>{{ $t('page.coop.employees_permanent.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_permanent }" :placeholder="$t('page.coop.employees_permanent.placeholder')"
                                                           @input="calculateMaxLimOfTotalEmp()" as="input" class="form-control"
                                                           name="employees_permanent" rules="integer|min_value:0"
                                                           type="number"
                                                           v-model="coop.employees.permanent"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_permanent">{{ errors.employees_permanent }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.employees_temporary.pointer') }}</span>{{ $t('page.coop.employees_temporary.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_temporary }" :placeholder="$t('page.coop.employees_temporary.placeholder')"
                                                           @input="calculateMaxLimOfTotalEmp()" as="input" class="form-control"
                                                           name="employees_temporary" rules="integer|min_value:0"
                                                           type="number"
                                                           v-model="coop.employees.temporary"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_temporary">{{ errors.employees_temporary }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.employees_casual.pointer') }}</span>{{ $t('page.coop.employees_casual.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_casual }" :placeholder="$t('page.coop.employees_casual.placeholder')"
                                                           @input="calculateMaxLimOfTotalEmp()" as="input" class="form-control"
                                                           name="employees_casual" rules="integer|min_value:0"
                                                           type="number"
                                                           v-model="coop.employees.casual"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_casual">{{ errors.employees_casual }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.employees_adhoc.pointer') }}</span>{{ $t('page.coop.employees_adhoc.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_adhoc }" :placeholder="$t('page.coop.employees_adhoc.placeholder')" as="input" class="form-control"
                                                           name="employees_adhoc" rules="integer|min_value:0|max_value:500"
                                                           type="number"
                                                           v-model="coop.employees.adhoc"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_adhoc">{{ errors.employees_adhoc }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10 mg-t-30">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label font-weight-bold">
                                                        <span class="form-order">{{ $t('page.coop.employees_training.pointer') }}</span>{{ $t('page.coop.employees_training.text') }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.employees_trained.pointer') }}</span>{{ $t('page.coop.employees_trained.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_trained }" :placeholder="$t('page.coop.employees_trained.placeholder')" @input="calculateTotalEmp()"
                                                           as="input" class="form-control"
                                                           name="employees_trained" rules="required|integer|min_value:0"
                                                           type="number"
                                                           v-model="coop.employees.trained"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_trained">{{ errors.employees_trained }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.employees_untrained.pointer') }}</span>{{ $t('page.coop.employees_untrained.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.employees_untrained }" :placeholder="$t('page.coop.employees_untrained.placeholder')"
                                                           @input="calculateTotalEmp()" as="input" class="form-control"
                                                           name="employees_untrained" rules="required|integer|min_value:0"
                                                           type="number"
                                                           v-model="coop.employees.untrained"/>
                                                    <span class="invalid-feedback" v-if="errors.employees_untrained">{{ errors.employees_untrained }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left"><span class="pl-0">{{ $t('page.coop.current_status.text') }}</span></h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.status_management.pointer') }}</span>{{ $t('page.coop.status_management.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.status_management }" as="select" class="form-control" name="status_management"
                                                           rules="required"
                                                           v-model="coop.management_status">
                                                        <option :value="null">{{ $t('page.coop.status_management.placeholder') }}</option>
                                                        <option :value="status" v-for="status of managementStatuses">{{ status }}</option>
                                                    </Field>
                                                    <span class="invalid-feedback" v-if="errors.status_management">{{ errors.status_management }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.status_agm_date.pointer') }}</span>{{ $t('page.coop.status_agm_date.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field as="input" class="form-control" name="status_agm_date" type="date"
                                                           v-model="coop.last_agm_held"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.status_election_date.pointer') }}</span>{{ $t('page.coop.status_election_date.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field as="input" class="form-control" name="status_election_date" type="date"
                                                           v-model="coop.last_election_held"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.status_audit_upto.pointer') }}</span>{{ $t('page.coop.status_audit_upto.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.status_audit_upto }" as="select" class="form-control" name="status_audit_upto"
                                                           rules="required"
                                                           v-model="coop.audit_completed">
                                                        <option :value="null">{{ $t('page.coop.status_audit_upto.placeholder') }}</option>
                                                        <option :value="year" v-for="year of auditYears">{{ year }}</option>
                                                    </Field>
                                                    <span class="invalid-feedback" v-if="errors.status_audit_upto">{{ errors.status_audit_upto }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.status_email.pointer') }}</span>{{ $t('page.coop.status_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.status_email }" :placeholder="$t('page.coop.status_email.placeholder')" as="input" class="form-control"
                                                           name="status_email"
                                                           rules="email" type="email"
                                                           v-model="coop.official_email"/>
                                                    <span class="invalid-feedback" v-if="errors.status_email">{{ errors.status_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{ $t('page.coop.chairman.pointer') }} {{ $t('page.coop.chairman.text') }}
                            </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label required">
                                                        <span class="form-order">{{ $t('page.coop.chairman_name.pointer') }}</span>{{ $t('page.coop.chairman_name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.chairman_name}" :placeholder="$t('page.coop.chairman_name.placeholder')" as="input" class="form-control"
                                                           name="chairman_name"
                                                           rules="required"
                                                           v-model="coop.chairman.name"/>
                                                    <span class="invalid-feedback" v-if="errors.chairman_name">{{ errors.chairman_name }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.chairman_mobile.pointer') }}</span>{{ $t('page.coop.chairman_mobile.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.chairman_mobile}" :placeholder="$t('page.coop.chairman_mobile.placeholder')" as="input"
                                                           class="form-control" name="chairman_mobile"
                                                           rules="digits:10"
                                                           v-model="coop.chairman.mobile"/>
                                                    <span class="invalid-feedback" v-if="errors.chairman_mobile">{{ errors.chairman_mobile }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.chairman_email.pointer') }}</span>{{ $t('page.coop.chairman_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.chairman_email}" :placeholder="$t('page.coop.chairman_email.placeholder')" as="input" class="form-control"
                                                           name="chairman_email" rules="email"
                                                           type="email"
                                                           v-model="coop.chairman.email"/>
                                                    <span class="invalid-feedback" v-if="errors.chairman_email">{{ errors.chairman_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.vice_chairman.pointer') }} {{ $t('page.coop.vice_chairman.text') }}
                                                </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.vice_chairman_name.pointer') }}</span>{{ $t('page.coop.vice_chairman_name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.vice_chairman_name.placeholder')" as="input" class="form-control" name="vice_chairman_name"
                                                           type="text" v-model="coop.vice_chairman.name"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.vice_chairman_mobile.pointer') }}</span>{{ $t('page.coop.vice_chairman_mobile.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.vice_chairman_mobile}" :placeholder="$t('page.coop.vice_chairman_mobile.placeholder')" as="input"
                                                           class="form-control" name="vice_chairman_mobile"
                                                           rules="digits:10"
                                                           v-model="coop.vice_chairman.mobile"/>
                                                    <span class="invalid-feedback" v-if="errors.vice_chairman_mobile">{{ errors.vice_chairman_mobile }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.vice_chairman_email.pointer') }}</span>{{ $t('page.coop.vice_chairman_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.vice_chairman_email}" :placeholder="$t('page.coop.vice_chairman_email.placeholder')" as="input"
                                                           class="form-control" name="vice_chairman_email"
                                                           rules="email"
                                                           v-model="coop.vice_chairman.email"/>
                                                    <span class="invalid-feedback" v-if="errors.vice_chairman_email">{{ errors.vice_chairman_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.secretary.pointer') }} {{ $t('page.coop.secretary.text') }}
                                                </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.secretary_name.pointer') }}</span>{{ $t('page.coop.secretary_name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.secretary_name.placeholder')" as="input" class="form-control" name="secretary_name"
                                                           type="text" v-model="coop.secretary.name"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.secretary_mobile.pointer') }}</span>{{ $t('page.coop.secretary_mobile.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.secretary_mobile}" :placeholder="$t('page.coop.secretary_mobile.placeholder')" as="input"
                                                           class="form-control" name="secretary_mobile"
                                                           rules="digits:10"
                                                           v-model="coop.secretary.mobile"/>
                                                    <span class="invalid-feedback" v-if="errors.secretary_mobile">{{ errors.secretary_mobile }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.secretary_email.pointer') }}</span>{{ $t('page.coop.secretary_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.secretary_email}" :placeholder="$t('page.coop.secretary_email.placeholder')" as="input"
                                                           class="form-control" name="secretary_email"
                                                           rules="email"
                                                           v-model="coop.secretary.email"/>
                                                    <span class="invalid-feedback" v-if="errors.secretary_email">{{ errors.secretary_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.highest_employee.pointer') }} {{ $t('page.coop.highest_employee.text') }}
                                                </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.highest_employee_name.pointer') }}</span>{{ $t('page.coop.highest_employee_name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.highest_employee_name.placeholder')" as="input" class="form-control" name="highest_employee_name"
                                                           type="text" v-model="coop.hde.name"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.highest_employee_designation.pointer') }}</span>{{
                                                            $t('page.coop.highest_employee_designation.text')
                                                        }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.highest_employee_designation.placeholder')" as="input" class="form-control" name="highest_employee_designation"
                                                           type="text" v-model="coop.hde.designation"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.highest_employee_mobile.pointer') }}</span>{{ $t('page.coop.highest_employee_mobile.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.highest_employee_mobile}" :placeholder="$t('page.coop.highest_employee_mobile.placeholder')" as="input"
                                                           class="form-control" name="highest_employee_mobile"
                                                           rules="digits:10"
                                                           v-model="coop.hde.mobile"/>
                                                    <span class="invalid-feedback" v-if="errors.highest_employee_mobile">{{ errors.highest_employee_mobile }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.highest_employee_email.pointer') }}</span>{{ $t('page.coop.highest_employee_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.highest_employee_email}" :placeholder="$t('page.coop.highest_employee_email.placeholder')" as="input"
                                                           class="form-control" name="highest_employee_email"
                                                           rules="email"
                                                           v-model="coop.hde.email"/>
                                                    <span class="invalid-feedback" v-if="errors.highest_employee_email">{{ errors.highest_employee_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.panel_chairman.pointer') }} {{ $t('page.coop.panel_chairman.text') }}
                                                </span>
                                            </h6>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.panel_chairman_name.pointer') }}</span>{{ $t('page.coop.panel_chairman_name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.panel_chairman_name.placeholder')" as="input" class="form-control" name="panel_chairman_name"
                                                           type="text" v-model="coop.panel_chairman.name"/>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.panel_chairman_mobile.pointer') }}</span>{{ $t('page.coop.panel_chairman_mobile.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.panel_chairman_mobile}" :placeholder="$t('page.coop.panel_chairman_mobile.placeholder')" as="input"
                                                           class="form-control" name="panel_chairman_mobile"
                                                           rules="digits:10"
                                                           v-model="coop.panel_chairman.mobile"/>
                                                    <span class="invalid-feedback" v-if="errors.panel_chairman_mobile">{{ errors.panel_chairman_mobile }}</span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.panel_chairman_email.pointer') }}</span>{{ $t('page.coop.panel_chairman_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.panel_chairman_email}" :placeholder="$t('page.coop.panel_chairman_email.placeholder')" as="input"
                                                           class="form-control" name="panel_chairman_email"
                                                           rules="email"
                                                           v-model="coop.panel_chairman.email"/>
                                                    <span class="invalid-feedback" v-if="errors.panel_chairman_email">{{ errors.panel_chairman_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.govt_nominee.pointer') }} {{ $t('page.coop.govt_nominee.text') }}
                                                </span>
                                            </h6>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.govt_nominee_name.pointer') }}</span>{{ $t('page.coop.govt_nominee_name.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.govt_nominee_name.placeholder')" as="input" class="form-control" name="govt_nominee_name"
                                                           type="text" v-model="coop.govt_nominee.name"/>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.govt_nominee_mobile.pointer') }}</span>{{ $t('page.coop.govt_nominee_mobile.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.govt_nominee_mobile}" :placeholder="$t('page.coop.govt_nominee_mobile.placeholder')" as="input"
                                                           class="form-control" name="govt_nominee_mobile"
                                                           rules="digits:10"
                                                           v-model="coop.govt_nominee.mobile"/>
                                                    <span class="invalid-feedback" v-if="errors.govt_nominee_mobile">{{ errors.govt_nominee_mobile }}</span>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.govt_nominee_email.pointer') }}</span>{{ $t('page.coop.govt_nominee_email.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :class="{'is-invalid': errors.govt_nominee_email}" :placeholder="$t('page.coop.govt_nominee_email.placeholder')" as="input"
                                                           class="form-control" name="govt_nominee_email"
                                                           rules="email"
                                                           v-model="coop.govt_nominee.email"/>
                                                    <span class="invalid-feedback" v-if="errors.govt_nominee_email">{{ errors.govt_nominee_email }}</span>
                                                </div>
                                            </div>

                                            <h6 class="text-divider text-left">
                                                <span class="pl-0">
                                                    {{ $t('page.coop.no_of_employee_with_qualification.pointer') }} {{ $t('page.coop.no_of_employee_with_qualification.text') }}
                                                </span>
                                            </h6>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.total_qualified.pointer') }}</span>{{ $t('page.coop.total_qualified.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field as="input" class="form-control" name="total_qualified" readonly
                                                           type="number" v-model="total_qualified"/>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.no_of_employee_with_diploma.pointer') }}</span>{{ $t('page.coop.no_of_employee_with_diploma.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.no_of_employee_with_diploma.text')" @input="calculateTotalQualified()" as="input" class="form-control"
                                                           name="no_of_employee_with_diploma"
                                                           type="number" v-model="coop.no_of_employee_with_diploma"/>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{ $t('page.coop.no_of_employee_with_graduate.pointer') }}</span>{{
                                                            $t('page.coop.no_of_employee_with_graduate.text')
                                                        }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.no_of_employee_with_graduate.text')" @input="calculateTotalQualified()" as="input" class="form-control"
                                                           name="no_of_employee_with_graduate"
                                                           type="number"
                                                           v-model="coop.no_of_employee_with_graduate"/>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{
                                                                $t('page.coop.no_of_employee_with_certificate_training.pointer')
                                                            }}</span>{{ $t('page.coop.no_of_employee_with_certificate_training.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.no_of_employee_with_certificate_training.text')" @input="calculateTotalQualified()" as="input"
                                                           class="form-control" name="no_of_employee_with_certificate_training"
                                                           type="number"
                                                           v-model="coop.no_of_employee_with_certificate_training"/>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mg-b-10">
                                                <div class="col-sm-12 col-md-4 col-lg-3">
                                                    <label class="form-label">
                                                        <span class="form-order">{{
                                                                $t('page.coop.no_of_employee_with_madhyamik_equivalent.pointer')
                                                            }}</span>{{ $t('page.coop.no_of_employee_with_madhyamik_equivalent.text') }}
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-6">
                                                    <Field :placeholder="$t('page.coop.no_of_employee_with_madhyamik_equivalent.text')" @input="calculateTotalQualified()" as="input"
                                                           class="form-control" name="no_of_employee_with_madhyamik_equivalent"
                                                           type="number"
                                                           v-model="coop.no_of_employee_with_madhyamik_equivalent"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- col -->
                                </div>
                                <add-tab-content :form_errors="errors" :coop="coop" :fields="tab.fields" :id="tab.id" :name="tab.name" v-else></add-tab-content>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="Object.keys(errors).length>0" class="alert alert-danger mg-b-8" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
                    There is some validation issue. Please <a data-target="#errorModal" data-toggle="modal" href="javascript:void(0)">Click here</a> to see the details
                </div>
                <div class="float-right btn-toolbar">
                    <button class="btn btn-success mr-2" type="submit">{{ $t('page.coop.button.submit') }}</button>
                    <button class="btn btn-light" type="reset">{{ $t('page.coop.button.reset') }}</button>
                </div>

                <div id="errorModal" class="modal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content modal-content-demo">
                            <div class="modal-header">
                                <h6 class="modal-title">Error Log</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body table-responsive">
                                <table class="table mg-b-0">
                                    <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Reason</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="key in Object.keys(errors)">
                                        <td>{{ key }}</td>
                                        <td>{{ errors[key] }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div><!-- modal-dialog -->
                </div>
            </Form>
        </div>
        <div class="card-body" v-else>
            <h5 class="text-center text-muted m-5">
                No Template Found
            </h5>
        </div>


    </div>
</template>

<script>
import AddGeneral from "./AddGeneral";
import AddTabContent from "./AddTabContent";
import {Form, Field} from "vee-validate";
import pad from "pad";
import society from "../../../assets/data/society.json";
import managementStatuses from "../../../assets/data/managementStatuses.json";

export default {
    name: "Add",
    watch: {
        selected_template: function (n, o) {
            console.log(typeof n, o);
            if (n !== o && n == 24) {
                this.tabs = this.pacs;
            } else {
                this.tabs = []
            }
        }
    },
    components: {AddTabContent, AddGeneral, Form, Field},
    data() {
        return {
            templates: [
                {
                    "id": 1,
                    "type_of_coop": "(P)Bidi Makers’ Co-operative Society",
                    "abbreviation": "BMCS"
                },
                {
                    "id": 2,
                    "type_of_coop": "(P)Cold Storage Co-operative Society",
                    "abbreviation": "CSCS"
                },
                {
                    "id": 3,
                    "type_of_coop": "(P)Consumers’ Co-operative Store / Society",
                    "abbreviation": "CCSS"
                },
                {
                    "id": 4,
                    "type_of_coop": "(P)Employees’  Credit Cooperative Society ",
                    "abbreviation": "ECCS"
                },
                {
                    "id": 5,
                    "type_of_coop": "(P)Engineers’ Co-operative Society",
                    "abbreviation": "ENCS"
                },
                {
                    "id": 6,
                    "type_of_coop": "(P)Farmers’ Service Co-operative  Society (FSCS)",
                    "abbreviation": "FSCS"
                },
                {
                    "id": 7,
                    "type_of_coop": "(P)Fee Parking Co-operative Society",
                    "abbreviation": "FPCS"
                },
                {
                    "id": 8,
                    "type_of_coop": "(P)Fishermen’s  Cooperative Society *",
                    "abbreviation": "FICS"
                },
                {
                    "id": 9,
                    "type_of_coop": "(P)Fruit  & Vegetables Processing Co-operative Society",
                    "abbreviation": "FVPC"
                },
                {
                    "id": 10,
                    "type_of_coop": "(P)Hawkers’ Co-operative Society",
                    "abbreviation": "HACS"
                },
                {
                    "id": 11,
                    "type_of_coop": "(P)Horticulture & Plantation Cooperative Society*",
                    "abbreviation": "HPCS"
                },
                {
                    "id": 12,
                    "type_of_coop": "(P)Housing Co-operative Society ",
                    "abbreviation": "HOCS"
                },
                {
                    "id": 13,
                    "type_of_coop": "(P)Irrigation Cooperative Society",
                    "abbreviation": "IRCS"
                },
                {
                    "id": 14,
                    "type_of_coop": "(P)Labour Contract Co-operative Society",
                    "abbreviation": "LCCS"
                },
                {
                    "id": 15,
                    "type_of_coop": "(P)Large-sized Adivasi Multipurpose Cooperative Societies (LAMPS)*",
                    "abbreviation": "LAMS"
                },
                {
                    "id": 16,
                    "type_of_coop": "(P)Other  Agro-processing Co-operative Society",
                    "abbreviation": "OAPC"
                },
                {
                    "id": 17,
                    "type_of_coop": "(P)Other Agricultural Co-operative Society",
                    "abbreviation": "OACS"
                },
                {
                    "id": 18,
                    "type_of_coop": "(P)Other Agricultural Production Co-operative Society",
                    "abbreviation": "OAPS"
                },
                {
                    "id": 19,
                    "type_of_coop": "(P)Other Industrial Co-operative Society",
                    "abbreviation": "OICS"
                },
                {
                    "id": 20,
                    "type_of_coop": "(P)Other Multi-purpose Co-operative Society",
                    "abbreviation": "OMCS"
                },
                {
                    "id": 21,
                    "type_of_coop": "(P)Other Non-agricultural Credit Co-operative Society",
                    "abbreviation": "ONCS"
                },
                {
                    "id": 22,
                    "type_of_coop": "(P)Other Service Co-operative Society",
                    "abbreviation": "OSCS"
                },
                {
                    "id": 23,
                    "type_of_coop": "(P)Primary  Co-operative Agriculture and Rural Development Bank (PCARDB)",
                    "abbreviation": "PCAR"
                },
                {
                    "id": 24,
                    "type_of_coop": "(P)Primary Agricultural Co-operative Credit Society (PACS)",
                    "abbreviation": "PACS"
                },
                {
                    "id": 25,
                    "type_of_coop": "(P)Primary Agricultural Cooperative Marketing Society",
                    "abbreviation": "PACM"
                },
                {
                    "id": 26,
                    "type_of_coop": "(P)Primary Livestock Cooperative Society*",
                    "abbreviation": "PLCS"
                },
                {
                    "id": 27,
                    "type_of_coop": "(P)Primary Milk Producers’ Cooperative Society*",
                    "abbreviation": "PMPC"
                },
                {
                    "id": 28,
                    "type_of_coop": "(P)Primary Women’s Milk Producers’ Cooperative Society*",
                    "abbreviation": "PWMC"
                },
                {
                    "id": 29,
                    "type_of_coop": "(P)Rice Mill Co-operative Society",
                    "abbreviation": "RMCS"
                },
                {
                    "id": 30,
                    "type_of_coop": "(P)Road Transport Co-operative Society",
                    "abbreviation": "RTCS"
                },
                {
                    "id": 31,
                    "type_of_coop": "(P)Rural Electrification Co-operative Society",
                    "abbreviation": "RECS"
                },
                {
                    "id": 32,
                    "type_of_coop": "(P)Self Help Group Co-operative Society",
                    "abbreviation": "SHGS"
                },
                {
                    "id": 33,
                    "type_of_coop": "(P)Silk Weavers’ Cooperative Society  *",
                    "abbreviation": "SWCS"
                },
                {
                    "id": 34,
                    "type_of_coop": "(P)Talgur-Khejurgur Producers’ Co-operative Society",
                    "abbreviation": "TPCS"
                },
                {
                    "id": 35,
                    "type_of_coop": "(P)Urban Co-operative Bank",
                    "abbreviation": "URCB"
                },
                {
                    "id": 36,
                    "type_of_coop": "(P)Urban Credit Co-operative Societies",
                    "abbreviation": "UCCS"
                },
                {
                    "id": 37,
                    "type_of_coop": "(P)Water Transport Co-operative Society",
                    "abbreviation": "WTCS"
                },
                {
                    "id": 38,
                    "type_of_coop": "(P)Weavers’ Cooperative Society  *",
                    "abbreviation": "WECS"
                },
                {
                    "id": 39,
                    "type_of_coop": "(P)Women’s Co-operative Bank",
                    "abbreviation": "WOCB"
                },
                {
                    "id": 40,
                    "type_of_coop": "(P)Women’s Credit Co-operative Societies Urban Credit Co-operative Societies",
                    "abbreviation": "WCCS"
                },
                {
                    "id": 41,
                    "type_of_coop": "(C)Central Agricultural Cooperative Marketing Society",
                    "abbreviation": "CACS"
                },
                {
                    "id": 42,
                    "type_of_coop": "(C)Central Co-operative Bank ",
                    "abbreviation": "CCOB"
                },
                {
                    "id": 43,
                    "type_of_coop": "(C)Central Engineers’ Co-operative Society",
                    "abbreviation": "CECS"
                },
                {
                    "id": 44,
                    "type_of_coop": "(C)Central Fishermen’s Cooperative Societies *",
                    "abbreviation": "CFCS"
                },
                {
                    "id": 45,
                    "type_of_coop": "(C)Central Milk Producers’ Co-operative Union",
                    "abbreviation": "CMPU"
                },
                {
                    "id": 46,
                    "type_of_coop": "(C)District / Range Co-operative Union",
                    "abbreviation": "DRCU"
                },
                {
                    "id": 47,
                    "type_of_coop": "(C)Wholesale Consumers’ Co-operative Society",
                    "abbreviation": "WHCC"
                },
                {
                    "id": 48,
                    "type_of_coop": "(A)State Agricultural Marketing Co-operative Federation",
                    "abbreviation": "SAMF"
                },
                {
                    "id": 49,
                    "type_of_coop": "(A)State Consumers’ Co-operative Federation",
                    "abbreviation": "SCCF"
                },
                {
                    "id": 50,
                    "type_of_coop": "(A)State Co-operative Agriculture and Rural Development Bank (SCARDB)",
                    "abbreviation": "SCAR"
                },
                {
                    "id": 51,
                    "type_of_coop": "(A)State Co-operative Bank",
                    "abbreviation": "SCOB"
                },
                {
                    "id": 52,
                    "type_of_coop": "(A)State Co-operative Federation",
                    "abbreviation": "SCOF"
                },
                {
                    "id": 53,
                    "type_of_coop": "(A)State Co-operative Union",
                    "abbreviation": "SCOU"
                },
                {
                    "id": 54,
                    "type_of_coop": "(A)State Engineers’ Co-operative Federation",
                    "abbreviation": "SECF"
                },
                {
                    "id": 55,
                    "type_of_coop": "(A)State Federation of Urban Co-operative Credit Societies and Banks",
                    "abbreviation": "SFCB"
                },
                {
                    "id": 56,
                    "type_of_coop": "(A)State Fishermen’s Cooperative Federation*",
                    "abbreviation": "SFCF"
                },
                {
                    "id": 57,
                    "type_of_coop": "(A)State Milk Producers’ Co-operative Union",
                    "abbreviation": "SMPC"
                },
                {
                    "id": 58,
                    "type_of_coop": "(A)State Silk Weavers’ Co-operative Federation",
                    "abbreviation": "SSWC"
                },
                {
                    "id": 59,
                    "type_of_coop": "(A)State Talgur-Khejurgur Co-operative Federation",
                    "abbreviation": "STKF"
                },
                {
                    "id": 60,
                    "type_of_coop": "(A)State Tribal Deve-lopment Co-operative Corporation*",
                    "abbreviation": "STDC"
                },
                {
                    "id": 61,
                    "type_of_coop": "(A)State Weavers’ Co-operative Society (Federation)",
                    "abbreviation": "SWCF"
                }
            ],
            years: ["2019", "2020", "2021", "2022"],
            selected_template: 1,
            selected_year: "2022",
            coop: {
                coop_id: null,
                range: null,
                municipality: null,
                name: null,
                address: null,
                class: null,
                area: null,
                registration: {
                    number: null,
                    date: null,
                    addon_number: null,
                    addon_date: null,
                },
                members: {
                    individual_voters: {
                        total: null,
                        male: null,
                        female: null,
                        general: null,
                        sc: null,
                        st: null,
                        obc: null,
                        minority: null
                    },
                    nominal: null,
                    self_help_groups: null,
                    coop_societies: null,
                    delegates: null
                },
                employees: {
                    total: null,
                    permanent: null,
                    temporary: null,
                    casual: null,
                    adhoc: null,
                    trained: null,
                    untrained: null
                },
                management_status: null,
                last_agm_held: null,
                last_election_held: null,
                audit_completed: null,
                official_email: null,
                chairman: {
                    name: null,
                    designation: null,
                    mobile: null,
                    email: null
                },
                vice_chairman: {
                    name: null,
                    designation: null,
                    mobile: null,
                    email: null
                },
                secretary: {
                    name: null,
                    designation: null,
                    mobile: null,
                    email: null
                },
                hde: {
                    name: null,
                    designation: null,
                    mobile: null,
                    email: null
                },
                panel_chairman: {
                    name: null,
                    designation: null,
                    mobile: null,
                    email: null
                },
                govt_nominee: {
                    name: null,
                    designation: null,
                    mobile: null,
                    email: null
                },
                "status": "Draft",
                "created_by": "379d8d70-4451-11eb-b378-0242ac130002",
                "created_by_name": null,
                "file_name": null,
                "created_at": "2022-05-26T07:48:46.891727",
                "updated_at": "2022-05-26T07:48:46.891727",
                "is_valid": true,
                "registration_year": "2020",
                "coop_society_type": "(P)Primary Agricultural Co-operative Credit Society (PACS)",
                "coop_human_id": "1/2216/PACS/20090312/209136728",
                "no_of_employee_with_diploma": null,
                "no_of_employee_with_graduate": null,
                "no_of_employee_with_certificate_training": null,
                "no_of_employee_with_madhyamik_equivalent": null,
                "working_capital": {
                    "working_capital_as_per_definition_69": {
                        "borrowed_fund": 0,
                        "crar_as_determined_by_the_auditors": 0,
                        "owned_capital": 0,
                        "working_capital_owned_capital_borrowed_fund": 0
                    }
                },
                "marketing_earnings": {
                    "carriage_inward": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_carriage_inward": 0
                    },
                    "closing_Stock": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_closing_Stock": 0
                    },
                    "commision_paid": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_commision_paid": 0
                    },
                    "gross_profit_loss": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_gross_profit_loss": 0
                    },
                    "loading_unloading_charges": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_loading_unloading_charges": 0
                    },
                    "opening_stock": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_opening_stock": 0
                    },
                    "other_trade_charges": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_other_trade_charges": 0
                    },
                    "purchase": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_purchase": 0
                    },
                    "sale_return": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_sale_return": 0
                    },
                    "sales_distributorship": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_sales_distributorship": 0
                    },
                    "sales_government_supply": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_sales_government_supply": 0
                    },
                    "sales_retail": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_sales_retail": 0
                    },
                    "stock_transfer": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_stock_transfer": 0
                    },
                    "stock_transfer_return": {
                        "fertiliser": 0,
                        "insecticides_and_pesticides": 0,
                        "others": 0,
                        "plant_protection_chemicals": 0,
                        "seeds": 0,
                        "total_stock_transfer_return": 0
                    },
                    "total_operating_expenses": 0,
                    "total_operating_income": 0
                },
                "consumer_earnings": {
                    "carriage_inward": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_carriage_inward": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "closing_Stock": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_closing_Stock": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "commision_paid": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_commision_paid": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "gross_profit_loss": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_gross_profit_loss": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "loading_unloading_charges": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_loading_unloading_charges": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "opening_stock": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_opening_stock": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "other_trade_charges": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_other_trade_charges": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "purchases": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_purchases": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "sale_return": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_sale_return": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "sales_distributorship": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_sales_distributorship": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "sales_government_supply": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_sales_government_supply": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "sales_retail": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_sales_retail": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "stock_transfer": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_stock_transfer": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "stock_transfer_return": {
                        "groceries": 0,
                        "others": 0,
                        "stationaries": 0,
                        "textiles_garments": 0,
                        "total_stock_transfer_return": 0,
                        "vegetable_perishable_foods": 0
                    },
                    "total_operating_expenses": 0,
                    "total_operating_income": 0
                },
                "processing_earnings": {
                    "carriage_inward": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_carriage_inward": 0
                    },
                    "closing_Stock": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_closing_Stock": 0
                    },
                    "commision_paid": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_commision_paid": 0
                    },
                    "gross_profit_loss": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_gross_profit_loss": 0
                    },
                    "loading_unloading_charges": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_loading_unloading_charges": 0
                    },
                    "opening_stock": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_opening_stock": 0
                    },
                    "other_trade_charges": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_other_trade_charges": 0
                    },
                    "purchases": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_purchases": 0
                    },
                    "sale_return": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_sale_return": 0
                    },
                    "sales_distributorship": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_sales_distributorship": 0
                    },
                    "sales_government_supply": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_sales_government_supply": 0
                    },
                    "sales_retail": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_sales_retail": 0
                    },
                    "stock_transfer": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_stock_transfer": 0
                    },
                    "stock_transfer_return": {
                        "jute_seed": 0,
                        "others": 0,
                        "potato_seed": 0,
                        "pulses_oilseed": 0,
                        "rice_seed": 0,
                        "total_stock_transfer_return": 0
                    },
                    "total_operating_expenses": 0,
                    "total_operating_income": 0
                },
                "liabilities": {
                    "accumulated_loss": 0,
                    "accumulated_profit": 0,
                    "borrowing": {
                        "borrowing": {
                            "banks": 0,
                            "government": 0,
                            "other_financial_institution": 0,
                            "total_borrowing": 0
                        },
                        "total_total_borrowing": 0
                    },
                    "capital": {
                        "authorised_share_capital": {
                            "individual": 0,
                            "state_government": 0,
                            "total_authorised_share_capital": 0
                        },
                        "subscribed_paid_up_share_capital": {
                            "individual": 0,
                            "state_government": 0,
                            "total_subscribed_paid_up_share_capital": 0
                        },
                        "total_capital": 0
                    },
                    "deposit": {
                        "savings_deposit": {
                            "employees": 0,
                            "individual": 0,
                            "self_help_group": 0,
                            "total_savings_deposit": 0
                        },
                        "term_deposit": {
                            "employees": 0,
                            "individual": 0,
                            "self_help_group": 0,
                            "total_term_deposit": 0
                        },
                        "total_deposit": 0
                    },
                    "other_liabilities": {
                        "any_other_contingent_liability": 0,
                        "bonus_unpaid": 0,
                        "cost_of_management_unpaid": 0,
                        "dividend_unpaid": 0,
                        "interest_payable_borrowing": 0,
                        "interest_payable_on_deposit": 0,
                        "total_other_liabilities": 0
                    },
                    "reserve": {
                        "other_reserves_and_funds": {
                            "any_other_fund": 0,
                            "building_fund": 0,
                            "dividend_equalization_fund": 0,
                            "education_fund": 0,
                            "general_reserve": 0,
                            "grant_received": 0,
                            "total_other_reserves_and_funds": 0
                        },
                        "reserve_for_bad_and_doubtful_debts": 0,
                        "reserve_for_overdue_interest": 0,
                        "reserve_fund_section_82": 0,
                        "total_reserve": 0
                    },
                    "total_liabilities": 0
                },
                "assets": {
                    "fixed_assets": {
                        "any_other_fixed_asset": 0,
                        "computer_and_accessories": 0,
                        "dead_stock": 0,
                        "furniture_and_fixture": 0,
                        "land_and_building": 0,
                        "total_fixed_assets": 0
                    },
                    "interest_on_loan_outstanding": {
                        "individual_loan": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_individual_loan": 0
                        },
                        "msme_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_msme_sector": 0
                        },
                        "other": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_other": 0
                        },
                        "shg": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_shg": 0
                        },
                        "total_interest_on_loan_outstanding": 0
                    },
                    "investment": {
                        "any_other_investment": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_any_other_investment": 0
                        },
                        "deposit_with_any_scheduled_bank": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_deposit_with_any_scheduled_bank": 0
                        },
                        "deposit_with_post_Office": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_deposit_with_post_Office": 0
                        },
                        "deposit_with_wbscb": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_deposit_with_wbscb": 0
                        },
                        "government_securities": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_government_securities": 0
                        },
                        "shares_of_other_co_operative_societies": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_shares_of_other_co_operative_societies": 0
                        },
                        "total_investment": 0
                    },
                    "investment_of_reserve_fund": {
                        "any_other_investment": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_any_other_investment": 0
                        },
                        "deposit_with_any_scheduled_bank": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_deposit_with_any_scheduled_bank": 0
                        },
                        "deposit_with_post_Office": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_deposit_with_post_Office": 0
                        },
                        "deposit_with_wbscb": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_deposit_with_wbscb": 0
                        },
                        "government_securities": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_government_securities": 0
                        },
                        "shares_of_other_co_operative_societies": {
                            "interest_accrued_thereupon": 0,
                            "principal_amount": 0,
                            "total_shares_of_other_co_operative_societies": 0
                        },
                        "total_investment_of_reserve_fund": 0
                    },
                    "liquid_asset": {
                        "balance_with_current_account": 0,
                        "cash": 0,
                        "total_liquid_asset": 0
                    },
                    "loan_principal_outstanding": {
                        "individual_loan": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_individual_loan": 0
                        },
                        "msme_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_msme_sector": 0
                        },
                        "other": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_other": 0
                        },
                        "shg": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_shg": 0
                        },
                        "total_loan_principal_outstanding": 0
                    },
                    "other_assest": {
                        "gst_receivable": 0,
                        "it_receivable": 0,
                        "other_assest": 0,
                        "stock_in_trade": 0,
                        "tds_receivable": 0,
                        "total_other_assest": 0
                    },
                    "total_assets": 0
                },
                "land_building": {
                    "annual_license": 0,
                    "annual_license_fee": 0,
                    "business_of_the_society": {
                        "hired_building": 0,
                        "long_term_leased_building": 0,
                        "own_building_on_leased_land": 0,
                        "own_building_on_own_land": 0
                    },
                    "covered_area": 0,
                    "depricated_value_of_branch_buildings": 0,
                    "depricated_value_of_building": 0,
                    "details_of_godown": {
                        "hired_with_caapacity_mt": 0,
                        "monthly_rent_received": 0,
                        "owned_let_out": 0,
                        "owned_with_caapacity_mt": 0
                    },
                    "number_of_branch_office": 0,
                    "status_of_the_branch": {
                        "number_of_hired_building": 0,
                        "number_of_long_term_leased_building": 0,
                        "number_of_own_building_on_leased_land": 0,
                        "number_of_own_building_on_own_land": 0
                    },
                    "total_covered_area": 0
                },
                "mis": {
                    "configuration_of_the_server": {
                        "application_software": 0,
                        "cooling_Unit": 0,
                        "data_backup_system": 0,
                        "external_storage_space": 0,
                        "number_of_hard_discs": 0,
                        "number_of_processors": 0,
                        "raid_controller": 0,
                        "system_software": 0,
                        "whether_it_has_any_firewall": 0
                    },
                    "interconnected_by_lan": 0,
                    "name_of_vendor": 0,
                    "society_in_working_condition": {
                        "data_centre": 0,
                        "desktop": 0,
                        "dot_matrix_printer": 0,
                        "laptop": 0,
                        "laserjet_printer": 0,
                        "passbook_printer": 0,
                        "scanner": 0,
                        "server": 0,
                        "switch_for_lan": 0,
                        "ups": 0
                    },
                    "whether_society_application_software": {
                        "application_software": 0,
                        "based_application_software": 0
                    },
                    "whether_the_society_has_accounting_software": {
                        "accounting_software": 0,
                        "advances_module": 0,
                        "deposit_module": 0,
                        "financial_statements": 0,
                        "hrms_software": 0,
                        "investment_module": 0,
                        "mis_software": 0,
                        "returns_module": 0
                    }
                },
                "depositor_profile": {
                    "casa_deposit": "0",
                    "casa_depositor": "0",
                    "deposit": {
                        "savings_deposit": {
                            "employees": "0",
                            "individual": "0",
                            "self_help_group": "0",
                            "total_savings_deposit": "0"
                        },
                        "term_deposit_within_year": {
                            "employees": "0",
                            "individual": "0",
                            "self_help_group": "0",
                            "total_term_deposit": "0"
                        },
                        "total_deposit": "0"
                    },
                    "number_of_depositors": {
                        "savings_depositor_no": {
                            "employees": 0,
                            "individual": 0,
                            "self_help_group": 0,
                            "total_savings_depositor_no": 0
                        },
                        "term_depositor_no_within_year": {
                            "employees": 0,
                            "individual": 0,
                            "self_help_group": 0,
                            "total_term_depositor_no_within_year": 0
                        },
                        "total_number_of_depositors": 0
                    },
                    "percentage_of_casa_deposit": 0.0,
                    "percentage_of_casa_depositor": 0.0,
                    "term_deposit": "0",
                    "term_depositor": "0",
                    "total_deposit": "0",
                    "total_depositor": "0"
                },
                "loanee_profile": {
                    "average_loan_portfolio_of_loanee_members": {
                        "no_of_individual_loanee_member": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member": 0
                        },
                        "no_of_individual_loanee_member_in_msme_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member_in_msme_sector": 0
                        },
                        "no_of_individual_loanee_member_in_other_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member_in_other_sector": 0
                        },
                        "no_of_individual_loanee_member_in_shg_fold": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member_in_shg_fold": 0
                        },
                        "total_average_loan_portfolio_of_loanee_members": 0
                    },
                    "classification_of_asset": {
                        "doubtful_asset": {
                            "doubtful_more_than_1_year_and_upto_3_years": 0,
                            "doubtful_more_than_3_years": 0,
                            "doubtful_upto_year": 0,
                            "total_doubtful_asset": 0
                        },
                        "loss_asset": 0,
                        "performing_asset": {
                            "current": 0,
                            "overdue_less_than_quarter": 0,
                            "total_performing_asset": 0
                        },
                        "total_non_performing_asset": 0,
                        "total_sub_standard_asset": 0
                    },
                    "interest_on_loan_outstanding": {
                        "individual_loan": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_individual_loan": 0
                        },
                        "msme_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_msme_sector": 0
                        },
                        "other": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_other": 0
                        },
                        "shg": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_shg": 0
                        },
                        "total_interest_on_loan_outstanding": 0
                    },
                    "loan_principal_outstanding": {
                        "individual_loan": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_individual_loan": 0
                        },
                        "msme_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_msme_sector": 0
                        },
                        "other": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_other": 0
                        },
                        "shg": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_shg": 0
                        },
                        "total_loan_principal_outstanding": 0
                    },
                    "no_of_loanee_members": {
                        "no_of_individual_loanee_member": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member": 0
                        },
                        "no_of_individual_loanee_member_in_msme_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member_in_msme_sector": 0
                        },
                        "no_of_individual_loanee_member_in_other_sector": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member_in_other_sector": 0
                        },
                        "no_of_individual_loanee_member_in_shg_fold": {
                            "current": 0,
                            "litigated": 0,
                            "non_performing": 0,
                            "overdue": 0,
                            "total_no_of_individual_loanee_member_in_shg_fold": 0
                        },
                        "total_no_of_loanee_members": 0
                    },
                    "percentange_of_loan_repayment": {
                        "total_individual_loan_repaid": 0,
                        "total_loan_repaid_msme_sector": 0,
                        "total_loan_repaid_other": 0,
                        "total_loan_repaid_shg": 0
                    },
                    "total_percentage_of_loan_repayment": 0
                },
                "earnings": {
                    "appropriation_of_profits": {
                        "appropriation_towards_building_fund": 0,
                        "appropriation_towards_dividend_equalisation_fund": 0,
                        "appropriation_towards_other_reserves": 0,
                        "appropriation_towards_statutory_reserves": 0,
                        "dividend": 0,
                        "total_appropriation_of_profits": 0
                    },
                    "balance_of_previous": 0,
                    "discount_received_on_loans_and_advances": 0,
                    "expenditure_for_salary": {
                        "casual_employees": 0,
                        "contingent_menials": 0,
                        "contractual_employees": 0,
                        "permanent_employees": 0,
                        "total_expenditure_for_salary": 0
                    },
                    "interest_discount_received": 0,
                    "interest_on_investments": {
                        "dividend": 0,
                        "interest_received_on_standard_investments": 0,
                        "total_interest_on_investments": 0
                    },
                    "interest_paid": {
                        "bank_borrowings": 0,
                        "customer_deposits": 0,
                        "others": 0,
                        "total_interest_paid": 0
                    },
                    "net_distributable_profit_loss": {
                        "accumulated_loss_of_current_year": 0,
                        "accumulated_profit_of_current_year": 0,
                        "total_net_distributable_profit_loss": 0
                    },
                    "net_interest_income": 0,
                    "net_operating_profit": 0,
                    "net_profit": 0,
                    "non_interest_expenditure": 0,
                    "non_interest_income": 0,
                    "non_operating_income": {
                        "non_operating_income": 0,
                        "profit_loss_of_fixed_assets": 0,
                        "profit_loss_of_other_assets": 0,
                        "total_non_operating_income": 0
                    },
                    "number_of_employees": {
                        "casual_employees": 0,
                        "contingent_menials": 0,
                        "contractual_employees": 0,
                        "permanent_employees": 0,
                        "total_number_of_employees": 0
                    },
                    "operating_expenses": {
                        "auditors_fees": 0,
                        "directors_fees": 0,
                        "staff_expenses": 0,
                        "total_operating_expenses": 0
                    },
                    "operating_expenses_from_trading_account": 0,
                    "operating_income_trading_account": 0,
                    "operating_profit_loss": 0,
                    "other_operating_expenses": {
                        "advertisement": 0,
                        "depreciation": 0,
                        "insurance": 0,
                        "law_charges": 0,
                        "others": 0,
                        "postage": 0,
                        "rent_taxes_lighting": 0,
                        "repairs": 0,
                        "stationery": 0,
                        "total_other_operating_expenses": 0
                    },
                    "other_operating_income": {
                        "fee_commission": 0,
                        "miscellaneous_income": 0,
                        "profit_loss": 0,
                        "total_other_operating_income": 0
                    },
                    "provision_against_risks": {
                        "other_provisions": 0,
                        "other_risk": 0,
                        "provision_for_depreciation": 0,
                        "provision_for_loan_losses": 0,
                        "total_provision_against_risks": 0
                    },
                    "provisions_for_taxes": 0,
                    "total_income": 0,
                    "total_operating_expenses": 0,
                    "total_operating_income": 0,
                    "write_off": {
                        "bad_debts_written_off": 0,
                        "capitalised_expenditure": 0,
                        "other_assets_written_off": 0,
                        "total_write_off": 0
                    }
                },
                "government_share_loan": {
                    "government_share_loan": {
                        "amount_of_dividend_paid": 0,
                        "amount_of_govt_loan_repaid": 0,
                        "amount_of_interest_due": 0,
                        "amount_of_interest_repaid": 0,
                        "amount_of_outstanding_govt_loan": {
                            "of_which_overdue": 0,
                            "total_amount_of_outstanding_govt_loan": 0
                        },
                        "amount_of_paid_up_share_capital": {
                            "of_which_state_share": 0,
                            "total_amount_of_paid_up_share_capital": 0
                        },
                        "amount_of_state_share": 0,
                        "amount_of_state_share_redeemed": 0
                    }
                },
                "shg": {
                    "amount_of_deposit_mobilized": {
                        "savings_deposit": 0,
                        "term_deposit": 0,
                        "total_amount_of_deposit_mobilized": 0
                    },
                    "amount_of_loan_outstanding": {
                        "current": 0,
                        "litigated": 0,
                        "non_performing": 0,
                        "overdue": 0,
                        "total_amount_of_loan_outstanding": 0
                    },
                    "amount_of_shg_Loan_issued_during_the_year": 0,
                    "number_of_members": 0,
                    "number_of_shg_member_details_uploaded_in_shg_portal": 0,
                    "number_of_shg_members_credit_linked": 0,
                    "number_of_shgs_credit_linked": 0,
                    "number_of_shgs_digitised": 0,
                    "number_of_shgs_formed": 0,
                    "of_which": {
                        "amount_repaid": 0,
                        "percentage_of_collection": 0.0
                    },
                    "out_of_number_of_members": {
                        "female_members": 0,
                        "male_members": 0,
                        "total_members": 0
                    }
                }
            },
            tabs: [],
            pacs: [
                {
                    index: 0,
                    name: 'General'
                },
                {
                    index: 1,
                    name: 'Working Capital',
                    id: 'working_capital',
                    description: 'Working Capital as per Definition 69 of the Section 4 of the WBCS Act, 2006',
                    "fields": [
                        {
                            "db": "working_capital_as_per_definition_69",
                            "label": "Working Capital as per Definition 69 of the Section 4 of the WBCS Act, 2006",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "owned_capital",
                                    "label": "1. Owned Capital",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "borrowed_fund",
                                    "label": "2. Borrowed Fund",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "working_capital_owned_capital_borrowed_fund",
                                    "label": "3. Working Capital = Owned Capital + Borrowed Fund",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "crar_as_determined_by_the_auditors",
                                    "label": "4.CRAR (as determined by the Auditors)",
                                    "type": "number"
                                }
                            ]
                        },
                    ]
                },
                {
                    index: 3,
                    name: 'Marketing Earnings',
                    id: 'marketing_earnings',
                    "fields": [
                        {
                            "db": "total_operating_income",
                            "label": "A. Total Operating Income",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "sales_retail",
                            "label": "A.1. Sales (Retail)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_retail",
                                    "label": "Total Sales (Retail)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sales_distributorship",
                            "label": "A.2. Sales (Distributorship)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_distributorship",
                                    "label": "Total Sales (Distributorship)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sales_government_supply",
                            "label": "A.3. Sales (Government Supply)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_government_supply",
                                    "label": "Total Sales (Government Supply)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "stock_transfer_return",
                            "label": "A.4. Stock Transfer Return",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_stock_transfer_return",
                                    "label": "Total Stock Transfer Return",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "closing_Stock",
                            "label": "A.5. Closing Stock",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_closing_Stock",
                                    "label": "Total Closing Stock",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "total_operating_expenses",
                            "label": "B. Total Operating Expenses",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "opening_stock",
                            "label": "B.1. Opening Stock",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_opening_stock",
                                    "label": "Total Opening Stock",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "purchase",
                            "label": "B.2. Purchase",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_purchase",
                                    "label": "Total Purchase",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "stock_transfer",
                            "label": "B.3. Stock Transfer",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_stock_transfer",
                                    "label": "Total Stock Transfer",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sale_return",
                            "label": "B.4. Sale Return",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sale_return",
                                    "label": "Total Sale Return",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "carriage_inward",
                            "label": "B.5. Carriage Inward",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_carriage_inward",
                                    "label": "Total Carriage Inward",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "loading_unloading_charges",
                            "label": "B.6. Loading / Unloading Charges",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_loading_unloading_charges",
                                    "label": "Total Loading / Unloading Charges",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "commision_paid",
                            "label": "B.7. Commision Paid",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_commision_paid",
                                    "label": "Total Commision Paid",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "other_trade_charges",
                            "label": "B.8. Other Trade Charges ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_other_trade_charges",
                                    "label": "Total Other Trade Charges",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number"
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number"
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number"
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "gross_profit_loss",
                            "label": "C. Gross (+)Profit / (-)Loss",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_gross_profit_loss",
                                    "label": "Total Gross (+)Profit / (-)Loss",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fertiliser",
                                    "label": "i. Fertiliser",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "plant_protection_chemicals",
                                    "label": "ii. Plant Protection Chemicals",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "insecticides_and_pesticides",
                                    "label": "iii. Insecticides and Pesticides",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "seeds",
                                    "label": "iv. Seeds",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number",
                                    "readonly": true
                                }
                            ]
                        }
                    ]
                },
                {
                    index: 4,
                    name: 'Consumer Earnings',
                    id: 'consumer_earnings',
                    "fields": [
                        {
                            "db": "total_operating_income",
                            "label": "A. Total Operating Income",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "sales_retail",
                            "label": "A.1. Sales (Retail)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_retail",
                                    "label": "Total Sales (Retail)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sales_distributorship",
                            "label": "A.2. Sales (Distributorship)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_distributorship",
                                    "label": "Total Sales (Distributorship)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sales_government_supply",
                            "label": "A.3. Sales (Government Supply)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_government_supply",
                                    "label": "Total Sales (Government Supply)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "stock_transfer_return",
                            "label": "A.4. Stock Transfer Return",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_stock_transfer_return",
                                    "label": "Total Stock Transfer Return",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "closing_Stock",
                            "label": "A.5. Closing Stock",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_closing_Stock",
                                    "label": "Total Closing Stock",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "total_operating_expenses",
                            "label": "B. Total Operating Expenses",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "opening_stock",
                            "label": "B.1. Opening Stock",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_opening_stock",
                                    "label": "Total Opening Stock",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "purchases",
                            "label": "B.2. Purchases",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_purchases",
                                    "label": "Total Purchases",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "stock_transfer",
                            "label": "B.3. Stock Transfer",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_stock_transfer",
                                    "label": "Total Stock Transfer",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sale_return",
                            "label": "B.4. Sale Return",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sale_return",
                                    "label": "Total Sale Return",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "carriage_inward",
                            "label": "B.5. Carriage Inward",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_carriage_inward",
                                    "label": "Total Carriage Inward",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "loading_unloading_charges",
                            "label": "B.6. Loading / Unloading Charges ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_loading_unloading_charges",
                                    "label": "Total Loading / Unloading Charges",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "commision_paid",
                            "label": "B.7. Commision Paid ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_commision_paid",
                                    "label": "Total Commision Paid",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "other_trade_charges",
                            "label": "B.8. Other Trade Charges ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_other_trade_charges",
                                    "label": "Total Other Trade Charges",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number"
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number"
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number"
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "gross_profit_loss",
                            "label": "C. Gross (+)Profit / (-)Loss",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_gross_profit_loss",
                                    "label": "Total Gross (+)Profit / (-)Loss",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "groceries",
                                    "label": "i. Groceries",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "stationaries",
                                    "label": "ii. Stationaries",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "textiles_garments",
                                    "label": "iii. Textiles & Garments",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "vegetable_perishable_foods",
                                    "label": "iv. Vegetable & perishable foods",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number",
                                    "readonly": true
                                }
                            ]
                        }
                    ]
                },
                {
                    index: 5,
                    name: 'Processing Earnings',
                    id: 'processing_earnings',
                    "fields": [
                        {
                            "db": "total_operating_income",
                            "label": "A. Total Operating Income",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "sales_retail",
                            "label": "A.1. Sales (Retail)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_retail",
                                    "label": "Total Sales (Retail)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sales_distributorship",
                            "label": "A.2. Sales (Distributorship)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_distributorship",
                                    "label": "Total Sales (Distributorship)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sales_government_supply",
                            "label": "A.3. Sales (Government Supply)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sales_government_supply",
                                    "label": "Total Sales (Government Supply)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "stock_transfer_return",
                            "label": "A.4. Stock Transfer Return",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_stock_transfer_return",
                                    "label": "Total Stock Transfer Return",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "closing_Stock",
                            "label": "A.5. Closing Stock(including work in progress)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_closing_Stock",
                                    "label": "Total Closing Stock(including work in progress)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "total_operating_expenses",
                            "label": "B. Total Operating Expenses",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "opening_stock",
                            "label": "B.1. Opening Stock(including work in progress)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_opening_stock",
                                    "label": "Total Opening Stock(including work in progress)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "purchases",
                            "label": "B.2. Purchases (including work in progress)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_purchases",
                                    "label": "Total Purchases",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "stock_transfer",
                            "label": "B.3. Stock Transfer",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_stock_transfer",
                                    "label": "Total Stock Transfer",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "sale_return",
                            "label": "B.4. Sale Return",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_sale_return",
                                    "label": "Total Sale Return",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "carriage_inward",
                            "label": "B.5. Carriage Inward",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_carriage_inward",
                                    "label": "Total Carriage Inward",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "loading_unloading_charges",
                            "label": "B.6. Loading / Unloading Charges ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_loading_unloading_charges",
                                    "label": "Total Loading / Unloading Charges",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "commision_paid",
                            "label": "B.7. Commision Paid",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_commision_paid",
                                    "label": "Total Commision Paid",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "other_trade_charges",
                            "label": "B.8. Other Trade Charges ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_other_trade_charges",
                                    "label": "Total Other Trade Charges",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number"
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number"
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "gross_profit_loss",
                            "label": "C. Gross (+)Profit / (-)Loss",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_gross_profit_loss",
                                    "label": "Total Gross (+)Profit / (-)Loss",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rice_seed",
                                    "label": "i. Rice Seed",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "potato_seed",
                                    "label": "ii. Potato Seed",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "jute_seed",
                                    "label": "iii. Jute Seed ",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "pulses_oilseed",
                                    "label": "iv. Pulses & Oilseed ",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "others",
                                    "label": "v. Others",
                                    "type": "number",
                                    "readonly": true
                                }
                            ]
                        }
                    ]
                },
                {
                    index: 6,
                    name: 'Liabilities',
                    id: 'liabilities',
                    "fields": [
                        {
                            "db": "total_liabilities",
                            "label": "Total Liabilities",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "capital",
                            "label": "A. Capital",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_capital",
                                    "label": "A. Capital",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "authorised_share_capital",
                                    "label": "(a) Authorised Share Capital (in Rs.)",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_authorised_share_capital",
                                            "label": "Total Authorised Share Capital (in Rs.)",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number"
                                        },
                                        {
                                            "db": "state_government",
                                            "label": "ii. State Government",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "share_mt_loan",
                                        //     "label": "iii. Share M.T. Loan",
                                        //     "type": "number"
                                        // }

                                    ]
                                },
                                {
                                    "db": "subscribed_paid_up_share_capital",
                                    "label": "(b) Subscribed & Paid-up Share Capital (in Rs.)",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_subscribed_paid_up_share_capital",
                                            "label": "Total Subscribed & Paid-up Share Capital (in Rs.)",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number"
                                        },
                                        {
                                            "db": "state_government",
                                            "label": "ii. State Government",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "share_mt_loan",
                                        //     "label": "iii. Share M.T. Loan",
                                        //     "type": "number"
                                        // }

                                    ]
                                }
                            ]
                        },
                        {
                            "db": "reserve",
                            "label": "B. Reserve",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_reserve",
                                    "label": "B. Reserve",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "reserve_fund_section_82",
                                    "label": "B.(a) Reserve Fund under section 82 of the West Bengal Coop. Societies Act 2006 (West Bengal Act, XL of 2006)",
                                    "type": "number"
                                },
                                {
                                    "db": "reserve_for_bad_and_doubtful_debts",
                                    "label": "B.(b) Reserve for bad and doubtful debts",
                                    "type": "number"
                                },
                                {
                                    "db": "reserve_for_overdue_interest",
                                    "label": "B.(c) Reserve for overdue interest",
                                    "type": "number"
                                },
                                {
                                    "db": "other_reserves_and_funds",
                                    "label": "B.(d) Other reserves and Funds",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_other_reserves_and_funds",
                                            "label": "Total Other reserves and Funds",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "general_reserve",
                                            "label": "i.General reserve",
                                            "type": "number"
                                        },
                                        {
                                            "db": "building_fund",
                                            "label": "ii. Building_Fund",
                                            "type": "number"
                                        },
                                        {
                                            "db": "education_fund",
                                            "label": "iii. Education Fund",
                                            "type": "number"
                                        },
                                        {
                                            "db": "dividend_equalization_fund",
                                            "label": "iv. Dividend Equalization Fund",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "charity_fund_societies",
                                        //     "label": "v. Charity Fund societies",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "grant_received",
                                            "label": "v. Grant Received",
                                            "type": "number"
                                        },
                                        {
                                            "db": "any_other_fund",
                                            "label": "vi. Any other Fund",
                                            "type": "number"
                                        }

                                    ]
                                }
                            ]
                        },
                        {
                            "db": "deposit",
                            "label": "C. Deposit",
                            "type": "json",
                            "display_value": true,
                            "fields": [

                                {
                                    "db": "total_deposit",
                                    "label": "C. Deposit",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "savings_deposit",
                                    "label": "C.(b) Savings Deposit",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_savings_deposit",
                                            "label": "Total Savings Deposit",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number"
                                        },
                                        {
                                            "db": "employees",
                                            "label": "ii. Employees",
                                            "type": "number"
                                        },
                                        {
                                            "db": "self_help_group",
                                            "label": "iii. Self Help Group",
                                            "type": "number"
                                        }

                                    ]
                                },
                                {
                                    "db": "term_deposit",
                                    "label": "C.(c) Term Deposit",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_term_deposit",
                                            "label": "Total Term Deposit",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number"
                                        },
                                        {
                                            "db": "employees",
                                            "label": "ii. Employees",
                                            "type": "number"
                                        },
                                        {
                                            "db": "self_help_group",
                                            "label": "iii. Self Help Group",
                                            "type": "number"
                                        }

                                    ]
                                }
                            ]
                        },
                        {
                            "db": "borrowing",
                            "label": "D. Borrowing",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_total_borrowing",
                                    "label": " D.Borrowing",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "borrowing",
                                    "label": "D.(a) Borrowing",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_borrowing",
                                            "label": "Total Borrowing",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "government",
                                            "label": "i. Government",
                                            "type": "number"
                                        },
                                        {
                                            "db": "banks",
                                            "label": "ii. Banks",
                                            "type": "number"
                                        },
                                        {
                                            "db": "other_financial_institution",
                                            "label": "iii. Other Financial Institution",
                                            "type": "number"
                                        }

                                    ]
                                }
                            ]
                        },
                        {
                            "db": "other_liabilities",
                            "label": "E. Other Liabilities",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_other_liabilities",
                                    "label": "Total Other Liabilities",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "interest_payable_borrowing",
                                    "label": "(a) Interest payable on Borrowing",
                                    "type": "number"
                                },
                                {
                                    "db": "interest_payable_on_deposit",
                                    "label": "(b) Interest payable on Deposit (Term Deposit)",
                                    "type": "number"
                                },
                                {
                                    "db": "dividend_unpaid",
                                    "label": "(c) Dividend unpaid",
                                    "type": "number"
                                },
                                {
                                    "db": "bonus_unpaid",
                                    "label": "(d) Bonus unpaid",
                                    "type": "number"
                                },
                                {
                                    "db": "cost_of_management_unpaid",
                                    "label": "(e) Cost of management unpaid",
                                    "type": "number"
                                },
                                {
                                    "db": "any_other_contingent_liability",
                                    "label": "(f) Any other contingent liability",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "accumulated_profit",
                            "label": "F. Accumulated Profit",
                            "type": "number"
                        },
                        {
                            "db": "accumulated_loss",
                            "label": "G. Accumulated Loss",
                            "type": "number"
                        }

                    ]
                },
                {
                    index: 7,
                    name: 'Assets',
                    id: 'assets',
                    "fields": [
                        {
                            "db": "total_assets",
                            "label": "Total Assets",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "liquid_asset",
                            "label": "A. Liquid Asset",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_liquid_asset",
                                    "label": "Total Liquid Assets",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "cash",
                                    "label": "(a) Cash",
                                    "type": "number"
                                },
                                {
                                    "db": "balance_with_current_account",
                                    "label": "(b) Balance with Current Account with Banks",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "investment",
                            "label": "B. Investment",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_investment",
                                    "label": "Total Investment",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "government_securities",
                                    "label": "B.(a) Government Securities",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_government_securities",
                                            "label": "Total Government Securities",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "deposit_with_wbscb",
                                    "label": "B.(b) Deposit with WBSCB / DCCB",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_deposit_with_wbscb",
                                            "label": "Total Deposit with WBSCB / DCCB",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "deposit_with_post_Office",
                                    "label": "B.(c) Deposit with Post Office / Life Insurance Corporation",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_deposit_with_post_Office",
                                            "label": "Total Deposit with Post Office / Life Insurance Corporation",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "deposit_with_any_scheduled_bank",
                                    "label": "B.(d) Deposit with any Scheduled Bank",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_deposit_with_any_scheduled_bank",
                                            "label": "Total Deposit with any Scheduled Bank",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "shares_of_other_co_operative_societies",
                                    "label": "B.(e) Shares of other co-operative societies",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_shares_of_other_co_operative_societies",
                                            "label": "Total Shares of other co-operative societies",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "any_other_investment",
                                    "label": "B.(f) Any other investment",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_any_other_investment",
                                            "label": "Total Any other investment",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "investment_of_reserve_fund",
                            "label": "C. Investment of Reserve Fund",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_investment",
                                    "label": "Total Investment",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "government_securities",
                                    "label": "C.(a) Government Securities",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_government_securities",
                                            "label": "Total Government Securities",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "deposit_with_wbscb",
                                    "label": "C.(b) Deposit with WBSCB / DCCB",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_deposit_with_wbscb",
                                            "label": "Total Deposit with WBSCB / DCCB",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "deposit_with_post_Office",
                                    "label": "C.(c) Deposit with Post Office / Life Insurance Corporation",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_deposit_with_post_Office",
                                            "label": "Total Deposit with Post Office / Life Insurance Corporation",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "deposit_with_any_scheduled_bank",
                                    "label": "C.(d) Deposit with any Scheduled Bank",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_deposit_with_any_scheduled_bank",
                                            "label": "Total Deposit with any Scheduled Bank",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "shares_of_other_co_operative_societies",
                                    "label": "C.(e) Shares of other co-operative societies",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_shares_of_other_co_operative_societies",
                                            "label": "Total Shares of other co-operative societies",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "any_other_investment",
                                    "label": "C.(f) Any other investment",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_any_other_investment",
                                            "label": "Total Any other investment",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "principal_amount",
                                            "label": "i) Principal Amount",
                                            "type": "number"
                                        },
                                        {
                                            "db": "interest_accrued_thereupon",
                                            "label": "ii) Interest accrued thereupon",
                                            "type": "number"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "fixed_assets",
                            "label": "D. Fixed Assets (only book value after depreciation)",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_fixed_assets",
                                    "label": "Total Fixed Assets (only book value after depreciation)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "land_and_building",
                                    "label": "(a) Land and Building",
                                    "type": "number"
                                },
                                {
                                    "db": "furniture_and_fixture",
                                    "label": "(b) Furniture and fixture",
                                    "type": "number"
                                },
                                {
                                    "db": "computer_and_accessories",
                                    "label": "(c) Computer and Accessories",
                                    "type": "number"
                                },
                                {
                                    "db": "dead_stock",
                                    "label": "(d) Dead Stock",
                                    "type": "number"
                                },
                                {
                                    "db": "any_other_fixed_asset",
                                    "label": "(e) Any other fixed asset",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "loan_principal_outstanding",
                            "label": "E. Loan (Principal outstanding)",
                            "type": "json",
                            "display_value": false,
                            "fields": [

                                {
                                    "db": "total_loan_principal_outstanding",
                                    "label": "D. Loan (Principal outstanding)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "individual_loan",
                                    "label": "D.(a). Individual Loan KCC",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_individual_loan",
                                            "label": "Total Individual Loan KCC",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "msme_sector",
                                    "label": "D.(b) MSME Sector/ Small Business Loan",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_msme_sector",
                                            "label": "Total MSME Sector",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "shg",
                                    "label": "D.(c) SHG",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_shg",
                                            "label": "Total SHG",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "other",
                                    "label": "D.(d) Other including Staff Loan",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_other",
                                            "label": "Total Other",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "interest_on_loan_outstanding",
                            "label": "F. Interest on Loan outstanding",
                            "type": "json",
                            "display_value": false,
                            "fields": [

                                {
                                    "db": "total_interest_on_loan_outstanding",
                                    "label": "Total Interest on Loan outstanding",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "individual_loan",
                                    "label": "E.(a). Individual Loan KCC",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_individual_loan",
                                            "label": "Total Individual Loan KCC",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "msme_sector",
                                    "label": "E.(b) MSME Sector",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_msme_sector",
                                            "label": "Total MSME Sector",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "shg",
                                    "label": "E.(c) SHG",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_shg",
                                            "label": "Total SHG",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "other",
                                    "label": "E.(d) Other including Staff Loan",
                                    "type": "json",
                                    "display_value": false,
                                    "fields": [
                                        {
                                            "db": "total_other",
                                            "label": "Total Other",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(i). Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "(ii). Overdue",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "(iii). Non-performing",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "(iv) Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "(iv) Litigated",
                                            "type": "number"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "other_assest",
                            "label": "G.Other Assets",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_other_assest",
                                    "label": "Total Other Assets",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "tds_receivable",
                                    "label": "(a) TDS Receivable",
                                    "type": "number"
                                },
                                {
                                    "db": "gst_receivable",
                                    "label": "(b) GST Receivable",
                                    "type": "number"
                                },
                                {
                                    "db": "it_receivable",
                                    "label": "(c) IT Receivable",
                                    "type": "number"
                                },
                                {
                                    "db": "stock_in_trade",
                                    "label": "(d) Stock in Trade",
                                    "type": "number"
                                },
                                {
                                    "db": "other_assest",
                                    "label": "(e) Other Assets",
                                    "type": "number"
                                }
                            ]
                        }
                    ]
                },
                {
                    index: 8,
                    name: 'Land_Building',
                    id: 'land_building',
                    "fields": [
                        {
                            "db": "business_of_the_society",
                            "label": "A. Business of the society at its registered address is carried on at a",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "own_building_on_own_land",
                                    "label": "a) Own building on own land",
                                    "type": "bool"
                                },
                                {
                                    "db": "own_building_on_leased_land",
                                    "label": "b) Own building on leased land",
                                    "type": "bool"
                                },
                                {
                                    "db": "long_term_leased_building",
                                    "label": "c) Long term leased building",
                                    "type": "bool"
                                },
                                {
                                    "db": "hired_building",
                                    "label": "d) Hired building",
                                    "type": "bool"
                                }
                            ]
                        },
                        {
                            "db": "covered_area",
                            "label": "A.1. Covered area of the registered business place (in sq. Ft.)",
                            "type": "number"
                        },
                        {
                            "db": "depricated_value_of_building",
                            "label": "A.2. Depricated value of the building, if owned (in Rs.)",
                            "type": "number"
                        },
                        {
                            "db": "annual_license_fee",
                            "label": "A.3. Annual License Fee / Rent, if leased or hired (in Rs.)",
                            "type": "number"
                        },
                        {
                            "db": "number_of_branch_office",
                            "label": "B. Number of branch office of the society",
                            "type": "number"
                        },
                        {
                            "db": "status_of_the_branch",
                            "label": "B.1. Status of the branch offices, if any",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "number_of_own_building_on_own_land",
                                    "label": "a) Number of own building on own land",
                                    "type": "number"
                                },
                                {
                                    "db": "number_of_own_building_on_leased_land",
                                    "label": "b) Number of own building on leased land",
                                    "type": "number"
                                },
                                {
                                    "db": "number_of_long_term_leased_building",
                                    "label": "c) Number of Long term leased building",
                                    "type": "number"
                                },
                                {
                                    "db": "number_of_hired_building",
                                    "label": "d) Number of Hired building",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "total_covered_area",
                            "label": "B.2. Total covered area of the branch offices (in sq. Ft.)",
                            "type": "number"
                        },
                        {
                            "db": "depricated_value_of_branch_buildings",
                            "label": "B.3. Depricated value of the branch buildings, if owned (in Rs.)",
                            "type": "number"
                        },
                        {
                            "db": "annual_license",
                            "label": "B.4. Annual License Fee / Rent of the branch buildings, if leased or hired (in Rs.)",
                            "type": "number"
                        },
                        {
                            "db": "details_of_godown",
                            "label": "C. Details of Godown",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "owned_with_caapacity_mt",
                                    "label": "a) Owned godown with capacity in MT",
                                    "type": "number"
                                },
                                {
                                    "db": "hired_with_caapacity_mt",
                                    "label": "b) Hired godown with capacity in MT",
                                    "type": "number"
                                },
                                {
                                    "db": "owned_let_out",
                                    "label": "c) Owned godown let out, if any",
                                    "type": "number"
                                },
                                {
                                    "db": "monthly_rent_received",
                                    "label": "d) Monthly rent received if c) above yes",
                                    "type": "number"
                                },
                            ]
                        }
                    ]
                },
                {
                    index: 9,
                    name: 'MIS',
                    id: 'mis',
                    "fields": [
                        {
                            "db": "society_in_working_condition",
                            "label": "The society has the following in working condition",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "data_centre",
                                    "label": "a. Data Centre / Server Room",
                                    "type": "number"
                                },
                                {
                                    "db": "server",
                                    "label": "b. Server",
                                    "type": "number"
                                },
                                {
                                    "db": "desktop",
                                    "label": "c. Desktop (computer including monitor)",
                                    "type": "number"
                                },
                                {
                                    "db": "laptop",
                                    "label": "d. Laptop",
                                    "type": "number"
                                },
                                {
                                    "db": "ups",
                                    "label": "e. UPS (online and offline)",
                                    "type": "number"
                                },
                                {
                                    "db": "switch_for_lan",
                                    "label": "f. Switch for LAN",
                                    "type": "number"
                                },
                                {
                                    "db": "passbook_printer",
                                    "label": "g. Passbook Printer",
                                    "type": "number"
                                },
                                {
                                    "db": "laserjet_printer",
                                    "label": "h. Laserjet Printer",
                                    "type": "number"
                                },
                                {
                                    "db": "dot_matrix_printer",
                                    "label": "i. Dot Matrix Printer",
                                    "type": "number"
                                },
                                {
                                    "db": "scanner",
                                    "label": "j. Scanner",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "interconnected_by_lan",
                            "label": "How many of these computers are interconnected by LAN",
                            "type": "number"
                        },
                        {
                            "db": "configuration_of_the_server",
                            "label": "Configuration of the Server, if any",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "number_of_processors",
                                    "label": "a. Number of Processors",
                                    "type": "number"
                                },
                                {
                                    "db": "number_of_hard_discs",
                                    "label": "b. Number of Hard-discs",
                                    "type": "number"
                                },
                                {
                                    "db": "whether_it_has_any_firewall",
                                    "label": "c. Whether it has any Firewall",
                                    "type": "bool"
                                },
                                {
                                    "db": "raid_controller",
                                    "label": "d. Whether it has any RAID controller",
                                    "type": "bool"
                                },
                                {
                                    "db": "system_software",
                                    "label": "e. Whether it has any Licensed System Software",
                                    "type": "bool"
                                },
                                {
                                    "db": "application_software",
                                    "label": "f. Whether it has any Licensed Application Software",
                                    "type": "bool"
                                },
                                {
                                    "db": "cooling_Unit",
                                    "label": "g. Whether it has any Cooling Unit",
                                    "type": "bool"
                                },
                                {
                                    "db": "external_storage_space",
                                    "label": "h. Whether it has any External Storage Space",
                                    "type": "bool"
                                },
                                {
                                    "db": "data_backup_system",
                                    "label": "i. Whether it has any Data Backup System",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "whether_the_society_has_accounting_software",
                            "label": "Whether the society has the following:",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "accounting_software",
                                    "label": "a. Any dedicated Accounting Software",
                                    "type": "bool"
                                },
                                {
                                    "db": "financial_statements",
                                    "label": "b. If so, whether it can generate statutory Financial Statements",
                                    "type": "bool"
                                },
                                {
                                    "db": "deposit_module",
                                    "label": "c. If so, whether it has Deposit Module",
                                    "type": "bool"
                                },
                                {
                                    "db": "advances_module",
                                    "label": "d. If so, whether it has Loans and Advances Module",
                                    "type": "bool"
                                },
                                {
                                    "db": "investment_module",
                                    "label": "e. If so, whether it has Investment Module",
                                    "type": "bool"
                                },
                                {
                                    "db": "returns_module",
                                    "label": "f. If so, whether it has Reports & Returns Module",
                                    "type": "bool"
                                },
                                {
                                    "db": "hrms_software",
                                    "label": "g. Any dedicated HRMS Software",
                                    "type": "bool"
                                },
                                {
                                    "db": "mis_software",
                                    "label": "h. Any dedicated MIS Software",
                                    "type": "bool"
                                }
                            ]
                        },
                        {
                            "db": "whether_society_application_software",
                            "label": "Whether the society is:",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "application_software",
                                    "label": "a. having agreement with any vendor for operation and maintenance of the Application Software",
                                    "type": "bool"
                                },
                                {
                                    "db": "based_application_software",
                                    "label": "b. using any cloud based Application Software",
                                    "type": "bool"
                                }
                            ]
                        },
                        {
                            "db": "name_of_vendor",
                            "label": "Name of Vendor with whom the society is having an agreement for O&M of the Application Software, if any",
                            "type": "text"
                        }
                    ]
                },
                {
                    index: 10,
                    name: 'Depositor profile',
                    id: 'depositor_profile',
                    "fields": [
                        {
                            "db": "deposit",
                            "label": "A. Deposit",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_deposit",
                                    "label": "A. Deposit",
                                    "type": "number",
                                    "readonly": true
                                },
                                // {
                                //     "db": "current_deposit",
                                //     "label": "A.(a) Current Deposit",
                                //     "type": "json",
                                //     "display_value": true,
                                //     "fields": [
                                //         {
                                //             "db": "total_current_deposit",
                                //             "label": "Current Deposit",
                                //             "type": "number",
                                //             "readonly": true
                                //         },
                                //         {
                                //             "db": "individual",
                                //             "label": "i. Individual",
                                //             "type": "number",
                                //             "readonly": true
                                //         },
                                //         {
                                //             "db": "employees",
                                //             "label": "ii. Employees",
                                //             "type": "number",
                                //             "readonly": true
                                //         },
                                //         {
                                //             "db": "self_help_group",
                                //             "label": "iii. Self Help Group",
                                //             "type": "number",
                                //             "readonly": true
                                //         }
                                //     ]
                                // },
                                {
                                    "db": "savings_deposit",
                                    "label": "A.(a) Savings Deposit",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_savings_deposit",
                                            "label": "Savings Deposit",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "employees",
                                            "label": "ii. Employees",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "self_help_group",
                                            "label": "iii. Self Help Group",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "term_deposit_within_year",
                                    "label": "A.(b) Term Deposit",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_term_deposit",
                                            "label": "Total Term Deposit",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "employees",
                                            "label": "ii. Employees",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "self_help_group",
                                            "label": "iii. Self Help Group",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                // {
                                //     "db": "term_deposit_above_year",
                                //     "label": "A.(d) Term Deposit (payable above one year)",
                                //     "type": "json",
                                //     "display_value": true,
                                //     "fields": [
                                //         {
                                //             "db": "total_term_deposit",
                                //             "label": "Total Term Deposit (payable above one year",
                                //             "type": "number",
                                //             "readonly": true
                                //         },
                                //         {
                                //             "db": "individual",
                                //             "label": "i. Individual",
                                //             "type": "number"
                                //         },
                                //         {
                                //             "db": "employees",
                                //             "label": "ii. Employees",
                                //             "type": "number"
                                //         }
                                //
                                //     ]
                                // }
                            ]
                        },
                        {
                            "db": "number_of_depositors",
                            "label": "B. Number of Depositors",
                            "type": "json",
                            "display_value": false,
                            "fields": [

                                {
                                    "db": "total_number_of_depositors",
                                    "label": "B. Number of Depositors",
                                    "type": "number",
                                    "readonly": true
                                },
                                // {
                                //     "db": "current_depositor_no",
                                //     "label": "B.(a) Current Depositor (No.)",
                                //     "type": "json",
                                //     "display_value": true,
                                //     "fields": [
                                //         {
                                //             "db": "total_current_depositor_no",
                                //             "label": "Current Depositor (No.)",
                                //             "type": "number",
                                //             "readonly": true
                                //         },
                                //         {
                                //             "db": "individual",
                                //             "label": "i. Individual",
                                //             "type": "number"
                                //         },
                                //         {
                                //             "db": "employees",
                                //             "label": "ii. Employees",
                                //             "type": "number"
                                //         },
                                //         {
                                //             "db": "self_help_group",
                                //             "label": "iii. Self Help Group",
                                //             "type": "number"
                                //         }
                                //     ]
                                // },
                                {
                                    "db": "savings_depositor_no",
                                    "label": "B.(b) Savings Depositor (No.)",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_savings_depositor_no",
                                            "label": "Savings Depositor (No.)",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number"
                                        },
                                        {
                                            "db": "employees",
                                            "label": "ii. Employees",
                                            "type": "number"
                                        },
                                        {
                                            "db": "self_help_group",
                                            "label": "iii. Self Help Group",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "term_depositor_no_within_year",
                                    "label": "B.(c) Term Depositor (No.) (payable within one year)",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_term_depositor_no_within_year",
                                            "label": "Term Depositor (No.) (payable within one year)",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "individual",
                                            "label": "i. Individual",
                                            "type": "number"
                                        },
                                        {
                                            "db": "employees",
                                            "label": "ii. Employees",
                                            "type": "number"
                                        },
                                        {
                                            "db": "self_help_group",
                                            "label": "iii. Self Help Group",
                                            "type": "number"
                                        }
                                    ]
                                },
                                // {
                                //     "db": "term_depositor_no_above_year",
                                //     "label": "B.(d) Term Depositor (No.) (payable above one year)",
                                //     "type": "json",
                                //     "display_value": true,
                                //     "fields": [
                                //         {
                                //             "db": "total_term_depositor_no_above_year",
                                //             "label": "Term Depositor (No.) (payable above one year)",
                                //             "type": "number",
                                //             "readonly": true
                                //         },
                                //         {
                                //             "db": "individual",
                                //             "label": "i. Individual",
                                //             "type": "number"
                                //         },
                                //         {
                                //             "db": "employees",
                                //             "label": "ii. Employees",
                                //             "type": "number"
                                //         }
                                //
                                //     ]
                                // }
                            ]
                        },
                        {
                            "db": "casa_deposit",
                            "label": "C. (a) CASA Deposit",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "term_deposit",
                            "label": "C. (b) Term Deposit",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "total_deposit",
                            "label": "C. (c) Total Deposit",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "percentage_of_casa_deposit",
                            "label": "C. (d) Percentag of CASA Deposit to Total Deposit",
                            "type": "number",
                            "readonly": true
                        },

                        {
                            "db": "casa_depositor",
                            "label": "D. (a) No of CASA Depositor",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "term_depositor",
                            "label": "D. (b) No of Term Depositor",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "total_depositor",
                            "label": "D. (c) Total Depositor",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "percentage_of_casa_depositor",
                            "label": "D. (d) Percentag of CASA Depositor to Total Depositor",
                            "type": "number",
                            "readonly": true
                        }
                    ]
                },
                {
                    index: 11,
                    name: 'Loanee profile',
                    id: 'loanee_profile',
                    "fields": [
                        {
                            "db": "loan_principal_outstanding",
                            "label": "A. Loan (Principal outstanding) matured / maturing within one year",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_loan_principal_outstanding",
                                    "label": "A. Loan (Principal outstanding) matured / maturing within one year",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "individual_loan",
                                    "label": "(a) Individual Loan - KCC",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_individual_loan",
                                            "label": "Individual Loan -  KCC",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "msme_sector",
                                    "label": "(b) Small Business Loans",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_msme_sector",
                                            "label": "Small Business Loans",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "shg",
                                    "label": "(c) SHG ",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_shg",
                                            "label": "Total SHG",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "other",
                                    "label": "(d) Other including Staff Loan",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_other",
                                            "label": "Other",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "interest_on_loan_outstanding",
                            "label": "B. Interest on Loan outstanding",
                            "type": "json",
                            "display_value": false,
                            "fields": [

                                {
                                    "db": "total_interest_on_loan_outstanding",
                                    "label": "B. Interest on Loan outstanding",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "individual_loan",
                                    "label": "(a) Individual Loan - KCC",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_individual_loan",
                                            "label": "Total Individual Loan - KCC",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "msme_sector",
                                    "label": "(b) Small Business Loans",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_msme_sector",
                                            "label": "i.  Small Business Loans",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "shg",
                                    "label": "(c) SHG ",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_shg",
                                            "label": "SHG",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "other",
                                    "label": "(d) Other ",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_other",
                                            "label": "Other",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "no_of_loanee_members",
                            "label": "C. No. of Loanee members",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_no_of_loanee_members",
                                    "label": "C. No. of Loanee members",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "no_of_individual_loanee_member",
                                    "label": "(a) No. of Individual Loanee member - KCC",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member",
                                            "label": "No. of Individual Loanee member - KCC",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "no_of_individual_loanee_member_in_msme_sector",
                                    "label": "(b) No. of Individual Loanee member in Small Business",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member_in_msme_sector",
                                            "label": "No. of Individual Loanee member in Small Business",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "no_of_individual_loanee_member_in_shg_fold",
                                    "label": "(c) No. of Individual Loanee member in SHG fold",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member_in_shg_fold",
                                            "label": "No. of Individual Loanee member in SHG fold",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "no_of_individual_loanee_member_in_other_sector",
                                    "label": "(d) No. of Individual Loanee member in Other sector (Including Staff Loan)",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member_in_other_sector",
                                            "label": "No. of Individual Loanee member in Other sector (Including Staff Loan)",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number"
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number"
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number"
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "average_loan_portfolio_of_loanee_members",
                            "label": "E. Average Loan portfolio of Loanee members",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_average_loan_portfolio_of_loanee_members",
                                    "label": "E. Average Loan portfolio of Loanee members",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "no_of_individual_loanee_member",
                                    "label": "(a) No. of Individual Loanee member - KCC",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member",
                                            "label": "Total No. of Individual Loanee member - KCC",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "no_of_individual_loanee_member_in_msme_sector",
                                    "label": "(b) No. of Individual Loanee member in Small Business",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member_in_msme_sector",
                                            "label": "No. of Individual Loanee member in Small Business",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "no_of_individual_loanee_member_in_shg_fold",
                                    "label": "(c) No. of Individual Loanee member in SHG fold",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member_in_shg_fold",
                                            "label": "No. of Individual Loanee member in SHG fold",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                },
                                {
                                    "db": "no_of_individual_loanee_member_in_other_sector",
                                    "label": "(d) No. of Individual Loanee member in Other sector (Including Staff Loan)",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_no_of_individual_loanee_member_in_other_sector",
                                            "label": "No. of Individual Loanee member in Other sector (Including Staff Loan)",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "i. Current",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "overdue",
                                            "label": "ii. Overdue ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "non_performing",
                                            "label": "iii. Non-performing  ",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        // {
                                        //     "db": "bad",
                                        //     "label": "iv. Bad",
                                        //     "type": "number",
                                        //     "readonly": true
                                        // },
                                        {
                                            "db": "litigated",
                                            "label": "iv. Litigated",
                                            "type": "number",
                                            "readonly": true
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "db": "percentange_of_loan_repayment",
                            "label": "F. Percentange of Loan repayment",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_individual_loan_repaid",
                                    "label": "(a) Total Individual Loan repaid KCC",
                                    "type": "number"
                                },
                                {
                                    "db": "total_loan_repaid_msme_sector",
                                    "label": "(b) Total Loan repaid - Small Business",
                                    "type": "number"
                                },
                                {
                                    "db": "total_loan_repaid_shg",
                                    "label": "(c) Total Loan repaid - SHG ",
                                    "type": "number"
                                },
                                {
                                    "db": "total_loan_repaid_other",
                                    "label": "(d) Total Loan repaid -  Other (Including MT Agril & Service Sector)",
                                    "type": "number"
                                }

                            ]
                        },
                        {
                            "db": "total_percentage_of_loan_repayment",
                            "label": "Total percentage of Loan repayment by members",
                            "type": "number",
                            "is_percentage": true
                        },
                        {
                            "db": "classification_of_asset",
                            "label": "G. Classification of Asset (Loan)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "performing_asset",
                                    "label": "G.1. Performing Asset",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_performing_asset",
                                            "label": "Performing Asset",
                                            "type": "number",
                                            "readonly": true
                                        },
                                        {
                                            "db": "current",
                                            "label": "(a) Current",
                                            "type": "number"
                                        },
                                        {
                                            "db": "overdue_less_than_quarter",
                                            "label": "(b) Overdue less than 1 quarter",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "total_non_performing_asset",
                                    "label": "G.2. Non-Performing Asset",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "total_sub_standard_asset",
                                    "label": "G.2.1. Sub-Standard Asset",
                                    "description": "which has remained NPA for a period less than or equal to 12 months",
                                    "type": "number"
                                },
                                {
                                    "db": "doubtful_asset",
                                    "label": "G.2.2. Doubtful Asset",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_doubtful_asset",
                                            "label": "G.2.2. Doubtful Asset",
                                            "type": "number",
                                            "readonly": true,
                                            "description": "remained in the sub­standard category for a period of 12 months."
                                        },
                                        {
                                            "db": "doubtful_upto_year",
                                            "label": "(a) Doubtful upto 1 year",
                                            "type": "number"
                                        },
                                        {
                                            "db": "doubtful_more_than_1_year_and_upto_3_years",
                                            "label": "(b) Doubtful more than 1 year and upto 3 years",
                                            "type": "number"
                                        },
                                        {
                                            "db": "doubtful_more_than_3_years",
                                            "label": "(c) Doubtful more than 3 years",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "loss_asset",
                                    "label": "G.2.3. Loss Asset",
                                    "type": "number",
                                    "description": "remained in the sub­standard category for a period of 12 months."
                                }
                            ]
                        }


                    ]
                },
                {
                    index: 12,
                    name: 'Earnings',
                    id: 'earnings',
                    "fields": [
                        {
                            "db": "interest_discount_received",
                            "label": "1. Interest / discount received (2+3)",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "discount_received_on_loans_and_advances",
                            "label": "2. Interest / discount received on loans and advances (other than from banks)",
                            "type": "number"
                        },
                        {
                            "db": "interest_on_investments",
                            "label": "3. Interest on investments",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_interest_on_investments",
                                    "label": "Total Interest on investments",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "interest_received_on_standard_investments",
                                    "label": "i) Interest received /accrued on Standard Investments",
                                    "type": "number"
                                },
                                {
                                    "db": "dividend",
                                    "label": "ii) Bonus / Dividend etc.",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "other_operating_income",
                            "label": "4. Other Operating income",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_other_operating_income",
                                    "label": "Total Other Operating income",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "fee_commission",
                                    "label": "i) Fee, Commission, etc.",
                                    "type": "number"
                                },
                                {
                                    "db": "profit_loss",
                                    "label": "ii) Profit (+) / Loss (-) on sale of securities",
                                    "type": "number"
                                },
                                {
                                    "db": "miscellaneous_income",
                                    "label": "iii) Miscellaneous Income (if applicable)",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "operating_income_trading_account",
                            "label": "5. Operating Income transferred from Trading Account",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "total_operating_income",
                            "label": "6. Total Operating Income [1+4+5]",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "non_operating_income",
                            "label": "7. Non Operating Income [Capital gains / losses (+)/ (-)]",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_non_operating_income",
                                    "label": "Total Non Operating Income [Capital gains / losses (+)/ (-)]",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "profit_loss_of_fixed_assets",
                                    "label": "i) Profit / (Loss) on sale of fixed assets (+) / (-)",
                                    "type": "number"
                                },
                                {
                                    "db": "profit_loss_of_other_assets",
                                    "label": "ii) Profit / (Loss) on sale of other assets (+) / (-)",
                                    "type": "number"
                                },
                                {
                                    "db": "non_operating_income",
                                    "label": "iii) Other Non-operating Income / (Expenses) (+) / (-)",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "total_income",
                            "label": "8. Total income [6+7]",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "interest_paid",
                            "label": "9. Interest paid",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_interest_paid",
                                    "label": "Total Interest paid",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "customer_deposits",
                                    "label": "i) On Customer deposits",
                                    "type": "number"
                                },
                                {
                                    "db": "bank_borrowings",
                                    "label": "ii) On bank borrowings",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "iii) Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "operating_expenses",
                            "label": "10. Operating Expenses",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_operating_expenses",
                                    "label": "Total Operating Expenses",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "staff_expenses",
                                    "label": "i) Staff expenses",
                                    "type": "number"
                                },
                                {
                                    "db": "directors_fees",
                                    "label": "ii) Directors fees/expenses",
                                    "type": "number"
                                },
                                {
                                    "db": "auditors_fees",
                                    "label": "iii) Auditors fees",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "other_operating_expenses",
                            "label": "11. Other Operating expenses",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_other_operating_expenses",
                                    "label": "Total Other Operating Expenses",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "rent_taxes_lighting",
                                    "label": "i) Rent, taxes and lighting",
                                    "type": "number"
                                },
                                {
                                    "db": "insurance",
                                    "label": "ii) Insurance",
                                    "type": "number"
                                },
                                {
                                    "db": "law_charges",
                                    "label": "iii) Law charges",
                                    "type": "number"
                                },
                                {
                                    "db": "postage",
                                    "label": "iv) Postage, telegrams and stamps",
                                    "type": "number"
                                },
                                {
                                    "db": "stationery",
                                    "label": "v) Stationery and printing ",
                                    "type": "number"
                                },
                                {
                                    "db": "advertisement",
                                    "label": "vi) Advertisement and publicity ",
                                    "type": "number"
                                },
                                {
                                    "db": "depreciation",
                                    "label": "vii) Depreciation on societies' property  ",
                                    "type": "number"
                                },
                                {
                                    "db": "repairs",
                                    "label": "viii) Repairs and maintenance",
                                    "type": "number"
                                },
                                {
                                    "db": "others",
                                    "label": "ix) Others",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "operating_expenses_from_trading_account",
                            "label": "12. Operating Expenses transferred from Trading Account",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "total_operating_expenses",
                            "label": "13. Total operating expenses [9+10+11+12]",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "operating_profit_loss",
                            "label": "14. Operating Profit/Loss [6-13]",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "write_off",
                            "label": "15. Write off",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_write_off",
                                    "label": "Total Write off",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "bad_debts_written_off",
                                    "label": "i) Bad Debts written off",
                                    "type": "number"
                                },
                                {
                                    "db": "other_assets_written_off",
                                    "label": "ii) Other assets written off",
                                    "type": "number"
                                },
                                {
                                    "db": "capitalised_expenditure",
                                    "label": "iii) Capitalised expenditure written off",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "provision_against_risks",
                            "label": "16. Provision against risks/ contingencies",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_provision_against_risks",
                                    "label": "Total Provision against risks/ contingencies",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "provision_for_loan_losses",
                                    "label": "i) Provision for loan losses (BDDR, Spl. BDDR, etc.)",
                                    "type": "number"
                                },
                                {
                                    "db": "provision_for_depreciation",
                                    "label": "ii) Provision for depreciation in investments",
                                    "type": "number"
                                },
                                {
                                    "db": "other_risk",
                                    "label": "iii) Other risk provisions towards losses on account of frauds, misappropriations, robberies, claims against the bank, etc.",
                                    "type": "number"
                                },
                                {
                                    "db": "other_provisions",
                                    "label": "iv) Other provisions",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "net_operating_profit",
                            "label": "17. Net Operating Profit (+)/Loss (-) before taxes - (EBPT) [14-15-16]",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "provisions_for_taxes",
                            "label": "18. Provisions for taxes (if any)",
                            "type": "number"
                        },
                        {
                            "db": "net_profit",
                            "label": "19. Net Profit (+)/ Loss (-) after taxes [17-18]",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "balance_of_previous",
                            "label": "20. Balance of previous year’s profit (+) / Accumulated losses (-)",
                            "type": "number"
                        },
                        {
                            "db": "net_distributable_profit_loss",
                            "label": "21. Net distributable profit (+) / Accumulated losses (-) [19 + 20]",
                            "type": "json",
                            "fields": [
                                {
                                    "db": "total_net_distributable_profit_loss",
                                    "label": "Net distributable profit (+) / Accumulated losses (-) [19 + 20]",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "accumulated_profit_of_current_year",
                                    "label": "21(a). Accumulated Profit of current year (if 19 is +ve)",
                                    "type": "number"
                                },
                                {
                                    "db": "accumulated_loss_of_current_year",
                                    "label": "21(b). Accumulated Loss of current year (if 19 is -ve)",
                                    "type": "number"
                                },
                            ]
                        },
                        {
                            "db": "appropriation_of_profits",
                            "label": "22. Appropriation of profits",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_appropriation_of_profits",
                                    "label": "Total Appropriation of profits",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "appropriation_towards_statutory_reserves",
                                    "label": "i) Appropriation towards Statutory Reserves",
                                    "type": "number"
                                },
                                {
                                    "db": "appropriation_towards_building_fund",
                                    "label": "ii) Appropriation towards Building Fund",
                                    "type": "number"
                                },
                                {
                                    "db": "appropriation_towards_dividend_equalisation_fund",
                                    "label": "iii) Appropriation towards Dividend Equalisation Fund",
                                    "type": "number"
                                },
                                {
                                    "db": "appropriation_towards_other_reserves",
                                    "label": "iv) Appropriation towards Other Reserves/ Provisions",
                                    "type": "number"
                                },
                                {
                                    "db": "dividend",
                                    "label": "v) Dividend",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "net_interest_income",
                            "label": "23. Net interest income",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "non_interest_income",
                            "label": "24. Non-interest income",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "non_interest_expenditure",
                            "label": "25. Non-interest expenditure",
                            "type": "number",
                            "readonly": true
                        },
                        {
                            "db": "number_of_employees",
                            "label": "26. Number of Employees",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_number_of_employees",
                                    "label": "Total Number of Employees",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "permanent_employees",
                                    "label": "26(a). Number of permanent employees",
                                    "type": "number"
                                },
                                {
                                    "db": "casual_employees",
                                    "label": "26(b). Number of casual employees",
                                    "type": "number"
                                },
                                {
                                    "db": "contractual_employees",
                                    "label": "26(c). Number of contractual employees",
                                    "type": "number"
                                },
                                {
                                    "db": "contingent_menials",
                                    "label": "26(d). Number of contingent menials",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "expenditure_for_salary",
                            "label": "27. Expenditure for salary / emoluments of the Employees",
                            "type": "json",
                            "display_value": false,
                            "fields": [
                                {
                                    "db": "total_expenditure_for_salary",
                                    "label": "Total Expenditure for salary / emoluments of the Employees",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "permanent_employees",
                                    "label": "27(a). Expenditure for salary / emoluments of permanent employees",
                                    "type": "number"
                                },
                                {
                                    "db": "casual_employees",
                                    "label": "27(b). Expenditure for salary / emoluments of casual employees",
                                    "type": "number"
                                },
                                {
                                    "db": "contractual_employees",
                                    "label": "27(c). Expenditure for salary / emoluments of contractual employees",
                                    "type": "number"
                                },
                                {
                                    "db": "contingent_menials",
                                    "label": "27(d). Expenditure for salary / emoluments of contingent menials",
                                    "type": "number"
                                }
                            ]
                        },
                        // {
                        //     "db": "professional_qualification_of_the_employees",
                        //     "label": "28. Professional / Educational qualification of the Employees",
                        //     "type": "json",
                        //     "display_value": false,
                        //     "fields": [
                        //         {
                        //             "db": "total_professional_qualification_of_the_employees",
                        //             "label": "Total Professional / Educational qualification of the Employees",
                        //             "type": "number",
                        //             "readonly": true
                        //         },
                        //         {
                        //             "db": "number_of_employees_having_professional_degree",
                        //             "label": "28(a). Number of employees having Professional Degree / Diploma",
                        //             "type": "number"
                        //         },
                        //         {
                        //             "db": "number_of_employees_having_graduation_degree",
                        //             "label": "28(b). Number of employees having Graduation Degree",
                        //             "type": "number"
                        //         },
                        //         {
                        //             "db": "number_of_employees_completed_certificate",
                        //             "label": "28(c). Number of employees completed certificate training course",
                        //             "type": "number"
                        //         },
                        //         {
                        //             "db": "number_of_employees_having_madhyamik_equivalent",
                        //             "label": "28(d). Number of employees having Madhyamik equivalent educational qualification",
                        //             "type": "number"
                        //         }
                        //     ]
                        // }
                    ]
                },
                {
                    index: 13,
                    name: 'Government Share_Loan',
                    id: 'government_share_loan',
                    "fields": [
                        {
                            "db": "government_share_loan",
                            "label": "Government Share & Loan (Rs.)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "amount_of_paid_up_share_capital",
                                    "label": "1. Amount of Paid-up Share Capital:",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_amount_of_paid_up_share_capital",
                                            "label": "Total Amount of Paid-up Share Capital",
                                            "type": "number"
                                        },
                                        {
                                            "db": "of_which_state_share",
                                            "label": "1.(a)of which - State Share",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "amount_of_state_share",
                                    "label": "2. Amount of State Share fallen due for redemption :",
                                    "type": "number"
                                },
                                {
                                    "db": "amount_of_state_share_redeemed",
                                    "label": "3. Amount of State Share redeemed during last 3 years :",
                                    "type": "number"
                                },
                                {
                                    "db": "amount_of_outstanding_govt_loan",
                                    "label": "4.Amount of outstanding Govt. Loan :",
                                    "type": "json",
                                    "display_value": true,
                                    "fields": [
                                        {
                                            "db": "total_amount_of_outstanding_govt_loan",
                                            "label": "Total Amount of outstanding Govt. Loan",
                                            "type": "number"
                                        },
                                        {
                                            "db": "of_which_overdue",
                                            "label": "4.(a) of which overdue :",
                                            "type": "number"
                                        }
                                    ]
                                },
                                {
                                    "db": "amount_of_govt_loan_repaid",
                                    "label": "5. Amount of Govt. Loan repaid during last 3 years :",
                                    "type": "number"
                                },
                                {
                                    "db": "amount_of_interest_due",
                                    "label": "6. Amount of Interest due :",
                                    "type": "number"
                                },
                                {
                                    "db": "amount_of_interest_repaid",
                                    "label": "7. Amount of Interest repaid during last 3 years : ",
                                    "type": "number"
                                },
                                {
                                    "db": "amount_of_dividend_paid",
                                    "label": "8. Amount of Dividend paid to State Govt. during last 3 years :",
                                    "type": "number"
                                }

                            ]
                        }
                    ]

                },
                {
                    index: 14,
                    name: 'SHG',
                    id: 'shg',
                    "fields": [
                        {
                            "db": "number_of_shgs_formed",
                            "label": "1. Number of SHGs Formed ",
                            "type": "number"
                        },
                        {
                            "db": "number_of_members",
                            "label": "2. Number of Members ",
                            "type": "number"
                        },
                        {
                            "db": "out_of_number_of_members",
                            "label": "3. out of Number of Members -",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_members",
                                    "label": "Total Members",
                                    "type": "number"
                                },
                                {
                                    "db": "male_members",
                                    "label": "(a). Male Members",
                                    "type": "number"
                                },
                                {
                                    "db": "female_members",
                                    "label": "(b). Female Members",
                                    "type": "number"
                                }
                            ]
                        },
                        {
                            "db": "amount_of_deposit_mobilized",
                            "label": "4. Amount of Deposit Mobilized (Rs.)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_amount_of_deposit_mobilized",
                                    "label": "Amount of Deposit Mobilized (Rs.)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "savings_deposit",
                                    "label": "(a) Savings Deposit",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "term_deposit",
                                    "label": "(b) Term Deposit",
                                    "type": "number",
                                    "readonly": true
                                }
                            ]
                        },
                        {
                            "db": "number_of_shgs_credit_linked",
                            "label": "5. Number of SHGs Credit Linked ",
                            "type": "number"
                        },
                        {
                            "db": "number_of_shg_members_credit_linked",
                            "label": "6. Number of SHG Members Credit linked",
                            "type": "number"
                        },
                        {
                            "db": "amount_of_loan_outstanding",
                            "label": "7. Amount of Loan outstanding (Rs.)",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "total_amount_of_loan_outstanding",
                                    "label": "Amount of Loan outstanding (Rs.)",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "current",
                                    "label": "(a) Current",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "overdue",
                                    "label": "(b) Overdue",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "non_performing",
                                    "label": "(c) Non-performing",
                                    "type": "number",
                                    "readonly": true
                                },
                                {
                                    "db": "litigated",
                                    "label": "(d) Litigated",
                                    "type": "number",
                                    "readonly": true
                                }
                            ]
                        },
                        {
                            "db": "amount_of_shg_Loan_issued_during_the_year",
                            "label": "8. Amount of SHG Loan issued during the year (Rs.) ",
                            "type": "number"
                        },
                        {
                            "db": "of_which",
                            "label": "9. of which - ",
                            "type": "json",
                            "display_value": true,
                            "fields": [
                                {
                                    "db": "amount_repaid",
                                    "label": "(a) Amount repaid  (Rs.)",
                                    "type": "number"
                                },
                                {
                                    "db": "percentage_of_collection",
                                    "label": "(b) Percentage of collection",
                                    "type": "number",
                                    "is_percentage": true
                                }
                            ]
                        },
                        {
                            "db": "number_of_shgs_digitised",
                            "label": "10. Number of SHGs Digitised ",
                            "type": "number"
                        },
                        {
                            "db": "number_of_shg_member_details_uploaded_in_shg_portal",
                            "label": "11. Number of SHG Member details uploaded in SHG Portal ",
                            "type": "number"
                        }
                    ]
                }
            ],
            activeTab: 0,
            ranges: [],
            society: society,
            managementStatuses: managementStatuses,
            auditYears: [],
            maxLimOfTotalEmp: 0,
            blocks: [],
            total_qualified: 0
        }
    },
    mounted() {
        this.$parent.$parent.title = this.$t('page.coop.page_label.add');
        this.selected_template = 24;
        this.tabs = this.pacs;
        this.init();
    },
    methods: {
        async init() {
            await Promise.all([
                this.getRanges(),
                this.getAuditYear()
            ])
        },
        async getRanges() {
            try {
                let {data} = await this.$api('coops/ranges');
                this.ranges = data;
            } catch (e) {

            }
        },
        navigate(index) {
            this.activeTab = index;
        },
        async submit() {
            if (this.coop.assets.total_assets === this.coop.liabilities.total_liabilities) {
                try {
                    let found_range = this.ranges.find(r => r.range_id === this.coop.range);
                    this.coop.range = found_range ? found_range.name : null;
                    this.coop.members.individual_voters.total = Number(this.coop.members.individual_voters.total);
                    this.coop.members.individual_voters.male = Number(this.coop.members.individual_voters.male);
                    this.coop.members.individual_voters.female = Number(this.coop.members.individual_voters.female);
                    this.coop.members.individual_voters.general = Number(this.coop.members.individual_voters.general);
                    this.coop.members.individual_voters.sc = Number(this.coop.members.individual_voters.sc);
                    this.coop.members.individual_voters.st = Number(this.coop.members.individual_voters.st);
                    this.coop.members.individual_voters.obc = Number(this.coop.members.individual_voters.obc);
                    this.coop.members.individual_voters.minority = Number(this.coop.members.individual_voters.minority);

                    this.coop.members.nominal = Number(this.coop.members.nominal);
                    this.coop.members.self_help_groups = Number(this.coop.members.self_help_groups);
                    this.coop.members.coop_societies = Number(this.coop.members.coop_societies);
                    this.coop.members.delegates = Number(this.coop.members.delegates);

                    this.coop.no_of_employee_with_madhyamik_equivalent = Number(this.coop.no_of_employee_with_madhyamik_equivalent);
                    this.coop.no_of_employee_with_certificate_training = Number(this.coop.no_of_employee_with_certificate_training);
                    this.coop.no_of_employee_with_graduate = Number(this.coop.no_of_employee_with_graduate);
                    this.coop.no_of_employee_with_diploma = Number(this.coop.no_of_employee_with_diploma);

                    this.coop.employees.total = Number(this.coop.employees.total);
                    this.coop.employees.permanent = Number(this.coop.employees.permanent);
                    this.coop.employees.temporary = Number(this.coop.employees.temporary);
                    this.coop.employees.casual = Number(this.coop.employees.casual);
                    this.coop.employees.adhoc = Number(this.coop.employees.adhoc);
                    this.coop.employees.trained = Number(this.coop.employees.trained);
                    this.coop.employees.untrained = Number(this.coop.employees.untrained);
                    this.coop.registration_year = this.selected_year;
                    this.coop.coop_society_type = this.templates.find(t => t.id === this.selected_template).type_of_coop;
                    this.coop.add_on_number = this.coop.registration.addon_number;

                    await this.$api.post('/coops', this.coop);
                    this.$toast.success(this.$t('toastr.create-coop-msg'));
                    await this.$router.push({name: 'coops'});
                } catch (e) {
                    console.log(e);
                    this.$toast.error(this.$t('toastr.create-coop-err-msg'));
                }
            } else {
                this.$toast.error(`Total Assets(${this.coop.assets.total_assets}) & Total Liabilities(${this.coop.liabilities.total_liabilities}) are different`);
            }
        },
        async getBlocks(range_id) {
            if (range_id) {
                this.blocks = [];
                try {
                    let {data} = await this.$api.get(`/coops/ranges/${range_id}/blocks`);
                    this.blocks = data;
                } catch (e) {

                }
            }
        },
        getAuditYear() {
            let d = new Date();
            let end = d.getFullYear();
            let start = 2000;
            while (start < end) {
                this.auditYears.push(`${start}-${(++start)}`)
            }
        },
        calculateTotalEmp() {
            this.coop.employees.total = Number(this.coop.employees.trained) + Number(this.coop.employees.untrained);
        },
        calculateMaxLimOfTotalEmp() {
            this.maxLimOfTotalEmp = Number(this.coop.employees.permanent) + Number(this.coop.employees.casual) + Number(this.coop.employees.temporary);
        },
        employeeTotalValidation(value) {
            value = Number(value);
            const regex = /^[1-9]\d*$/;
            if (!regex.test(value)) {
                return 'Not a valid number';
            } else if (value < 1) {
                return 'This field should be greater than 0'
            } else if (this.maxLimOfTotalEmp > 0 && (value > this.maxLimOfTotalEmp || value < this.maxLimOfTotalEmp)) {
                return 'This field should be equal to the sum of number of "Permanent", "Temporary" & "Casual / Contingent" employees.'
            } else if (value > 500) {
                return 'This field should be less than 500'
            }
            return true;
        },
        validateAddonNumber(value) {
            value = value ? value.toString() : null;
            if (!value) {
                return 'The field is required';
            } else if (value.length > 16 || value.length < 16) {
                return 'This field should be 16 character long'
            }
            return true;
        },
        calculateTotalQualified() {
            if (this.coop) {
                this.total_qualified = Number(this.coop.no_of_employee_with_diploma) + Number(this.coop.no_of_employee_with_graduate) + Number(this.coop.no_of_employee_with_certificate_training) + Number(this.coop.no_of_employee_with_madhyamik_equivalent)
            }
        }
    }
}
</script>

<style scoped>

</style>