<template>
    <div class="row row-sm">
        <div class="col-12">
            <Form @submit="submit" ref="form" v-slot="{errors}">
                <div class="card card-dashboard-twelve">
                    <div class="card-header border-bottom">
                        <h5 class="card-title">{{shg.name}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.shg.coop.form_label')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Multiselect
                                        v-model="coop_id"
                                        :placeholder="$t('page.shg.coop.placeholder')"
                                        :close-on-select="true"
                                        :options="coops"
                                        class="multiselect-blue"
                                        @search-change="getCoops($event)"
                                        :searchable="true"/>
<!--                                {{coop_id}}-->
<!--                                <Multiselect :options="coops"-->
<!--                                             @search-change="getCoops($event)"-->
<!--                                             :searchable="true"-->
<!--                                             label="name"-->
<!--                                             :placeholder="$t('page.shg.coop.placeholder')"-->
<!--                                             trackBy="name"-->
<!--                                             v-model="coop_id">-->
<!--                                    <template v-slot:singleLabel="{ coop_id }">-->
<!--                                        <div class="multiselect-single-label">-->
<!--                                            {{ coop_id.name }}-->
<!--                                        </div>-->
<!--                                    </template>-->

<!--                                    <template v-slot:option="{ option }">-->
<!--                                        {{ option.name }}-->
<!--                                    </template>-->
<!--                                </Multiselect>-->
<!--                                <Field :class="{ 'is-invalid' : errors.coop }" as="select" class="form-control"-->
<!--                                       name="coop" rules="required"-->
<!--                                       v-model="shg.coop_id">-->
<!--                                    <option :value="null">{{$t('page.shg.coop.placeholder')}}</option>-->
<!--                                    <option :value="coop.coop_id" v-for="coop in coops">{{coop.name}}</option>-->
<!--                                </Field>-->
<!--                                <span class="invalid-feedback" v-if="errors.coop">{{ errors.coop }}</span>-->
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.shg.name.text')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="name" rules="required"
                                       class="form-control" v-model="shg.name"
                                       :class="{ 'is-invalid' : errors.name }"
                                       :placeholder="$t('page.shg.name.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.name">{{ errors.name}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.shg.address.text')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="textarea" name="address" rules="required"
                                       class="form-control" rows="3" v-model="shg.address"
                                       :class="{ 'is-invalid' : errors.address }"
                                       :placeholder="$t('page.shg.address.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.address">{{ errors.address }}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.shg.contact_no.text')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="contact_no" rules="required"
                                       class="form-control" v-model="shg.contact_number"
                                       :class="{ 'is-invalid' : errors.contact_no }"
                                       :placeholder="$t('page.shg.contact_no.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.contact_no">{{ errors.contact_no}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.shg.no_of_members.text')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="no_of_members" class="form-control"
                                       :class="{'is-invalid': errors.no_of_members }" rules="required|integer|min_value:0"
                                       v-model="shg.no_of_members"
                                       :placeholder="$t('page.shg.no_of_members.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.no_of_members">{{errors.no_of_members}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right btn-toolbar">
                            <button class="btn btn-success mr-2" type="submit">{{$t('page.shg.button.submit')}}</button>
                            <button class="btn btn-light" type="reset">{{$t('page.shg.button.reset')}}</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import Multiselect from '@vueform/multiselect'

    export default {
        name: "EditShg",
        props: ["shgid"],
        components: {Form, Field, Multiselect},
        data() {
            return {
                coop_id: null,
                shg: {
                    coop_id: null,
                    name: null,
                    address: null,
                    contact_number: null,
                    no_of_members: null
                },
                coops: [],
            }
        },
        mounted() {
            this.$parent.$parent.title = this.$t('page.shg.page_label.add');
            this.init();
        },
        methods: {
            async init() {
                await this.getCoops();
                        await this.getShg()
            },
            async getCoops() {
                try {
                    let {data} = await this.$api.get('coops', {
                        params: {
                            is_valid: true,
                            page: 0,
                            per_page: 1000
                        }
                    });
                    this.coops = data.coops;
                    this.coops = data.coops.map(coop=> ({
                        value: coop.coop_id,
                        label: coop.name+' ('+coop.registration.number+')'
                    }));
                } catch (e) {

                }
            },
            async getShg() {
                try {
                    let {data} = await this.$api.get(`/shgs/${this.shgid}`);
                    this.shg = data;
                    this.coop_id = this.shg.coop_id;
                } catch (e) {

                }
            },

            async submit() {
                try {
                    this.shg.no_of_members = Number(this.shg.no_of_members);
                    this.shg.coop_id  = this.coop_id;
                    await this.$api.put(`/shgs/${this.shgid}`, this.shg);
                    this.$toast.success(this.$t('toastr.update-shg-msg'));
                    await this.$router.push({name: 'shgs'});
                } catch (e) {
                    this.$toast.error(this.$t('toastr.update-shg-err-msg'));
                }
            }
        }
    }
</script>

<style scoped>

</style>