<template>
    <div class="row row-sm">
        <div class="col-12">
            <Form @submit="submit" ref="form" v-slot="{errors}">
                <div class="card card-dashboard-twelve">
                    <div class="card-body">
                        <div class="row align-items-center mg-b-10 mg-t-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">Old Password</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field :class="{ 'is-invalid' : errors.old_pass }" :placeholder="'Enter Old Password'" as="input" class="form-control"
                                       name="old_pass" rules="required"
                                       type="password"
                                       v-model="auth.old_password"/>
                                <span class="invalid-feedback" v-if="errors.old_pass">{{ errors.old_pass }}</span>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">New Password</label>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-6">
                                <Field :class="{ 'is-invalid' : errors.new_pass }" :placeholder="'Enter New Password'" :rules="validatePass" as="input"
                                       class="form-control" name="new_pass" type="password"
                                       v-model="auth.new_password"
                                       vid="password"/>
                                <span class="invalid-feedback" v-if="errors.new_pass">{{ errors.new_pass }}</span>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">Confirm Password</label>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-6">
                                <Field :class="{ 'is-invalid' : errors.cnf_pass }" :placeholder="'Confirm Password'" :rules="validateCnfPass" as="input"
                                       class="form-control" name="cnf_pass"
                                       type="password"
                                       v-model="auth.conf_password"/>
                                <span class="invalid-feedback" v-if="errors.cnf_pass">{{ errors.cnf_pass }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right btn-toolbar">
                            <button class="btn btn-success mr-2" type="submit">{{$t('page.coop.button.submit')}}</button>
                            <button class="btn btn-light" type="reset">{{$t('page.coop.button.reset')}}</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import {mapGetters} from "vuex";

    export default {
        name: "ChangePassword",
        components: {Form, Field},
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                auth: {
                    email: null,
                    old_password: null,
                    new_password: null,
                    conf_password: null
                }
            }
        },
        methods: {
            async submit() {
                try {
                    this.auth.email = this.currentUser.email;
                    await this.$api.post(`auth/change_password`, this.auth);
                    this.$toast.success('Password successfully changed!.');
                    await this.$router.push('/');
                } catch (e) {
                    if (e.response.data && e.response.data.error === 'CustomError: Invalid Password') {
                        this.$toast.error('The old password does not match.');
                    }
                }
            },
            validatePass(value) {
                if (!value) {
                    return 'This field is required'
                } else if (value.length < 8) {
                    return 'It should not be less than 8 characters long'
                }
                return true;
            },
            validateCnfPass(value) {
                if (value != this.auth.new_password) {
                    return 'Password does not match'
                }
                return true;
            },
            reset() {
                this.auth = {
                    email: null,
                    old_password: null,
                    new_password: null,
                    conf_password: null
                }
            }
        }
    }
</script>

<style scoped>

</style>