import {createI18n} from "vue3-i18n";

import en from "./languages/en.json";
import en1 from "./languages/en1.json";
import bn from "./languages/bn.json";

const messages = {
    en: en,
    en1: en1,
    bn: bn,
};

const i18n = createI18n({
    locale: localStorage.getItem("locale") || process.env.VUE_APP_LOCALE || "en",
    messages: messages,
});

export default i18n;