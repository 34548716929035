<template>
    <div class="row row-sm">
        <div class="col-12 d-print-none">
            <div class="card card-dashboard-twelve">
                <div class="card-body">
                    <div class="form-row mb-1">
                        <div class="col-lg-3 col-md-4 mb-1">
                            <select @change="getBlocks(range)" class="form-control" v-model="range">
                                <option :value="null">{{$t('page.coop.range.placeholder')}}</option>
                                <option :value="range.range_id" v-for="range in ranges">{{range.name}}</option>
                            </select>
                        </div><!-- col -->
                        <div class="col-lg-3 col-md-4 mb-1">
                            <select class="form-control" v-model="municipality">
                                <option :value="null">{{$t('page.coop.municipality.placeholder')}}</option>
                                <option :value="block.name" v-for="block in blocks">{{block.name}}</option>
                            </select>
                        </div><!-- col -->
                        <div class="col-lg-1 col-md-4 mb-1">
                            <button @click="getCoops" class="btn btn-info w-100" type="button">
                                {{$t('page.coop.button.search')}}
                            </button>
                        </div><!-- col -->
                        <div class="col-lg-1 col-md-4 mb-1">
                            <button @click="reset" class="btn btn-secondary w-100" type="button">
                                {{$t('page.coop.button.reset')}}
                            </button>
                        </div><!-- col -->
                        <div class="col-lg-1 col-md-4 mb-1 offset-3">
                            <a href="javascript:window.print()" class="btn btn-indigo w-100" type="button">
                                {{$t('page.coop.button.export')}}
                            </a>
                        </div><!-- col -->
                    </div>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div class="table-responsive" id="printMe">
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">Particulars</th>
                    <th scope="col">Accumulated Result</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(field, index1) in report_template">
                    <tr>
                        <template v-if="field.type !== 'json'">
                            <td>{{field.label}}</td>
                            <td>{{report[field.id]}}</td>
                        </template>
                        <template v-else>
                            <td class="font-weight-bold">{{field.label}}</td>
                            <td></td>
                        </template>
                    </tr>
                    <template v-for="(sub_field, index2) in field.fields">
                        <tr>
                            <template v-if="sub_field.type !== 'json'">
                                <td class="custom-pl-1">{{sub_field.label}}</td>
                                <td>{{report[field.id][sub_field.id]}}</td>
                            </template>
                            <template v-else>
                                <td class="font-weight-bold custom-pl-1">{{sub_field.label}}</td>
                                <td></td>
                            </template>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        label: "ViewReport",
        data() {
            return {
                coop_count: 0,
                coops: [],
                blocks: [],
                ranges: [],
                range: null,
                municipality: null,
                report: {
                    "general": {
                        "number_of_members": 0,
                        "total_number_of_employees": 0,
                        "employees_having_professional_degree_diploma": 0,
                        "employees_having_graduation_degree": 0,
                        "employees_completed_certificate_training_course": 0,
                        "employees_having_madhyamik_equivalent_educational_qualification": 0
                    },
                    "working_capital": {
                        "owned_capital": 0,
                        "borrowed_fund": 0,
                        "total_working_capital": 0
                    },
                    "marketing_earnings": {
                        "total_operating_expenses": 0,
                        "total_operating_income": 0
                    },
                    "consumer_earnings": {
                        "total_operating_expenses": 0,
                        "total_operating_income": 0
                    },
                    "processing_earnings": {
                        "total_operating_expenses": 0,
                        "total_operating_income": 0
                    },
                    "liabilities": {
                        "total_liabilities": 0
                    },
                    "assets": {
                        "total_assets": 0
                    },
                    "depositor_profile": {
                        "casa_deposit": 0,
                        "term_deposit": 0,
                        "total_deposit": 0,
                        "no_of_casa_depositor": 0,
                        "no_of_term_depositor": 0,
                        "total_depositor": 0

                    },
                    "loanee_profile": {
                        "total_loan_principal_outstanding": 0,
                        "interest_on_loan_outstanding": 0,
                        "total_no_of_loanee_members": 0,
                        "performing_asset": 0,
                        "non_performing_asset": 0
                    },
                    "earning": {
                        "total_income": 0,
                        "total_expenses": 0
                    }

                },
                report_template: [
                    {
                        index: 1,
                        label: 'General',
                        id: 'general',
                        "type": "json",
                        "fields": [
                            {
                                "id": "number_of_members",
                                "label": "Number of members",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "total_number_of_employees",
                                "label": "Total Number of Employees",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "employees_having_professional_degree_diploma",
                                "label": "Number of employees having Professional Degree / Diploma",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "employees_having_graduation_degree",
                                "label": "Number of employees having Graduation Degree",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "employees_completed_certificate_training_course",
                                "label": "Number of employees completed certificate training course",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "employees_having_madhyamik_equivalent_educational_qualification",
                                "label": "Number of employees having Madhyamik equivalent educational qualification",
                                "type": "number",
                                "readonly": true
                            }
                        ]
                    },

                    {
                        index: 2,
                        label: 'Working Capital',
                        id: 'working_capital',
                        "type": "json",
                        "fields": [
                            {
                                "id": "owned_capital",
                                "label": "Owned Capital",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "borrowed_fund",
                                "label": "Borrowed Fund",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "total_working_capital",
                                "label": "Total Working Capital",
                                "type": "number",
                                "readonly": true
                            }
                        ]
                    },
                    // {
                    //     index: 3,
                    //     label: 'Marketing Earnings',
                    //     id: 'marketing_earnings',
                    //     "type": "json",
                    //     "fields": [
                    //         {
                    //             "id": "total_operating_income",
                    //             "label": "Total Operating Income",
                    //             "type": "number",
                    //             "readonly": true
                    //         },
                    //         {
                    //             "id": "total_operating_expenses",
                    //             "label": "Total Operating Expenses",
                    //             "type": "number",
                    //             "readonly": true
                    //         }
                    //     ]
                    // },
                    // {
                    //     index: 4,
                    //     label: 'Consumer Earnings',
                    //     id: 'consumer_earnings',
                    //     "type": "json",
                    //     "fields": [
                    //         {
                    //             "id": "total_operating_income",
                    //             "label": "Total Operating Income",
                    //             "type": "number",
                    //             "readonly": true
                    //         },
                    //         {
                    //             "id": "total_operating_expenses",
                    //             "label": "Total Operating Expenses",
                    //             "type": "number",
                    //             "readonly": true
                    //         }
                    //     ]
                    // },
                    // {
                    //     index: 5,
                    //     label: 'Processing Earnings',
                    //     id: 'processing_earnings',
                    //     "type": "json",
                    //     "fields": [
                    //         {
                    //             "id": "total_operating_income",
                    //             "label": "Total Operating Income",
                    //             "type": "number",
                    //             "readonly": true
                    //         },
                    //         {
                    //             "id": "total_operating_expenses",
                    //             "label": "Total Operating Expenses",
                    //             "type": "number",
                    //             "readonly": true
                    //         }
                    //     ]
                    // },
                    {
                        index: 6,
                        label: 'Liabilities',
                        id: 'liabilities',
                        "type": "json",
                        "fields": [
                            {
                                "id": "total_liabilities",
                                "label": "Total Liabilities",
                                "type": "number",
                                "readonly": true
                            }
                        ]
                    },
                    {
                        index: 7,
                        label: 'Assets',
                        id: 'assets',
                        "type": "json",
                        "fields": [
                            {
                                "id": "total_assets",
                                "label": "Total Assets",
                                "type": "number",
                                "readonly": true
                            }
                        ]
                    },
                    {
                        index: 8,
                        label: 'Depositor profile',
                        id: 'depositor_profile',
                        "type": "json",
                        "fields": [
                            {
                                "id": "casa_deposit",
                                "label": "CASA Deposit",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "term_deposit",
                                "label": "Term Deposit",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "total_deposit",
                                "label": "Total Deposit",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "no_of_casa_depositor",
                                "label": "No of CASA Depositor",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "no_of_term_depositor",
                                "label": "No of Term Depositor",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "total_depositor",
                                "label": "Total Depositor",
                                "type": "number",
                                "readonly": true
                            }
                        ]
                    },
                    {
                        index: 9,
                        label: 'Loanee profile',
                        id: 'loanee_profile',
                        "type": "json",
                        "fields": [
                            {
                                "id": "total_loan_principal_outstanding",
                                "label": "Total Loan (Principal outstanding) matured / maturing within one year",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "interest_on_loan_outstanding",
                                "label": "Interest on Loan outstanding",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "total_no_of_loanee_members",
                                "label": "Total No. of Loanee members",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "performing_asset",
                                "label": "Performing Asset",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "non_performing_asset",
                                "label": "Non-Performing Asset",
                                "type": "number",
                                "readonly": true
                            }
                        ]
                    },
                    {
                        index: 10,
                        label: 'Earning',
                        id: 'earning',
                        "type": "json",
                        "fields": [
                            {
                                "id": "total_income",
                                "label": "Total Income",
                                "type": "number",
                                "readonly": true
                            },
                            {
                                "id": "total_expenses",
                                "label": "Total Expenses",
                                "type": "number",
                                "readonly": true
                            }


                        ]
                    }
                ]
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getRanges();
                await this.getCoopsCount();
                await this.getCoops();
            },
            async getCoopsCount() {
                try {
                    let {data} = await this.$api('coops', {
                        params: {
                            page: 0,
                            per_page: 1
                        }
                    });
                    this.coop_count = data.total;
                } catch (e) {

                }
            },
            async getRanges() {
                try {
                    let {data} = await this.$api('coops/ranges');
                    this.ranges = data;
                } catch (e) {

                }
            },
            async getBlocks(range_id) {
                if (range_id) {
                    this.blocks = [];
                    try {
                        let {data} = await this.$api.get(`/coops/ranges/${range_id}/blocks`);
                        this.blocks = data;
                    } catch (e) {

                    }
                }
            },
            async getCoops() {
                try {
                    let params = {
                        page: 0,
                        per_page: this.coop_count
                    }
                    if (this.range) {
                        params.range = this.ranges.find(r => r.range_id === this.range).name;
                    }
                    if (this.municipality) {
                        params.municipality = this.municipality;
                    }
                    let {data} = await this.$api('coops', {params: params});
                    let statuses = ['AwaitingApproval', 'Revision', 'Approved'];
                    this.coops = data.coops.filter(c=> statuses.includes(c.status));
                    this.getReportData();
                } catch (e) {

                }
            },
            reset() {
                this.range = null;
                this.municipality = null;
                this.getCoops();
            },
            getReportData() {
                this.report = {
                    "general": {
                        "number_of_members": 0,
                        "total_number_of_employees": 0,
                        "employees_having_professional_degree_diploma": 0,
                        "employees_having_graduation_degree": 0,
                        "employees_completed_certificate_training_course": 0,
                        "employees_having_madhyamik_equivalent_educational_qualification": 0
                    },
                    "working_capital": {
                        "owned_capital": 0,
                        "borrowed_fund": 0,
                        "total_working_capital": 0
                    },
                    "marketing_earnings": {
                        "total_operating_expenses": 0,
                        "total_operating_income": 0
                    },
                    "consumer_earnings": {
                        "total_operating_expenses": 0,
                        "total_operating_income": 0
                    },
                    "processing_earnings": {
                        "total_operating_expenses": 0,
                        "total_operating_income": 0
                    },
                    "liabilities": {
                        "total_liabilities": 0
                    },
                    "assets": {
                        "total_assets": 0
                    },
                    "depositor_profile": {
                        "casa_deposit": 0,
                        "term_deposit": 0,
                        "total_deposit": 0,
                        "no_of_casa_depositor": 0,
                        "no_of_term_depositor": 0,
                        "total_depositor": 0

                    },
                    "loanee_profile": {
                        "total_loan_principal_outstanding": 0,
                        "interest_on_loan_outstanding": 0,
                        "total_no_of_loanee_members": 0,
                        "performing_asset": 0,
                        "non_performing_asset": 0
                    },
                    "earning": {
                        "total_income": 0,
                        "total_expenses": 0
                    }

                };

                this.coops.forEach(coop => {
                    //GENERAL
                    this.report.general.number_of_members += Number(coop.members.individual_voters.total) + Number(coop.members.nominal) + Number(coop.members.self_help_groups) + Number(coop.members.coop_societies);
                    this.report.general.total_number_of_employees += Number(coop.employees.total);
                    this.report.general.employees_having_professional_degree_diploma += Number(coop.no_of_employee_with_diploma);
                    this.report.general.employees_having_graduation_degree += Number(coop.no_of_employee_with_graduate);
                    this.report.general.employees_completed_certificate_training_course += Number(coop.no_of_employee_with_certificate_training);
                    this.report.general.employees_having_madhyamik_equivalent_educational_qualification += Number(coop.no_of_employee_with_madhyamik_equivalent);

                    // WORKING CAPITAL
                    this.report.working_capital.owned_capital += Number(coop.working_capital.working_capital_as_per_definition_69.owned_capital);

                    this.report.working_capital.borrowed_fund += Number(coop.working_capital.working_capital_as_per_definition_69.borrowed_fund);

                    this.report.working_capital.total_working_capital += Number(coop.working_capital.working_capital_as_per_definition_69.working_capital_owned_capital_borrowed_fund);
                    // MARKETING EARNINGS
                    this.report.marketing_earnings.total_operating_income += Number(coop.marketing_earnings.total_operating_income);
                    this.report.marketing_earnings.total_operating_expenses += Number(coop.marketing_earnings.total_operating_expenses);
                    // CONSUMER EARNINGS
                    this.report.consumer_earnings.total_operating_income += Number(coop.consumer_earnings.total_operating_income);
                    this.report.consumer_earnings.total_operating_expenses += Number(coop.consumer_earnings.total_operating_expenses);
                    // PROCESSING EARNINGS
                    this.report.processing_earnings.total_operating_income += Number(coop.processing_earnings.total_operating_income);
                    this.report.processing_earnings.total_operating_expenses += Number(coop.processing_earnings.total_operating_expenses);
                    // LIABILITIES
                    this.report.liabilities.total_liabilities += Number(coop.liabilities.total_liabilities);
                    // ASSETS
                    this.report.assets.total_assets += Number(coop.assets.total_assets);
                    // DEPOSITOR PROFILE
                    this.report.depositor_profile.casa_deposit += Number(coop.depositor_profile.casa_deposit);
                    this.report.depositor_profile.term_deposit += Number(coop.depositor_profile.term_deposit);
                    this.report.depositor_profile.total_deposit += Number(coop.depositor_profile.deposit.total_deposit);
                    this.report.depositor_profile.no_of_casa_depositor += Number(coop.depositor_profile.casa_depositor);
                    this.report.depositor_profile.no_of_term_depositor += Number(coop.depositor_profile.term_depositor);
                    this.report.depositor_profile.total_depositor += Number(coop.depositor_profile.total_depositor);
                    // LOANEE PROFILE
                    this.report.loanee_profile.total_loan_principal_outstanding += Number(coop.loanee_profile.loan_principal_outstanding.total_loan_principal_outstanding);
                    this.report.loanee_profile.interest_on_loan_outstanding += Number(coop.loanee_profile.interest_on_loan_outstanding.total_interest_on_loan_outstanding);
                    this.report.loanee_profile.total_no_of_loanee_members += Number(coop.loanee_profile.no_of_loanee_members.total_no_of_loanee_members);
                    this.report.loanee_profile.performing_asset += Number(coop.loanee_profile.classification_of_asset.performing_asset.total_performing_asset);
                    this.report.loanee_profile.non_performing_asset += Number(coop.loanee_profile.classification_of_asset.total_non_performing_asset);
                    //EARNING
                    this.report.earning.total_income += Number(coop.earnings.total_income);
                    this.report.earning.total_expenses += Number(coop.earnings.total_operating_expenses) + Number(coop.earnings.write_off.total_write_off) + Number(coop.earnings.provision_against_risks.total_provision_against_risks) + Number(coop.earnings.provisions_for_taxes);
                })
            },
            printReport() {
                this.$htmlToPaper('printMe');
            },
        }
    }
</script>

<style scoped>

    .custom-pl-1 {
        padding-left: 2rem !important;
    }

    .custom-pl-2 {
        padding-left: 4rem !important;
    }
</style>
