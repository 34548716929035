<template>
    <div class="row row-sm">
        <div class="col-12">
            <!--            <Form :ref="name" v-slot="{errors}">-->
            <div class="card-header border-bottom">
                <div class="row">
                    <div class="col-6 text-left">
                        <h5 class="card-title">{{ name }}</h5>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <template :key="index1" v-for="(field, index1) in fields">
                    <div class="row align-items-center mg-b-10" v-if="field.type !== 'json'">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <label class="form-label font-weight-bold">{{ field.label }}</label>
                            <small class="form-text text-muted" id="helper-text" v-if="field.description">{{ field.description }}</small>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <Field :name="index1+'_'+id+'_'+field.db"
                                   :placeholder="field.readonly ? field.label.slice(field.label.indexOf(' ')) : 'Enter '+field.label.slice(field.label.indexOf(' '))"
                                   :readonly="field.readonly"
                                   :class="{ 'is-invalid' : form_errors[index1+'_'+id+'_'+field.db] }"
                                   :rules="field.is_percentage ? percentageFieldValidation: ''"
                                   :type="field.type"
                                   @input="calculate(field.db)" as="input" class="form-control"
                                   v-if="field.type !== 'bool'" v-model="coop[id][field.db]"/>
                            <span class="invalid-feedback" v-if="form_errors[index1+'_'+id+'_'+field.db]">{{ form_errors[index1 + '_' + id + '_' + field.db] }}</span>
                            <div class="form-check form-check-inline" v-if="field.type === 'bool'">
                                <input :name="'required'+id+'_'+field.db" :value="true" class="form-check-input" type="radio"
                                       v-model="coop[id][field.db]">
                                <label class="form-check-label" for="Yes">Yes</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="field.type === 'bool'">
                                <input :name="'required'+id+'_'+field.db" :value="false" class="form-check-input" type="radio"
                                       v-model="coop[id][field.db]">
                                <label class="form-check-label" for="No">No</label>
                            </div>
                        </div>
                    </div>
                    <h6 class="text-divider text-left" v-else><span class="pl-0">{{ field.label }}</span></h6>
                    <template :key="index2" v-for="(sub_field, index2) in field.fields">
                        <div class="row align-items-center mg-b-10" v-if="sub_field.type !== 'json'">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <label class="form-label">{{ sub_field.label }}</label>
                                <small class="form-text text-muted" id="sub-helper-text" v-if="sub_field.description">{{ sub_field.description }}</small>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field :name="index1+'_'+index2+'_'+id+'_'+sub_field.db"
                                       :placeholder="sub_field.readonly ? sub_field.label.slice(sub_field.label.indexOf(' ')) : 'Enter '+sub_field.label.slice(sub_field.label.indexOf(' '))"
                                       :readonly="sub_field.readonly"
                                       :class="{ 'is-invalid' : form_errors[index1+'_'+index2+'_'+id+'_'+sub_field.db] }"
                                       :rules="sub_field.is_percentage ? percentageFieldValidation: ''"
                                       :type="sub_field.type"
                                       @input="calculate(sub_field.db)" as="input" class="form-control"
                                       v-if="sub_field.type !== 'bool'" v-model="coop[id][field.db][sub_field.db]"/>
                                <span class="invalid-feedback"
                                      v-if="form_errors[index1+'_'+index2+'_'+id+'_'+sub_field.db]">{{ form_errors[index1 + '_' + index2 + '_' + id + '_' + sub_field.db] }}</span>
                                <div class="form-check form-check-inline" v-if="sub_field.type === 'bool'">
                                    <input :name="index1+'_'+index2+'_'+id+'_'+sub_field.db" :value="'Yes'"
                                           class="form-check-input" type="radio"
                                           v-model="coop[id][field.db][sub_field.db]">
                                    <label class="form-check-label" for="Yes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline" v-if="sub_field.type === 'bool'">
                                    <input :name="index1+'_'+index2+'_'+id+'_'+sub_field.db" :value="'No'"
                                           class="form-check-input" type="radio"
                                           v-model="coop[id][field.db][sub_field.db]">
                                    <label class="form-check-label" for="No">No</label>
                                </div>
                            </div>
                        </div>
                        <h6 class="text-divider text-left" v-else><span class="pl-0">{{ sub_field.label }}</span></h6>
                        <template :key="index3" v-for="(sub_sub_field, index3) in sub_field.fields">
                            <div class="row align-items-center mg-b-10" v-if="sub_sub_field.type !== 'json'">
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <label class="form-label">{{ sub_sub_field.label }}</label>
                                    <small class="form-text text-muted" id="sub-sub-helper-text"
                                           v-if="sub_sub_field.description">{{ sub_sub_field.description }}</small>
                                </div>
                                <div class="col-sm-12 col-md-8 col-lg-6">
                                    <Field :name="index1+'_'+index2+'_'+id+'_'+sub_sub_field.db"
                                           :placeholder="sub_sub_field.readonly ? sub_sub_field.label.slice(sub_sub_field.label.indexOf(' ')) : 'Enter '+sub_sub_field.label.slice(sub_sub_field.label.indexOf(' '))"
                                           :readonly="sub_sub_field.readonly"
                                           :class="{ 'is-invalid' : form_errors[index1+'_'+index2+'_'+id+'_'+sub_field.db] }"
                                           :rules="sub_sub_field.is_percentage ? percentageFieldValidation: ''"
                                           :type="sub_sub_field.type"
                                           @input="calculate(sub_sub_field.db)" as="input" class="form-control"
                                           v-if="sub_sub_field.type !== 'bool'"
                                           v-model="coop[id][field.db][sub_field.db][sub_sub_field.db]"/>
                                    <span class="invalid-feedback"
                                          v-if="form_errors[index1+'_'+index2+'_'+id+'_'+sub_sub_field.db]">{{ form_errors[index1 + '_' + index2 + '_' + id + '_' + sub_sub_field.db] }}</span>
                                    <div class="form-check form-check-inline" v-if="sub_sub_field.type === 'bool'">
                                        <input :name="index1+'_'+index2+'_'+id+'_'+sub_sub_field.db" :value="'Yes'"
                                               class="form-check-input" type="radio"
                                               v-model="coop[id][field.db][sub_field.db][sub_sub_field.db]">
                                        <label class="form-check-label" for="Yes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline" v-if="sub_sub_field.type === 'bool'">
                                        <input :name="index1+'_'+index2+'_'+id+'_'+sub_sub_field.db" :value="'No'"
                                               class="form-check-input" type="radio"
                                               v-model="coop[id][field.db][sub_field.db][sub_sub_field.db]">
                                        <label class="form-check-label" for="No">No</label>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {Form, Field} from "vee-validate";

export default {
    name: "AddTabContent",
    props: ["name", "id", "fields", "coop", "form_errors"],
    components: {Form, Field},
    data() {
        return {
            calculated_fields: [
                'owned_capital',
                'borrowed_fund',
                'working_capital_owned_capital_borrowed_fund'
            ]
        }
    },
    methods: {
        percentageFieldValidation(value) {
            value = Number(value);
            if (value > 100) {
                return 'This field should be less than or equal to 100'
            }
            return true;
        },
        calculate() {
            if (this.id === 'marketing_earnings') {
                // CALCULATION OF TOTAL OPERATING INCOME
                this.coop.marketing_earnings.sales_retail.total_sales_retail = Number(this.coop.marketing_earnings.sales_retail.fertiliser) +
                        Number(this.coop.marketing_earnings.sales_retail.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.sales_retail.others) +
                        Number(this.coop.marketing_earnings.sales_retail.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.sales_retail.seeds);
                this.coop.marketing_earnings.sales_distributorship.total_sales_distributorship = Number(this.coop.marketing_earnings.sales_distributorship.fertiliser) +
                        Number(this.coop.marketing_earnings.sales_distributorship.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.sales_distributorship.others) +
                        Number(this.coop.marketing_earnings.sales_distributorship.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.sales_distributorship.seeds);
                this.coop.marketing_earnings.sales_government_supply.total_sales_government_supply = Number(this.coop.marketing_earnings.sales_government_supply.fertiliser) +
                        Number(this.coop.marketing_earnings.sales_government_supply.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.sales_government_supply.others) +
                        Number(this.coop.marketing_earnings.sales_government_supply.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.sales_government_supply.seeds);
                this.coop.marketing_earnings.stock_transfer_return.total_stock_transfer_return = Number(this.coop.marketing_earnings.stock_transfer_return.fertiliser) +
                        Number(this.coop.marketing_earnings.stock_transfer_return.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.stock_transfer_return.others) +
                        Number(this.coop.marketing_earnings.stock_transfer_return.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.stock_transfer_return.seeds);
                this.coop.marketing_earnings.closing_Stock.total_closing_Stock = Number(this.coop.marketing_earnings.closing_Stock.fertiliser) +
                        Number(this.coop.marketing_earnings.closing_Stock.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.closing_Stock.others) +
                        Number(this.coop.marketing_earnings.closing_Stock.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.closing_Stock.seeds);
                this.coop.marketing_earnings.total_operating_income = Number(this.coop.marketing_earnings.sales_retail.total_sales_retail) +
                        Number(this.coop.marketing_earnings.sales_distributorship.total_sales_distributorship) +
                        Number(this.coop.marketing_earnings.sales_government_supply.total_sales_government_supply) +
                        Number(this.coop.marketing_earnings.stock_transfer_return.total_stock_transfer_return) +
                        Number(this.coop.marketing_earnings.closing_Stock.total_closing_Stock);

                // TOTAL OPERATING EXPENSES
                this.coop.marketing_earnings.opening_stock.total_opening_stock = Number(this.coop.marketing_earnings.opening_stock.fertiliser) +
                        Number(this.coop.marketing_earnings.opening_stock.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.opening_stock.others) +
                        Number(this.coop.marketing_earnings.opening_stock.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.opening_stock.seeds);
                this.coop.marketing_earnings.purchase.total_purchase = Number(this.coop.marketing_earnings.purchase.fertiliser) +
                        Number(this.coop.marketing_earnings.purchase.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.purchase.others) +
                        Number(this.coop.marketing_earnings.purchase.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.purchase.seeds);
                this.coop.marketing_earnings.stock_transfer.total_stock_transfer = Number(this.coop.marketing_earnings.stock_transfer.fertiliser) +
                        Number(this.coop.marketing_earnings.stock_transfer.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.stock_transfer.others) +
                        Number(this.coop.marketing_earnings.stock_transfer.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.stock_transfer.seeds);
                this.coop.marketing_earnings.sale_return.total_sale_return = Number(this.coop.marketing_earnings.sale_return.fertiliser) +
                        Number(this.coop.marketing_earnings.sale_return.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.sale_return.others) +
                        Number(this.coop.marketing_earnings.sale_return.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.sale_return.seeds);
                this.coop.marketing_earnings.carriage_inward.total_carriage_inward = Number(this.coop.marketing_earnings.carriage_inward.fertiliser) +
                        Number(this.coop.marketing_earnings.carriage_inward.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.carriage_inward.others) +
                        Number(this.coop.marketing_earnings.carriage_inward.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.carriage_inward.seeds);
                this.coop.marketing_earnings.loading_unloading_charges.total_loading_unloading_charges = Number(this.coop.marketing_earnings.loading_unloading_charges.fertiliser) +
                        Number(this.coop.marketing_earnings.loading_unloading_charges.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.loading_unloading_charges.others) +
                        Number(this.coop.marketing_earnings.loading_unloading_charges.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.loading_unloading_charges.seeds);
                this.coop.marketing_earnings.commision_paid.total_commision_paid = Number(this.coop.marketing_earnings.commision_paid.fertiliser) +
                        Number(this.coop.marketing_earnings.commision_paid.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.commision_paid.others) +
                        Number(this.coop.marketing_earnings.commision_paid.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.commision_paid.seeds);
                this.coop.marketing_earnings.other_trade_charges.total_other_trade_charges = Number(this.coop.marketing_earnings.other_trade_charges.fertiliser) +
                        Number(this.coop.marketing_earnings.other_trade_charges.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.other_trade_charges.others) +
                        Number(this.coop.marketing_earnings.other_trade_charges.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.other_trade_charges.seeds);
                this.coop.marketing_earnings.total_operating_expenses = Number(this.coop.marketing_earnings.opening_stock.total_opening_stock) +
                        Number(this.coop.marketing_earnings.purchase.total_purchase) +
                        Number(this.coop.marketing_earnings.stock_transfer.total_stock_transfer) +
                        Number(this.coop.marketing_earnings.sale_return.total_sale_return) +
                        Number(this.coop.marketing_earnings.carriage_inward.total_carriage_inward) +
                        Number(this.coop.marketing_earnings.loading_unloading_charges.total_loading_unloading_charges) +
                        Number(this.coop.marketing_earnings.commision_paid.total_commision_paid) +
                        Number(this.coop.marketing_earnings.other_trade_charges.total_other_trade_charges);

                this.coop.marketing_earnings.gross_profit_loss.fertiliser = Number(this.coop.marketing_earnings.sales_retail.fertiliser) + Number(this.coop.marketing_earnings.stock_transfer_return.fertiliser) +
                        Number(this.coop.marketing_earnings.closing_Stock.fertiliser) + Number(this.coop.marketing_earnings.sales_distributorship.fertiliser) +
                        Number(this.coop.marketing_earnings.sales_government_supply.fertiliser) - Number(this.coop.marketing_earnings.opening_stock.fertiliser) -
                        Number(this.coop.marketing_earnings.stock_transfer.fertiliser) - Number(this.coop.marketing_earnings.sale_return.fertiliser) - Number(this.coop.marketing_earnings.carriage_inward.fertiliser) -
                        Number(this.coop.marketing_earnings.loading_unloading_charges.fertiliser) - Number(this.coop.marketing_earnings.commision_paid.fertiliser) -
                        Number(this.coop.marketing_earnings.other_trade_charges.fertiliser) - Number(this.coop.marketing_earnings.purchase.fertiliser);

                this.coop.marketing_earnings.gross_profit_loss.plant_protection_chemicals = Number(this.coop.marketing_earnings.sales_retail.plant_protection_chemicals) + Number(this.coop.marketing_earnings.stock_transfer_return.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.closing_Stock.plant_protection_chemicals) + Number(this.coop.marketing_earnings.sales_distributorship.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.sales_government_supply.plant_protection_chemicals) - Number(this.coop.marketing_earnings.opening_stock.plant_protection_chemicals) -
                        Number(this.coop.marketing_earnings.stock_transfer.plant_protection_chemicals) - Number(this.coop.marketing_earnings.sale_return.plant_protection_chemicals) - Number(this.coop.marketing_earnings.carriage_inward.plant_protection_chemicals) -
                        Number(this.coop.marketing_earnings.loading_unloading_charges.plant_protection_chemicals) - Number(this.coop.marketing_earnings.commision_paid.plant_protection_chemicals) -
                        Number(this.coop.marketing_earnings.other_trade_charges.plant_protection_chemicals) - Number(this.coop.marketing_earnings.purchase.plant_protection_chemicals);

                this.coop.marketing_earnings.gross_profit_loss.insecticides_and_pesticides = Number(this.coop.marketing_earnings.sales_retail.insecticides_and_pesticides) + Number(this.coop.marketing_earnings.stock_transfer_return.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.closing_Stock.insecticides_and_pesticides) + Number(this.coop.marketing_earnings.sales_distributorship.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.sales_government_supply.insecticides_and_pesticides) - Number(this.coop.marketing_earnings.opening_stock.insecticides_and_pesticides) -
                        Number(this.coop.marketing_earnings.stock_transfer.insecticides_and_pesticides) - Number(this.coop.marketing_earnings.sale_return.insecticides_and_pesticides) - Number(this.coop.marketing_earnings.carriage_inward.insecticides_and_pesticides) -
                        Number(this.coop.marketing_earnings.loading_unloading_charges.insecticides_and_pesticides) - Number(this.coop.marketing_earnings.commision_paid.insecticides_and_pesticides) -
                        Number(this.coop.marketing_earnings.other_trade_charges.insecticides_and_pesticides) - Number(this.coop.marketing_earnings.purchase.insecticides_and_pesticides);

                this.coop.marketing_earnings.gross_profit_loss.seeds = Number(this.coop.marketing_earnings.sales_retail.seeds) + Number(this.coop.marketing_earnings.stock_transfer_return.seeds) +
                        Number(this.coop.marketing_earnings.closing_Stock.seeds) + Number(this.coop.marketing_earnings.sales_distributorship.seeds) +
                        Number(this.coop.marketing_earnings.sales_government_supply.seeds) - Number(this.coop.marketing_earnings.opening_stock.seeds) -
                        Number(this.coop.marketing_earnings.stock_transfer.seeds) - Number(this.coop.marketing_earnings.sale_return.seeds) - Number(this.coop.marketing_earnings.carriage_inward.seeds) -
                        Number(this.coop.marketing_earnings.loading_unloading_charges.seeds) - Number(this.coop.marketing_earnings.commision_paid.seeds) -
                        Number(this.coop.marketing_earnings.other_trade_charges.seeds) - Number(this.coop.marketing_earnings.purchase.seeds);

                this.coop.marketing_earnings.gross_profit_loss.others = Number(this.coop.marketing_earnings.sales_retail.others) + Number(this.coop.marketing_earnings.stock_transfer_return.others) +
                        Number(this.coop.marketing_earnings.closing_Stock.others) + Number(this.coop.marketing_earnings.sales_distributorship.others) +
                        Number(this.coop.marketing_earnings.sales_government_supply.others) - Number(this.coop.marketing_earnings.opening_stock.others) -
                        Number(this.coop.marketing_earnings.stock_transfer.others) - Number(this.coop.marketing_earnings.sale_return.others) - Number(this.coop.marketing_earnings.carriage_inward.others) -
                        Number(this.coop.marketing_earnings.loading_unloading_charges.others) - Number(this.coop.marketing_earnings.commision_paid.others) -
                        Number(this.coop.marketing_earnings.other_trade_charges.others) - Number(this.coop.marketing_earnings.purchase.others);
                // CALCULATION OF Gross (+)Profit / (-)Loss
                this.coop.marketing_earnings.gross_profit_loss.total_gross_profit_loss = Number(this.coop.marketing_earnings.gross_profit_loss.fertiliser) +
                        Number(this.coop.marketing_earnings.gross_profit_loss.insecticides_and_pesticides) +
                        Number(this.coop.marketing_earnings.gross_profit_loss.others) +
                        Number(this.coop.marketing_earnings.gross_profit_loss.plant_protection_chemicals) +
                        Number(this.coop.marketing_earnings.gross_profit_loss.seeds);

                this.coop.earnings.operating_income_trading_account = Number(this.coop.marketing_earnings.total_operating_income)
                                + Number(this.coop.processing_earnings.total_operating_income)
                                + Number(this.coop.consumer_earnings.total_operating_income);

                this.coop.earnings.operating_expenses_from_trading_account = this.coop.marketing_earnings.total_operating_expenses + this.coop.consumer_earnings.total_operating_expenses +
                        this.coop.processing_earnings.total_operating_expenses;

                this.coop.earnings.total_operating_expenses = Number(this.coop.earnings.interest_paid.total_interest_paid) +
                        Number(this.coop.earnings.operating_expenses.total_operating_expenses) +
                        Number(this.coop.earnings.other_operating_expenses.total_other_operating_expenses) +
                        Number(this.coop.earnings.operating_expenses_from_trading_account);

                this.coop.earnings.operating_profit_loss = Number(this.coop.earnings.total_income) - Number(this.coop.earnings.total_operating_expenses);
            }

            if (this.id === 'consumer_earnings') {
                // CALCULATION OF TOTAL OPERATING INCOME
                this.coop.consumer_earnings.sales_retail.total_sales_retail = Number(this.coop.consumer_earnings.sales_retail.groceries) +
                        Number(this.coop.consumer_earnings.sales_retail.stationaries) +
                        Number(this.coop.consumer_earnings.sales_retail.others) +
                        Number(this.coop.consumer_earnings.sales_retail.textiles_garments) +
                        Number(this.coop.consumer_earnings.sales_retail.vegetable_perishable_foods);
                this.coop.consumer_earnings.sales_distributorship.total_sales_distributorship = Number(this.coop.consumer_earnings.sales_distributorship.groceries) +
                        Number(this.coop.consumer_earnings.sales_distributorship.stationaries) +
                        Number(this.coop.consumer_earnings.sales_distributorship.others) +
                        Number(this.coop.consumer_earnings.sales_distributorship.textiles_garments) +
                        Number(this.coop.consumer_earnings.sales_distributorship.vegetable_perishable_foods);
                this.coop.consumer_earnings.sales_government_supply.total_sales_government_supply = Number(this.coop.consumer_earnings.sales_government_supply.groceries) +
                        Number(this.coop.consumer_earnings.sales_government_supply.stationaries) +
                        Number(this.coop.consumer_earnings.sales_government_supply.others) +
                        Number(this.coop.consumer_earnings.sales_government_supply.textiles_garments) +
                        Number(this.coop.consumer_earnings.sales_government_supply.vegetable_perishable_foods);
                this.coop.consumer_earnings.stock_transfer_return.total_stock_transfer_return = Number(this.coop.consumer_earnings.stock_transfer_return.groceries) +
                        Number(this.coop.consumer_earnings.stock_transfer_return.stationaries) +
                        Number(this.coop.consumer_earnings.stock_transfer_return.others) +
                        Number(this.coop.consumer_earnings.stock_transfer_return.textiles_garments) +
                        Number(this.coop.consumer_earnings.stock_transfer_return.vegetable_perishable_foods);
                this.coop.consumer_earnings.closing_Stock.total_closing_Stock = Number(this.coop.consumer_earnings.closing_Stock.groceries) +
                        Number(this.coop.consumer_earnings.closing_Stock.stationaries) +
                        Number(this.coop.consumer_earnings.closing_Stock.others) +
                        Number(this.coop.consumer_earnings.closing_Stock.textiles_garments) +
                        Number(this.coop.consumer_earnings.closing_Stock.vegetable_perishable_foods);
                this.coop.consumer_earnings.total_operating_income = Number(this.coop.consumer_earnings.sales_retail.total_sales_retail) +
                        Number(this.coop.consumer_earnings.sales_distributorship.total_sales_distributorship) +
                        Number(this.coop.consumer_earnings.sales_government_supply.total_sales_government_supply) +
                        Number(this.coop.consumer_earnings.stock_transfer_return.total_stock_transfer_return) +
                        Number(this.coop.consumer_earnings.closing_Stock.total_closing_Stock);

                // TOTAL OPERATING EXPENSES
                this.coop.consumer_earnings.opening_stock.total_opening_stock = Number(this.coop.consumer_earnings.opening_stock.groceries) +
                        Number(this.coop.consumer_earnings.opening_stock.stationaries) +
                        Number(this.coop.consumer_earnings.opening_stock.others) +
                        Number(this.coop.consumer_earnings.opening_stock.textiles_garments) +
                        Number(this.coop.consumer_earnings.opening_stock.vegetable_perishable_foods);
                this.coop.consumer_earnings.purchases.total_purchases = Number(this.coop.consumer_earnings.purchases.groceries) +
                        Number(this.coop.consumer_earnings.purchases.stationaries) +
                        Number(this.coop.consumer_earnings.purchases.others) +
                        Number(this.coop.consumer_earnings.purchases.textiles_garments) +
                        Number(this.coop.consumer_earnings.purchases.vegetable_perishable_foods);
                this.coop.consumer_earnings.stock_transfer.total_stock_transfer = Number(this.coop.consumer_earnings.stock_transfer.groceries) +
                        Number(this.coop.consumer_earnings.stock_transfer.stationaries) +
                        Number(this.coop.consumer_earnings.stock_transfer.others) +
                        Number(this.coop.consumer_earnings.stock_transfer.textiles_garments) +
                        Number(this.coop.consumer_earnings.stock_transfer.vegetable_perishable_foods);
                this.coop.consumer_earnings.sale_return.total_sale_return = Number(this.coop.consumer_earnings.sale_return.groceries) +
                        Number(this.coop.consumer_earnings.sale_return.stationaries) +
                        Number(this.coop.consumer_earnings.sale_return.others) +
                        Number(this.coop.consumer_earnings.sale_return.textiles_garments) +
                        Number(this.coop.consumer_earnings.sale_return.vegetable_perishable_foods);
                this.coop.consumer_earnings.carriage_inward.total_carriage_inward = Number(this.coop.consumer_earnings.carriage_inward.groceries) +
                        Number(this.coop.consumer_earnings.carriage_inward.stationaries) +
                        Number(this.coop.consumer_earnings.carriage_inward.others) +
                        Number(this.coop.consumer_earnings.carriage_inward.textiles_garments) +
                        Number(this.coop.consumer_earnings.carriage_inward.vegetable_perishable_foods);
                this.coop.consumer_earnings.loading_unloading_charges.total_loading_unloading_charges = Number(this.coop.consumer_earnings.loading_unloading_charges.groceries) +
                        Number(this.coop.consumer_earnings.loading_unloading_charges.stationaries) +
                        Number(this.coop.consumer_earnings.loading_unloading_charges.others) +
                        Number(this.coop.consumer_earnings.loading_unloading_charges.textiles_garments) +
                        Number(this.coop.consumer_earnings.loading_unloading_charges.vegetable_perishable_foods);
                this.coop.consumer_earnings.commision_paid.total_commision_paid = Number(this.coop.consumer_earnings.commision_paid.groceries) +
                        Number(this.coop.consumer_earnings.commision_paid.stationaries) +
                        Number(this.coop.consumer_earnings.commision_paid.others) +
                        Number(this.coop.consumer_earnings.commision_paid.textiles_garments) +
                        Number(this.coop.consumer_earnings.commision_paid.vegetable_perishable_foods);
                this.coop.consumer_earnings.other_trade_charges.total_other_trade_charges = Number(this.coop.consumer_earnings.other_trade_charges.groceries) +
                        Number(this.coop.consumer_earnings.other_trade_charges.stationaries) +
                        Number(this.coop.consumer_earnings.other_trade_charges.others) +
                        Number(this.coop.consumer_earnings.other_trade_charges.textiles_garments) +
                        Number(this.coop.consumer_earnings.other_trade_charges.vegetable_perishable_foods);
                this.coop.consumer_earnings.total_operating_expenses = Number(this.coop.consumer_earnings.opening_stock.total_opening_stock) +
                        Number(this.coop.consumer_earnings.purchases.total_purchases) +
                        Number(this.coop.consumer_earnings.stock_transfer.total_stock_transfer) +
                        Number(this.coop.consumer_earnings.sale_return.total_sale_return) +
                        Number(this.coop.consumer_earnings.carriage_inward.total_carriage_inward) +
                        Number(this.coop.consumer_earnings.loading_unloading_charges.total_loading_unloading_charges) +
                        Number(this.coop.consumer_earnings.commision_paid.total_commision_paid) +
                        Number(this.coop.consumer_earnings.other_trade_charges.total_other_trade_charges);

                this.coop.consumer_earnings.gross_profit_loss.groceries = Number(this.coop.consumer_earnings.sales_retail.groceries) + Number(this.coop.consumer_earnings.stock_transfer_return.groceries) +
                        Number(this.coop.consumer_earnings.closing_Stock.groceries) + Number(this.coop.consumer_earnings.sales_distributorship.groceries) +
                        Number(this.coop.consumer_earnings.sale_return.groceries) - Number(this.coop.consumer_earnings.opening_stock.groceries) - Number(this.coop.consumer_earnings.purchases.groceries) -
                        Number(this.coop.consumer_earnings.stock_transfer.groceries) - Number(this.coop.consumer_earnings.sale_return.groceries) - Number(this.coop.consumer_earnings.carriage_inward.groceries) -
                        Number(this.coop.consumer_earnings.loading_unloading_charges.groceries) - Number(this.coop.consumer_earnings.commision_paid.groceries) - Number(this.coop.consumer_earnings.other_trade_charges.groceries);

                this.coop.consumer_earnings.gross_profit_loss.stationaries = Number(this.coop.consumer_earnings.sales_retail.stationaries) + Number(this.coop.consumer_earnings.stock_transfer_return.stationaries) +
                        Number(this.coop.consumer_earnings.closing_Stock.stationaries) + Number(this.coop.consumer_earnings.sales_distributorship.stationaries) +
                        Number(this.coop.consumer_earnings.sale_return.stationaries) - Number(this.coop.consumer_earnings.opening_stock.stationaries) - Number(this.coop.consumer_earnings.purchases.stationaries) -
                        Number(this.coop.consumer_earnings.stock_transfer.stationaries) - Number(this.coop.consumer_earnings.sale_return.stationaries) - Number(this.coop.consumer_earnings.carriage_inward.stationaries) -
                        Number(this.coop.consumer_earnings.loading_unloading_charges.stationaries) - Number(this.coop.consumer_earnings.commision_paid.stationaries) -
                        Number(this.coop.consumer_earnings.other_trade_charges.stationaries);

                this.coop.consumer_earnings.gross_profit_loss.textiles_garments = Number(this.coop.consumer_earnings.sales_retail.textiles_garments) + Number(this.coop.consumer_earnings.stock_transfer_return.textiles_garments) +
                        Number(this.coop.consumer_earnings.closing_Stock.textiles_garments) + Number(this.coop.consumer_earnings.sales_distributorship.textiles_garments) +
                        Number(this.coop.consumer_earnings.sale_return.textiles_garments) - Number(this.coop.consumer_earnings.opening_stock.textiles_garments) - Number(this.coop.consumer_earnings.purchases.textiles_garments) -
                        Number(this.coop.consumer_earnings.stock_transfer.textiles_garments) - Number(this.coop.consumer_earnings.sale_return.textiles_garments) - Number(this.coop.consumer_earnings.carriage_inward.textiles_garments) -
                        Number(this.coop.consumer_earnings.loading_unloading_charges.textiles_garments) - Number(this.coop.consumer_earnings.commision_paid.textiles_garments) -
                        Number(this.coop.consumer_earnings.other_trade_charges.textiles_garments);

                this.coop.consumer_earnings.gross_profit_loss.vegetable_perishable_foods = Number(this.coop.consumer_earnings.sales_retail.vegetable_perishable_foods) + Number(this.coop.consumer_earnings.stock_transfer_return.vegetable_perishable_foods) +
                        Number(this.coop.consumer_earnings.closing_Stock.vegetable_perishable_foods) + Number(this.coop.consumer_earnings.sales_distributorship.vegetable_perishable_foods) +
                        Number(this.coop.consumer_earnings.sale_return.vegetable_perishable_foods) - Number(this.coop.consumer_earnings.opening_stock.vegetable_perishable_foods) - Number(this.coop.consumer_earnings.purchases.vegetable_perishable_foods) -
                        Number(this.coop.consumer_earnings.stock_transfer.vegetable_perishable_foods) - Number(this.coop.consumer_earnings.sale_return.vegetable_perishable_foods) - Number(this.coop.consumer_earnings.carriage_inward.vegetable_perishable_foods) -
                        Number(this.coop.consumer_earnings.loading_unloading_charges.vegetable_perishable_foods) - Number(this.coop.consumer_earnings.commision_paid.vegetable_perishable_foods) -
                        Number(this.coop.consumer_earnings.other_trade_charges.vegetable_perishable_foods);

                this.coop.consumer_earnings.gross_profit_loss.others = Number(this.coop.consumer_earnings.sales_retail.others) + Number(this.coop.consumer_earnings.stock_transfer_return.others) +
                        Number(this.coop.consumer_earnings.closing_Stock.others) + Number(this.coop.consumer_earnings.sales_distributorship.others) +
                        Number(this.coop.consumer_earnings.sale_return.others) - Number(this.coop.consumer_earnings.opening_stock.others) - Number(this.coop.consumer_earnings.purchases.others) -
                        Number(this.coop.consumer_earnings.stock_transfer.others) - Number(this.coop.consumer_earnings.sale_return.others) - Number(this.coop.consumer_earnings.carriage_inward.others) -
                        Number(this.coop.consumer_earnings.loading_unloading_charges.others) - Number(this.coop.consumer_earnings.commision_paid.others) -
                        Number(this.coop.consumer_earnings.other_trade_charges.others);
                // CALCULATION OF Gross (+)Profit / (-)Loss
                this.coop.consumer_earnings.gross_profit_loss.total_gross_profit_loss = Number(this.coop.consumer_earnings.gross_profit_loss.groceries) +
                        Number(this.coop.consumer_earnings.gross_profit_loss.stationaries) +
                        Number(this.coop.consumer_earnings.gross_profit_loss.others) +
                        Number(this.coop.consumer_earnings.gross_profit_loss.textiles_garments) +
                        Number(this.coop.consumer_earnings.gross_profit_loss.vegetable_perishable_foods);
                
        this.coop.earnings.operating_income_trading_account = Number(this.coop.marketing_earnings.total_operating_income)
                        + Number(this.coop.processing_earnings.total_operating_income)
                        + Number(this.coop.consumer_earnings.total_operating_income);

                this.coop.earnings.operating_expenses_from_trading_account = this.coop.marketing_earnings.total_operating_expenses + this.coop.consumer_earnings.total_operating_expenses +
                        this.coop.processing_earnings.total_operating_expenses;

                this.coop.earnings.operating_income_trading_account = Number(this.coop.marketing_earnings.total_operating_income)
                        + Number(this.coop.processing_earnings.total_operating_income)
                        + Number(this.coop.consumer_earnings.total_operating_income);
            }

            if (this.id === 'processing_earnings') {
                // CALCULATION OF TOTAL OPERATING INCOME
                this.coop.processing_earnings.sales_retail.total_sales_retail = Number(this.coop.processing_earnings.sales_retail.jute_seed) +
                        Number(this.coop.processing_earnings.sales_retail.potato_seed) +
                        Number(this.coop.processing_earnings.sales_retail.others) +
                        Number(this.coop.processing_earnings.sales_retail.pulses_oilseed) +
                        Number(this.coop.processing_earnings.sales_retail.rice_seed);
                this.coop.processing_earnings.sales_distributorship.total_sales_distributorship = Number(this.coop.processing_earnings.sales_distributorship.jute_seed) +
                        Number(this.coop.processing_earnings.sales_distributorship.potato_seed) +
                        Number(this.coop.processing_earnings.sales_distributorship.others) +
                        Number(this.coop.processing_earnings.sales_distributorship.pulses_oilseed) +
                        Number(this.coop.processing_earnings.sales_distributorship.rice_seed);
                this.coop.processing_earnings.sales_government_supply.total_sales_government_supply = Number(this.coop.processing_earnings.sales_government_supply.jute_seed) +
                        Number(this.coop.processing_earnings.sales_government_supply.potato_seed) +
                        Number(this.coop.processing_earnings.sales_government_supply.others) +
                        Number(this.coop.processing_earnings.sales_government_supply.pulses_oilseed) +
                        Number(this.coop.processing_earnings.sales_government_supply.rice_seed);
                this.coop.processing_earnings.stock_transfer_return.total_stock_transfer_return = Number(this.coop.processing_earnings.stock_transfer_return.jute_seed) +
                        Number(this.coop.processing_earnings.stock_transfer_return.potato_seed) +
                        Number(this.coop.processing_earnings.stock_transfer_return.others) +
                        Number(this.coop.processing_earnings.stock_transfer_return.pulses_oilseed) +
                        Number(this.coop.processing_earnings.stock_transfer_return.rice_seed);
                this.coop.processing_earnings.closing_Stock.total_closing_Stock = Number(this.coop.processing_earnings.closing_Stock.jute_seed) +
                        Number(this.coop.processing_earnings.closing_Stock.potato_seed) +
                        Number(this.coop.processing_earnings.closing_Stock.others) +
                        Number(this.coop.processing_earnings.closing_Stock.pulses_oilseed) +
                        Number(this.coop.processing_earnings.closing_Stock.rice_seed);
                this.coop.processing_earnings.total_operating_income = Number(this.coop.processing_earnings.sales_retail.total_sales_retail) +
                        Number(this.coop.processing_earnings.sales_distributorship.total_sales_distributorship) +
                        Number(this.coop.processing_earnings.sales_government_supply.total_sales_government_supply) +
                        Number(this.coop.processing_earnings.stock_transfer_return.total_stock_transfer_return) +
                        Number(this.coop.processing_earnings.closing_Stock.total_closing_Stock);

                // TOTAL OPERATING EXPENSES
                this.coop.processing_earnings.opening_stock.total_opening_stock = Number(this.coop.processing_earnings.opening_stock.jute_seed) +
                        Number(this.coop.processing_earnings.opening_stock.potato_seed) +
                        Number(this.coop.processing_earnings.opening_stock.others) +
                        Number(this.coop.processing_earnings.opening_stock.pulses_oilseed) +
                        Number(this.coop.processing_earnings.opening_stock.rice_seed);
                this.coop.processing_earnings.purchases.total_purchases = Number(this.coop.processing_earnings.purchases.jute_seed) +
                        Number(this.coop.processing_earnings.purchases.potato_seed) +
                        Number(this.coop.processing_earnings.purchases.others) +
                        Number(this.coop.processing_earnings.purchases.pulses_oilseed) +
                        Number(this.coop.processing_earnings.purchases.rice_seed);
                this.coop.processing_earnings.stock_transfer.total_stock_transfer = Number(this.coop.processing_earnings.stock_transfer.jute_seed) +
                        Number(this.coop.processing_earnings.stock_transfer.potato_seed) +
                        Number(this.coop.processing_earnings.stock_transfer.others) +
                        Number(this.coop.processing_earnings.stock_transfer.pulses_oilseed) +
                        Number(this.coop.processing_earnings.stock_transfer.rice_seed);
                this.coop.processing_earnings.sale_return.total_sale_return = Number(this.coop.processing_earnings.sale_return.jute_seed) +
                        Number(this.coop.processing_earnings.sale_return.potato_seed) +
                        Number(this.coop.processing_earnings.sale_return.others) +
                        Number(this.coop.processing_earnings.sale_return.pulses_oilseed) +
                        Number(this.coop.processing_earnings.sale_return.rice_seed);
                this.coop.processing_earnings.carriage_inward.total_carriage_inward = Number(this.coop.processing_earnings.carriage_inward.jute_seed) +
                        Number(this.coop.processing_earnings.carriage_inward.potato_seed) +
                        Number(this.coop.processing_earnings.carriage_inward.others) +
                        Number(this.coop.processing_earnings.carriage_inward.pulses_oilseed) +
                        Number(this.coop.processing_earnings.carriage_inward.rice_seed);
                this.coop.processing_earnings.loading_unloading_charges.total_loading_unloading_charges = Number(this.coop.processing_earnings.loading_unloading_charges.jute_seed) +
                        Number(this.coop.processing_earnings.loading_unloading_charges.potato_seed) +
                        Number(this.coop.processing_earnings.loading_unloading_charges.others) +
                        Number(this.coop.processing_earnings.loading_unloading_charges.pulses_oilseed) +
                        Number(this.coop.processing_earnings.loading_unloading_charges.rice_seed);
                this.coop.processing_earnings.commision_paid.total_commision_paid = Number(this.coop.processing_earnings.commision_paid.jute_seed) +
                        Number(this.coop.processing_earnings.commision_paid.potato_seed) +
                        Number(this.coop.processing_earnings.commision_paid.others) +
                        Number(this.coop.processing_earnings.commision_paid.pulses_oilseed) +
                        Number(this.coop.processing_earnings.commision_paid.rice_seed);
                this.coop.processing_earnings.other_trade_charges.total_other_trade_charges = Number(this.coop.processing_earnings.other_trade_charges.jute_seed) +
                        Number(this.coop.processing_earnings.other_trade_charges.potato_seed) +
                        Number(this.coop.processing_earnings.other_trade_charges.others) +
                        Number(this.coop.processing_earnings.other_trade_charges.pulses_oilseed) +
                        Number(this.coop.processing_earnings.other_trade_charges.rice_seed);
                this.coop.processing_earnings.total_operating_expenses = Number(this.coop.processing_earnings.opening_stock.total_opening_stock) +
                        Number(this.coop.processing_earnings.purchases.total_purchases) +
                        Number(this.coop.processing_earnings.stock_transfer.total_stock_transfer) +
                        Number(this.coop.processing_earnings.sale_return.total_sale_return) +
                        Number(this.coop.processing_earnings.carriage_inward.total_carriage_inward) +
                        Number(this.coop.processing_earnings.loading_unloading_charges.total_loading_unloading_charges) +
                        Number(this.coop.processing_earnings.commision_paid.total_commision_paid) +
                        Number(this.coop.processing_earnings.other_trade_charges.total_other_trade_charges);

                this.coop.processing_earnings.gross_profit_loss.rice_seed = Number(this.coop.processing_earnings.sales_retail.rice_seed) + Number(this.coop.processing_earnings.stock_transfer_return.rice_seed) +
                        Number(this.coop.processing_earnings.closing_Stock.rice_seed) + Number(this.coop.processing_earnings.sales_distributorship.rice_seed) +
                        Number(this.coop.processing_earnings.sale_return.rice_seed) - Number(this.coop.processing_earnings.opening_stock.rice_seed) - Number(this.coop.processing_earnings.purchases.rice_seed) -
                        Number(this.coop.processing_earnings.stock_transfer.rice_seed) - Number(this.coop.processing_earnings.sale_return.rice_seed) - Number(this.coop.processing_earnings.carriage_inward.rice_seed) -
                        Number(this.coop.processing_earnings.loading_unloading_charges.rice_seed) - Number(this.coop.processing_earnings.commision_paid.rice_seed) - Number(this.coop.processing_earnings.other_trade_charges.rice_seed);

                this.coop.processing_earnings.gross_profit_loss.potato_seed = Number(this.coop.processing_earnings.sales_retail.potato_seed) + Number(this.coop.processing_earnings.stock_transfer_return.potato_seed) +
                        Number(this.coop.processing_earnings.closing_Stock.potato_seed) + Number(this.coop.processing_earnings.sales_distributorship.potato_seed) +
                        Number(this.coop.processing_earnings.sale_return.potato_seed) - Number(this.coop.processing_earnings.opening_stock.potato_seed) - Number(this.coop.processing_earnings.purchases.potato_seed) -
                        Number(this.coop.processing_earnings.stock_transfer.potato_seed) - Number(this.coop.processing_earnings.sale_return.potato_seed) - Number(this.coop.processing_earnings.carriage_inward.potato_seed) -
                        Number(this.coop.processing_earnings.loading_unloading_charges.potato_seed) - Number(this.coop.processing_earnings.commision_paid.potato_seed) -
                        Number(this.coop.processing_earnings.other_trade_charges.potato_seed);

                this.coop.processing_earnings.gross_profit_loss.jute_seed = Number(this.coop.processing_earnings.sales_retail.jute_seed) + Number(this.coop.processing_earnings.stock_transfer_return.jute_seed) +
                        Number(this.coop.processing_earnings.closing_Stock.jute_seed) + Number(this.coop.processing_earnings.sales_distributorship.jute_seed) +
                        Number(this.coop.processing_earnings.sale_return.jute_seed) - Number(this.coop.processing_earnings.opening_stock.jute_seed) - Number(this.coop.processing_earnings.purchases.jute_seed) -
                        Number(this.coop.processing_earnings.stock_transfer.jute_seed) - Number(this.coop.processing_earnings.sale_return.jute_seed) - Number(this.coop.processing_earnings.carriage_inward.jute_seed) -
                        Number(this.coop.processing_earnings.loading_unloading_charges.jute_seed) - Number(this.coop.processing_earnings.commision_paid.jute_seed) -
                        Number(this.coop.processing_earnings.other_trade_charges.jute_seed);

                this.coop.processing_earnings.gross_profit_loss.pulses_oilseed = Number(this.coop.processing_earnings.sales_retail.pulses_oilseed) + Number(this.coop.processing_earnings.stock_transfer_return.pulses_oilseed) +
                        Number(this.coop.processing_earnings.closing_Stock.pulses_oilseed) + Number(this.coop.processing_earnings.sales_distributorship.pulses_oilseed) +
                        Number(this.coop.processing_earnings.sale_return.pulses_oilseed) - Number(this.coop.processing_earnings.opening_stock.pulses_oilseed) - Number(this.coop.processing_earnings.purchases.pulses_oilseed) -
                        Number(this.coop.processing_earnings.stock_transfer.pulses_oilseed) - Number(this.coop.processing_earnings.sale_return.pulses_oilseed) - Number(this.coop.processing_earnings.carriage_inward.pulses_oilseed) -
                        Number(this.coop.processing_earnings.loading_unloading_charges.pulses_oilseed) - Number(this.coop.processing_earnings.commision_paid.pulses_oilseed) -
                        Number(this.coop.processing_earnings.other_trade_charges.pulses_oilseed);

                this.coop.processing_earnings.gross_profit_loss.others = Number(this.coop.processing_earnings.sales_retail.others) + Number(this.coop.processing_earnings.stock_transfer_return.others) +
                        Number(this.coop.processing_earnings.closing_Stock.others) + Number(this.coop.processing_earnings.sales_distributorship.others) +
                        Number(this.coop.processing_earnings.sale_return.others) - Number(this.coop.processing_earnings.opening_stock.others) - Number(this.coop.processing_earnings.purchases.others) -
                        Number(this.coop.processing_earnings.stock_transfer.others) - Number(this.coop.processing_earnings.sale_return.others) - Number(this.coop.processing_earnings.carriage_inward.others) -
                        Number(this.coop.processing_earnings.loading_unloading_charges.others) - Number(this.coop.processing_earnings.commision_paid.others) -
                        Number(this.coop.processing_earnings.other_trade_charges.others);
                // CALCULATION OF Gross (+)Profit / (-)Loss
                this.coop.processing_earnings.gross_profit_loss.total_gross_profit_loss = Number(this.coop.processing_earnings.gross_profit_loss.jute_seed) +
                        Number(this.coop.processing_earnings.gross_profit_loss.potato_seed) +
                        Number(this.coop.processing_earnings.gross_profit_loss.others) +
                        Number(this.coop.processing_earnings.gross_profit_loss.pulses_oilseed) +
                        Number(this.coop.processing_earnings.gross_profit_loss.rice_seed);

        this.coop.earnings.operating_income_trading_account = Number(this.coop.marketing_earnings.total_operating_income)
                        + Number(this.coop.processing_earnings.total_operating_income)
                        + Number(this.coop.consumer_earnings.total_operating_income);

                this.coop.earnings.operating_expenses_from_trading_account = this.coop.marketing_earnings.total_operating_expenses + this.coop.consumer_earnings.total_operating_expenses +
                        this.coop.processing_earnings.total_operating_expenses;

                this.coop.earnings.operating_income_trading_account = Number(this.coop.marketing_earnings.total_operating_income)
                        + Number(this.coop.processing_earnings.total_operating_income)
                        + Number(this.coop.consumer_earnings.total_operating_income);
            }

            if (this.id === 'liabilities') {
                // CALCULATION OF TOTAL OPERATING INCOME
                this.coop.liabilities.capital.authorised_share_capital.total_authorised_share_capital = Number(this.coop.liabilities.capital.authorised_share_capital.individual) +
                        Number(this.coop.liabilities.capital.authorised_share_capital.state_government);
                this.coop.liabilities.capital.subscribed_paid_up_share_capital.total_subscribed_paid_up_share_capital = Number(this.coop.liabilities.capital.subscribed_paid_up_share_capital.individual) +
                        Number(this.coop.liabilities.capital.subscribed_paid_up_share_capital.state_government);

                this.coop.liabilities.capital.total_capital = Number(this.coop.liabilities.capital.subscribed_paid_up_share_capital.total_subscribed_paid_up_share_capital);

                this.coop.liabilities.reserve.other_reserves_and_funds.total_other_reserves_and_funds = Number(this.coop.liabilities.reserve.other_reserves_and_funds.any_other_fund) +
                        Number(this.coop.liabilities.reserve.other_reserves_and_funds.building_fund) +
                        Number(this.coop.liabilities.reserve.other_reserves_and_funds.dividend_equalization_fund) +
                        Number(this.coop.liabilities.reserve.other_reserves_and_funds.education_fund) +
                        Number(this.coop.liabilities.reserve.other_reserves_and_funds.general_reserve) +
                        Number(this.coop.liabilities.reserve.other_reserves_and_funds.grant_received);
                this.coop.liabilities.reserve.total_reserve = Number(this.coop.liabilities.reserve.other_reserves_and_funds.total_other_reserves_and_funds) +
                        Number(this.coop.liabilities.reserve.reserve_for_bad_and_doubtful_debts) +
                        Number(this.coop.liabilities.reserve.reserve_for_overdue_interest) +
                        Number(this.coop.liabilities.reserve.reserve_fund_section_82);

                this.coop.liabilities.deposit.savings_deposit.total_savings_deposit = Number(this.coop.liabilities.deposit.savings_deposit.employees) +
                        Number(this.coop.liabilities.deposit.savings_deposit.individual) +
                        Number(this.coop.liabilities.deposit.savings_deposit.self_help_group);
                this.coop.liabilities.deposit.term_deposit.total_term_deposit = Number(this.coop.liabilities.deposit.term_deposit.employees) +
                        Number(this.coop.liabilities.deposit.term_deposit.individual) +
                        Number(this.coop.liabilities.deposit.term_deposit.self_help_group);
                this.coop.liabilities.deposit.total_deposit = Number(this.coop.liabilities.deposit.savings_deposit.total_savings_deposit) +
                        Number(this.coop.liabilities.deposit.term_deposit.total_term_deposit);

                this.coop.liabilities.borrowing.total_total_borrowing = this.coop.liabilities.borrowing.borrowing.total_borrowing = Number(this.coop.liabilities.borrowing.borrowing.banks) +
                        Number(this.coop.liabilities.borrowing.borrowing.government) +
                        Number(this.coop.liabilities.borrowing.borrowing.other_financial_institution);

                this.coop.liabilities.other_liabilities.total_other_liabilities = Number(this.coop.liabilities.other_liabilities.interest_payable_borrowing) +
                        Number(this.coop.liabilities.other_liabilities.interest_payable_on_deposit) +
                        Number(this.coop.liabilities.other_liabilities.any_other_contingent_liability) +
                        Number(this.coop.liabilities.other_liabilities.bonus_unpaid) +
                        Number(this.coop.liabilities.other_liabilities.cost_of_management_unpaid) +
                        Number(this.coop.liabilities.other_liabilities.dividend_unpaid);

                this.coop.liabilities.total_liabilities = Number(this.coop.liabilities.capital.total_capital) +
                        Number(this.coop.liabilities.reserve.total_reserve) +
                        Number(this.coop.liabilities.deposit.total_deposit) +
                        Number(this.coop.liabilities.borrowing.total_total_borrowing) +
                        Number(this.coop.liabilities.other_liabilities.total_other_liabilities) +
                        Number(this.coop.liabilities.accumulated_profit) -
                        Number(this.coop.liabilities.accumulated_loss);


                // LIABILITIES REFERENCE IN DEPOSITOR PROFILE SHEET
                this.coop.depositor_profile.deposit.savings_deposit.individual = Number(this.coop.liabilities.deposit.savings_deposit.individual) > 0 ? Number(this.coop.liabilities.deposit.savings_deposit.individual) : 0;
                this.coop.depositor_profile.deposit.savings_deposit.employees = Number(this.coop.liabilities.deposit.savings_deposit.employees) > 0 ? Number(this.coop.liabilities.deposit.savings_deposit.employees) : 0;
                this.coop.depositor_profile.deposit.savings_deposit.self_help_group = Number(this.coop.liabilities.deposit.savings_deposit.self_help_group) > 0 ? Number(this.coop.liabilities.deposit.savings_deposit.self_help_group) : 0;
                this.coop.depositor_profile.deposit.savings_deposit.total_savings_deposit = Number(this.coop.depositor_profile.deposit.savings_deposit.individual) +
                        Number(this.coop.depositor_profile.deposit.savings_deposit.employees) +
                        Number(this.coop.depositor_profile.deposit.savings_deposit.self_help_group);
                this.coop.depositor_profile.casa_deposit = Number(this.coop.depositor_profile.deposit.savings_deposit.total_savings_deposit);

                this.coop.depositor_profile.deposit.term_deposit_within_year.individual = Number(this.coop.liabilities.deposit.term_deposit.individual);
                this.coop.depositor_profile.deposit.term_deposit_within_year.employees = Number(this.coop.liabilities.deposit.term_deposit.employees);
                this.coop.depositor_profile.deposit.term_deposit_within_year.self_help_group = Number(this.coop.liabilities.deposit.term_deposit.self_help_group);
                this.coop.depositor_profile.deposit.term_deposit_within_year.total_term_deposit = Number(this.coop.depositor_profile.deposit.term_deposit_within_year.individual) +
                        Number(this.coop.depositor_profile.deposit.term_deposit_within_year.employees) +
                        Number(this.coop.depositor_profile.deposit.term_deposit_within_year.self_help_group);
                this.coop.depositor_profile.term_deposit = Number(this.coop.depositor_profile.deposit.term_deposit_within_year.total_term_deposit);

                this.coop.depositor_profile.total_deposit = Number(this.coop.depositor_profile.casa_deposit) +
                        Number(this.coop.depositor_profile.term_deposit);

                this.coop.depositor_profile.percentage_of_casa_deposit = ((Number(this.coop.depositor_profile.casa_deposit) /
                        Number(this.coop.depositor_profile.total_deposit)) * 100).toFixed(2);

                this.coop.depositor_profile.deposit.total_deposit = Number(this.coop.depositor_profile.deposit.savings_deposit.total_savings_deposit)
                Number(this.coop.depositor_profile.deposit.term_deposit_within_year.total_term_deposit);

                // LIABILITIES REFERENCE IN WORKING CAPITAL SHEET
                this.coop.working_capital.working_capital_as_per_definition_69.owned_capital = Number(this.coop.liabilities.capital.total_capital) +
                        Number(this.coop.liabilities.reserve.total_reserve) +
                        Number(this.coop.liabilities.accumulated_profit) - Number(this.coop.liabilities.accumulated_loss);
                this.coop.working_capital.working_capital_as_per_definition_69.borrowed_fund = Number(this.coop.liabilities.deposit.total_deposit) + Number(this.coop.liabilities.borrowing.total_total_borrowing);
                this.coop.working_capital.working_capital_as_per_definition_69.working_capital_owned_capital_borrowed_fund = Number(this.coop.working_capital.working_capital_as_per_definition_69.owned_capital) +
                        Number(this.coop.working_capital.working_capital_as_per_definition_69.borrowed_fund);

                this.coop.working_capital.working_capital_as_per_definition_69.current_liabilities_as_per_balance_sheet_in_Rs_Lakh =
                        Number(this.coop.liabilities.capital.total_capital) + Number(this.coop.liabilities.reserve.total_reserve) + Number(this.coop.liabilities.deposit.total_deposit) + Number(this.coop.liabilities.borrowing.total_total_borrowing) +
                        Number(this.coop.liabilities.other_liabilities.total_other_liabilities) + Number(this.coop.liabilities.accumulated_profit) + Number(this.coop.liabilities.accumulated_loss);

                //    LIABILITIES REFERENCE IN SHG SHEET
                this.coop.shg.amount_of_deposit_mobilized.savings_deposit = this.coop.liabilities.deposit.savings_deposit.self_help_group;
                this.coop.shg.amount_of_deposit_mobilized.term_deposit = this.coop.liabilities.deposit.term_deposit.self_help_group;
                this.coop.shg.amount_of_deposit_mobilized.total_amount_of_deposit_mobilized = Number(this.coop.shg.amount_of_deposit_mobilized.savings_deposit) +
                        Number(this.coop.shg.amount_of_deposit_mobilized.term_deposit);
            }

            if (this.id === 'assets') {
                // CALCULATION OF TOTAL OPERATING INCOME
                this.coop.assets.liquid_asset.total_liquid_asset = Number(this.coop.assets.liquid_asset.balance_with_current_account) +
                        Number(this.coop.assets.liquid_asset.cash);

                this.coop.assets.investment.government_securities.total_government_securities = Number(this.coop.assets.investment.government_securities.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment.government_securities.principal_amount);
                this.coop.assets.investment.deposit_with_wbscb.total_deposit_with_wbscb = Number(this.coop.assets.investment.deposit_with_wbscb.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment.deposit_with_wbscb.principal_amount);
                this.coop.assets.investment.deposit_with_post_Office.total_deposit_with_post_Office = Number(this.coop.assets.investment.deposit_with_post_Office.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment.deposit_with_post_Office.principal_amount);
                this.coop.assets.investment.deposit_with_any_scheduled_bank.total_deposit_with_any_scheduled_bank = Number(this.coop.assets.investment.deposit_with_any_scheduled_bank.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment.deposit_with_any_scheduled_bank.principal_amount);
                this.coop.assets.investment.shares_of_other_co_operative_societies.total_shares_of_other_co_operative_societies = Number(this.coop.assets.investment.shares_of_other_co_operative_societies.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment.shares_of_other_co_operative_societies.principal_amount);
                this.coop.assets.investment.any_other_investment.total_any_other_investment = Number(this.coop.assets.investment.any_other_investment.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment.any_other_investment.principal_amount);

                this.coop.assets.investment.total_investment = Number(this.coop.assets.investment.government_securities.total_government_securities) +
                        Number(this.coop.assets.investment.deposit_with_wbscb.total_deposit_with_wbscb) +
                        Number(this.coop.assets.investment.deposit_with_post_Office.total_deposit_with_post_Office) +
                        Number(this.coop.assets.investment.deposit_with_any_scheduled_bank.total_deposit_with_any_scheduled_bank) +
                        Number(this.coop.assets.investment.shares_of_other_co_operative_societies.total_shares_of_other_co_operative_societies) +
                        Number(this.coop.assets.investment.any_other_investment.total_any_other_investment);

                this.coop.assets.investment_of_reserve_fund.government_securities.total_government_securities = Number(this.coop.assets.investment_of_reserve_fund.government_securities.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment_of_reserve_fund.government_securities.principal_amount);
                this.coop.assets.investment_of_reserve_fund.deposit_with_wbscb.total_deposit_with_wbscb = Number(this.coop.assets.investment_of_reserve_fund.deposit_with_wbscb.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment_of_reserve_fund.deposit_with_wbscb.principal_amount);
                this.coop.assets.investment_of_reserve_fund.deposit_with_post_Office.total_deposit_with_post_Office = Number(this.coop.assets.investment_of_reserve_fund.deposit_with_post_Office.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment_of_reserve_fund.deposit_with_post_Office.principal_amount);
                this.coop.assets.investment_of_reserve_fund.deposit_with_any_scheduled_bank.total_deposit_with_any_scheduled_bank = Number(this.coop.assets.investment_of_reserve_fund.deposit_with_any_scheduled_bank.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment_of_reserve_fund.deposit_with_any_scheduled_bank.principal_amount);
                this.coop.assets.investment_of_reserve_fund.shares_of_other_co_operative_societies.total_shares_of_other_co_operative_societies = Number(this.coop.assets.investment_of_reserve_fund.shares_of_other_co_operative_societies.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment_of_reserve_fund.shares_of_other_co_operative_societies.principal_amount);
                this.coop.assets.investment_of_reserve_fund.any_other_investment.total_any_other_investment = Number(this.coop.assets.investment_of_reserve_fund.any_other_investment.interest_accrued_thereupon) +
                        Number(this.coop.assets.investment_of_reserve_fund.any_other_investment.principal_amount);

                this.coop.assets.investment_of_reserve_fund.total_investment_of_reserve_fund = Number(this.coop.assets.investment_of_reserve_fund.government_securities.total_government_securities) +
                        Number(this.coop.assets.investment_of_reserve_fund.deposit_with_wbscb.total_deposit_with_wbscb) +
                        Number(this.coop.assets.investment_of_reserve_fund.deposit_with_post_Office.total_deposit_with_post_Office) +
                        Number(this.coop.assets.investment_of_reserve_fund.deposit_with_any_scheduled_bank.total_deposit_with_any_scheduled_bank) +
                        Number(this.coop.assets.investment_of_reserve_fund.shares_of_other_co_operative_societies.total_shares_of_other_co_operative_societies) +
                        Number(this.coop.assets.investment_of_reserve_fund.any_other_investment.total_any_other_investment);

                this.coop.assets.fixed_assets.total_fixed_assets = Number(this.coop.assets.fixed_assets.any_other_fixed_asset) +
                        Number(this.coop.assets.fixed_assets.computer_and_accessories) +
                        Number(this.coop.assets.fixed_assets.dead_stock) +
                        Number(this.coop.assets.fixed_assets.furniture_and_fixture) +
                        Number(this.coop.assets.fixed_assets.land_and_building);

                this.coop.assets.loan_principal_outstanding.individual_loan.total_individual_loan = Number(this.coop.assets.loan_principal_outstanding.individual_loan.current) +
                        Number(this.coop.assets.loan_principal_outstanding.individual_loan.litigated) +
                        Number(this.coop.assets.loan_principal_outstanding.individual_loan.non_performing) +
                        Number(this.coop.assets.loan_principal_outstanding.individual_loan.overdue);
                this.coop.assets.loan_principal_outstanding.msme_sector.total_msme_sector = Number(this.coop.assets.loan_principal_outstanding.msme_sector.current) +
                        Number(this.coop.assets.loan_principal_outstanding.msme_sector.litigated) +
                        Number(this.coop.assets.loan_principal_outstanding.msme_sector.non_performing) +
                        Number(this.coop.assets.loan_principal_outstanding.msme_sector.overdue);
                this.coop.assets.loan_principal_outstanding.shg.total_shg = Number(this.coop.assets.loan_principal_outstanding.shg.current) +
                        Number(this.coop.assets.loan_principal_outstanding.shg.litigated) +
                        Number(this.coop.assets.loan_principal_outstanding.shg.non_performing) +
                        Number(this.coop.assets.loan_principal_outstanding.shg.overdue);
                this.coop.assets.loan_principal_outstanding.other.total_other = Number(this.coop.assets.loan_principal_outstanding.other.current) +
                        Number(this.coop.assets.loan_principal_outstanding.other.litigated) +
                        Number(this.coop.assets.loan_principal_outstanding.other.non_performing) +
                        Number(this.coop.assets.loan_principal_outstanding.other.overdue);
                this.coop.assets.loan_principal_outstanding.total_loan_principal_outstanding = Number(this.coop.assets.loan_principal_outstanding.individual_loan.total_individual_loan) +
                        Number(this.coop.assets.loan_principal_outstanding.msme_sector.total_msme_sector) +
                        Number(this.coop.assets.loan_principal_outstanding.shg.total_shg) +
                        Number(this.coop.assets.loan_principal_outstanding.other.total_other);

                this.coop.assets.interest_on_loan_outstanding.individual_loan.total_individual_loan = Number(this.coop.assets.interest_on_loan_outstanding.individual_loan.current) +
                        Number(this.coop.assets.interest_on_loan_outstanding.individual_loan.litigated) +
                        Number(this.coop.assets.interest_on_loan_outstanding.individual_loan.non_performing) +
                        Number(this.coop.assets.interest_on_loan_outstanding.individual_loan.overdue);
                this.coop.assets.interest_on_loan_outstanding.msme_sector.total_msme_sector = Number(this.coop.assets.interest_on_loan_outstanding.msme_sector.current) +
                        Number(this.coop.assets.interest_on_loan_outstanding.msme_sector.litigated) +
                        Number(this.coop.assets.interest_on_loan_outstanding.msme_sector.non_performing) +
                        Number(this.coop.assets.interest_on_loan_outstanding.msme_sector.overdue);
                this.coop.assets.interest_on_loan_outstanding.shg.total_shg = Number(this.coop.assets.interest_on_loan_outstanding.shg.current) +
                        Number(this.coop.assets.interest_on_loan_outstanding.shg.litigated) +
                        Number(this.coop.assets.interest_on_loan_outstanding.shg.non_performing) +
                        Number(this.coop.assets.interest_on_loan_outstanding.shg.overdue);
                this.coop.assets.interest_on_loan_outstanding.other.total_other = Number(this.coop.assets.interest_on_loan_outstanding.other.current) +
                        Number(this.coop.assets.interest_on_loan_outstanding.other.litigated) +
                        Number(this.coop.assets.interest_on_loan_outstanding.other.non_performing) +
                        Number(this.coop.assets.interest_on_loan_outstanding.other.overdue);
                this.coop.assets.interest_on_loan_outstanding.total_interest_on_loan_outstanding = Number(this.coop.assets.interest_on_loan_outstanding.individual_loan.total_individual_loan) +
                        Number(this.coop.assets.interest_on_loan_outstanding.msme_sector.total_msme_sector) +
                        Number(this.coop.assets.interest_on_loan_outstanding.shg.total_shg) +
                        Number(this.coop.assets.interest_on_loan_outstanding.other.total_other);

                this.coop.assets.other_assest.total_other_assest = Number(this.coop.assets.other_assest.tds_receivable) +
                        Number(this.coop.assets.other_assest.gst_receivable) +
                        Number(this.coop.assets.other_assest.it_receivable) +
                        Number(this.coop.assets.other_assest.stock_in_trade) +
                        Number(this.coop.assets.other_assest.other_assest);

                this.coop.assets.total_assets = Number(this.coop.assets.liquid_asset.total_liquid_asset) + Number(this.coop.assets.investment.total_investment) + Number(this.coop.assets.investment_of_reserve_fund.total_investment_of_reserve_fund) + Number(this.coop.assets.fixed_assets.total_fixed_assets) +
                        Number(this.coop.assets.loan_principal_outstanding.total_loan_principal_outstanding) + Number(this.coop.assets.interest_on_loan_outstanding.total_interest_on_loan_outstanding) + Number(this.coop.assets.other_assest.total_other_assest);

                // ASSET REFERENCE IN LOANEE PROFILE SHEET
                this.coop.loanee_profile.loan_principal_outstanding.individual_loan.current = this.coop.assets.loan_principal_outstanding.individual_loan.current;
                this.coop.loanee_profile.loan_principal_outstanding.individual_loan.overdue = this.coop.assets.loan_principal_outstanding.individual_loan.overdue;
                this.coop.loanee_profile.loan_principal_outstanding.individual_loan.non_performing = this.coop.assets.loan_principal_outstanding.individual_loan.non_performing;
                this.coop.loanee_profile.loan_principal_outstanding.individual_loan.litigated = this.coop.assets.loan_principal_outstanding.individual_loan.litigated;
                this.coop.loanee_profile.loan_principal_outstanding.individual_loan.total_individual_loan = Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.current) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.non_performing) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.litigated) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.overdue);

                this.coop.loanee_profile.loan_principal_outstanding.msme_sector.current = this.coop.assets.loan_principal_outstanding.msme_sector.current;
                this.coop.loanee_profile.loan_principal_outstanding.msme_sector.overdue = this.coop.assets.loan_principal_outstanding.msme_sector.overdue;
                this.coop.loanee_profile.loan_principal_outstanding.msme_sector.non_performing = this.coop.assets.loan_principal_outstanding.msme_sector.non_performing;
                this.coop.loanee_profile.loan_principal_outstanding.msme_sector.litigated = this.coop.assets.loan_principal_outstanding.msme_sector.litigated;

                this.coop.loanee_profile.loan_principal_outstanding.msme_sector.total_msme_sector = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.current) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.non_performing) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.litigated) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.overdue);

                this.coop.loanee_profile.loan_principal_outstanding.shg.current = this.coop.assets.loan_principal_outstanding.shg.current;
                this.coop.loanee_profile.loan_principal_outstanding.shg.overdue = this.coop.assets.loan_principal_outstanding.shg.overdue;
                this.coop.loanee_profile.loan_principal_outstanding.shg.non_performing = this.coop.assets.loan_principal_outstanding.shg.non_performing;
                this.coop.loanee_profile.loan_principal_outstanding.shg.litigated = this.coop.assets.loan_principal_outstanding.shg.litigated;

                this.coop.loanee_profile.loan_principal_outstanding.shg.total_shg = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.current) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.shg.non_performing) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.shg.litigated) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.shg.overdue);

                this.coop.loanee_profile.loan_principal_outstanding.other.current = this.coop.assets.loan_principal_outstanding.other.current;
                this.coop.loanee_profile.loan_principal_outstanding.other.overdue = this.coop.assets.loan_principal_outstanding.other.overdue;
                this.coop.loanee_profile.loan_principal_outstanding.other.non_performing = this.coop.assets.loan_principal_outstanding.other.non_performing;
                this.coop.loanee_profile.loan_principal_outstanding.other.litigated = this.coop.assets.loan_principal_outstanding.other.litigated;

                this.coop.loanee_profile.loan_principal_outstanding.other.total_other = Number(this.coop.loanee_profile.loan_principal_outstanding.other.current) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.other.non_performing) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.other.litigated) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.other.overdue);

                this.coop.loanee_profile.loan_principal_outstanding.total_loan_principal_outstanding = Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.total_individual_loan) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.total_msme_sector) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.shg.total_shg) +
                        Number(this.coop.loanee_profile.loan_principal_outstanding.other.total_other);

                this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.current = this.coop.assets.interest_on_loan_outstanding.individual_loan.current;
                this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.overdue = this.coop.assets.interest_on_loan_outstanding.individual_loan.overdue;
                this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.non_performing = this.coop.assets.interest_on_loan_outstanding.individual_loan.non_performing;
                this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.litigated = this.coop.assets.interest_on_loan_outstanding.individual_loan.litigated;

                this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.total_individual_loan = Number(this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.current) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.non_performing) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.litigated) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.overdue);

                this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.current = this.coop.assets.interest_on_loan_outstanding.msme_sector.current;
                this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.overdue = this.coop.assets.interest_on_loan_outstanding.msme_sector.overdue;
                this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.non_performing = this.coop.assets.interest_on_loan_outstanding.msme_sector.non_performing;
                this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.litigated = this.coop.assets.interest_on_loan_outstanding.msme_sector.litigated;

                this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.total_msme_sector = Number(this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.current) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.non_performing) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.litigated) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.overdue);

                this.coop.loanee_profile.interest_on_loan_outstanding.shg.current = this.coop.assets.interest_on_loan_outstanding.shg.current;
                this.coop.loanee_profile.interest_on_loan_outstanding.shg.overdue = this.coop.assets.interest_on_loan_outstanding.shg.overdue;
                this.coop.loanee_profile.interest_on_loan_outstanding.shg.non_performing = this.coop.assets.interest_on_loan_outstanding.shg.non_performing;
                this.coop.loanee_profile.interest_on_loan_outstanding.shg.litigated = this.coop.assets.interest_on_loan_outstanding.shg.litigated;

                this.coop.loanee_profile.interest_on_loan_outstanding.shg.total_shg = Number(this.coop.loanee_profile.interest_on_loan_outstanding.shg.current) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.shg.non_performing) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.shg.litigated) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.shg.overdue);

                this.coop.loanee_profile.interest_on_loan_outstanding.other.current = this.coop.assets.interest_on_loan_outstanding.other.current;
                this.coop.loanee_profile.interest_on_loan_outstanding.other.overdue = this.coop.assets.interest_on_loan_outstanding.other.overdue;
                this.coop.loanee_profile.interest_on_loan_outstanding.other.non_performing = this.coop.assets.interest_on_loan_outstanding.other.non_performing;
                this.coop.loanee_profile.interest_on_loan_outstanding.other.litigated = this.coop.assets.interest_on_loan_outstanding.other.litigated;

                this.coop.loanee_profile.interest_on_loan_outstanding.other.total_other = Number(this.coop.loanee_profile.interest_on_loan_outstanding.other.current) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.other.non_performing) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.other.litigated) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.other.overdue);

                this.coop.loanee_profile.interest_on_loan_outstanding.total_interest_on_loan_outstanding = Number(this.coop.loanee_profile.interest_on_loan_outstanding.individual_loan.total_individual_loan) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.msme_sector.total_msme_sector) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.shg.total_shg) +
                        Number(this.coop.loanee_profile.interest_on_loan_outstanding.other.total_other);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.total_no_of_individual_loanee_member_in_msme_sector = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.total_no_of_individual_loanee_member_in_shg_fold = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current = Number(this.coop.loanee_profile.loan_principal_outstanding.other.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.other.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.other.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.other.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.total_no_of_individual_loanee_member_in_other_sector = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.total_average_loan_portfolio_of_loanee_members = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.total_no_of_individual_loanee_member) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.total_no_of_individual_loanee_member_in_msme_sector) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.total_no_of_individual_loanee_member_in_shg_fold) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.total_no_of_individual_loanee_member_in_other_sector);

                // ASSET REFERENCE IN SHG SHEET
                this.coop.shg.amount_of_loan_outstanding.current = this.coop.assets.loan_principal_outstanding.shg.current;
                this.coop.shg.amount_of_loan_outstanding.overdue = this.coop.assets.loan_principal_outstanding.shg.overdue;
                this.coop.shg.amount_of_loan_outstanding.non_performing = this.coop.assets.loan_principal_outstanding.shg.non_performing;
                this.coop.shg.amount_of_loan_outstanding.litigated = this.coop.assets.loan_principal_outstanding.shg.litigated;
                this.coop.shg.amount_of_loan_outstanding.total_amount_of_loan_outstanding = Number(this.coop.shg.amount_of_loan_outstanding.current) + Number(this.coop.shg.amount_of_loan_outstanding.overdue) +
                        Number(this.coop.shg.amount_of_loan_outstanding.non_performing) + Number(this.coop.shg.amount_of_loan_outstanding.litigated);
            }

            if (this.id === 'depositor_profile') {
                this.coop.depositor_profile.deposit.term_deposit_within_year.total_term_deposit = Number(this.coop.depositor_profile.deposit.term_deposit_within_year.individual) +
                        Number(this.coop.depositor_profile.deposit.term_deposit_within_year.employees);

                this.coop.depositor_profile.deposit.total_deposit = Number(this.coop.depositor_profile.deposit.savings_deposit.total_savings_deposit) +
                        Number(this.coop.depositor_profile.deposit.term_deposit_within_year.total_term_deposit);

                this.coop.depositor_profile.number_of_depositors.savings_depositor_no.total_savings_depositor_no = Number(this.coop.depositor_profile.number_of_depositors.savings_depositor_no.individual) +
                        Number(this.coop.depositor_profile.number_of_depositors.savings_depositor_no.employees) +
                        Number(this.coop.depositor_profile.number_of_depositors.savings_depositor_no.self_help_group);

                this.coop.depositor_profile.number_of_depositors.term_depositor_no_within_year.total_term_depositor_no_within_year = Number(this.coop.depositor_profile.number_of_depositors.term_depositor_no_within_year.individual) +
                        Number(this.coop.depositor_profile.number_of_depositors.term_depositor_no_within_year.employees) +
                        Number(this.coop.depositor_profile.number_of_depositors.term_depositor_no_within_year.self_help_group);

                this.coop.depositor_profile.number_of_depositors.total_number_of_depositors = Number(this.coop.depositor_profile.number_of_depositors.savings_depositor_no.total_savings_depositor_no)
                Number(this.coop.depositor_profile.number_of_depositors.term_depositor_no_within_year.total_term_depositor_no_within_year);

                this.coop.depositor_profile.casa_depositor = Number(this.coop.depositor_profile.number_of_depositors.savings_depositor_no.total_savings_depositor_no);

                this.coop.depositor_profile.term_depositor = Number(this.coop.depositor_profile.number_of_depositors.term_depositor_no_within_year.total_term_depositor_no_within_year)

                this.coop.depositor_profile.total_depositor = Number(this.coop.depositor_profile.casa_depositor) +
                        Number(this.coop.depositor_profile.term_depositor);

                this.coop.depositor_profile.percentage_of_casa_depositor = ((Number(this.coop.depositor_profile.casa_depositor) /
                        Number(this.coop.depositor_profile.total_depositor)) * 100).toFixed(2);


                this.coop.liabilities.deposit.term_deposit.individual = Number(this.coop.depositor_profile.deposit.term_deposit_above_year.individual) + Number(this.coop.depositor_profile.deposit.term_deposit_within_year.individual);
                this.coop.liabilities.deposit.term_deposit.employees = Number(this.coop.depositor_profile.deposit.term_deposit_above_year.employees) + Number(this.coop.depositor_profile.deposit.term_deposit_within_year.employees);
                this.coop.liabilities.deposit.term_deposit.self_help_group = Number(this.coop.depositor_profile.deposit.term_deposit_within_year.self_help_group);
                this.coop.liabilities.deposit.term_deposit.total_term_deposit = Number(this.coop.liabilities.deposit.term_deposit.individual) +
                        Number(this.coop.liabilities.deposit.term_deposit.employees) +
                        Number(this.coop.liabilities.deposit.term_deposit.self_help_group);
                this.coop.liabilities.deposit.total_deposit = Number(this.coop.liabilities.deposit.savings_deposit.total_savings_deposit) +
                        Number(this.coop.liabilities.deposit.term_deposit.total_term_deposit);
            }

            if (this.id === 'loanee_profile') {
                this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.total_no_of_individual_loanee_member = Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.current) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.overdue) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.non_performing) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.litigated);
                this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.total_no_of_individual_loanee_member_in_msme_sector = Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated);
                this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.total_no_of_individual_loanee_member_in_other_sector = Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated);
                this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.total_no_of_individual_loanee_member_in_shg_fold = Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated);
                this.coop.loanee_profile.no_of_loanee_members.total_no_of_loanee_members = Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.total_no_of_individual_loanee_member) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.total_no_of_individual_loanee_member_in_msme_sector) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.total_no_of_individual_loanee_member_in_other_sector) +
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.total_no_of_individual_loanee_member_in_shg_fold);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.current = Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.individual_loan.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.total_no_of_individual_loanee_member = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.msme_sector.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.total_no_of_individual_loanee_member_in_msme_sector = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.shg.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.total_no_of_individual_loanee_member_in_shg_fold = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current = Number(this.coop.loanee_profile.loan_principal_outstanding.other.current) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue = Number(this.coop.loanee_profile.loan_principal_outstanding.other.overdue) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing = Number(this.coop.loanee_profile.loan_principal_outstanding.other.non_performing) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated = Number(this.coop.loanee_profile.loan_principal_outstanding.other.litigated) /
                        Number(this.coop.loanee_profile.no_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated);
                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.total_no_of_individual_loanee_member_in_other_sector = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.current) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.overdue) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.non_performing) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.litigated);

                this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.total_average_loan_portfolio_of_loanee_members = Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member.total_no_of_individual_loanee_member) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_msme_sector.total_no_of_individual_loanee_member_in_msme_sector) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_shg_fold.total_no_of_individual_loanee_member_in_shg_fold) +
                        Number(this.coop.loanee_profile.average_loan_portfolio_of_loanee_members.no_of_individual_loanee_member_in_other_sector.total_no_of_individual_loanee_member_in_other_sector);

                this.coop.loanee_profile.classification_of_asset.performing_asset.total_performing_asset = Number(this.coop.loanee_profile.classification_of_asset.performing_asset.current) +
                        Number(this.coop.loanee_profile.classification_of_asset.performing_asset.overdue_less_than_quarter);

                this.coop.loanee_profile.classification_of_asset.doubtful_asset.total_doubtful_asset = Number(this.coop.loanee_profile.classification_of_asset.doubtful_asset.doubtful_more_than_1_year_and_upto_3_years) +
                        Number(this.coop.loanee_profile.classification_of_asset.doubtful_asset.doubtful_more_than_3_years) +
                        Number(this.coop.loanee_profile.classification_of_asset.doubtful_asset.doubtful_upto_year);

                this.coop.loanee_profile.classification_of_asset.total_non_performing_asset = Number(this.coop.loanee_profile.classification_of_asset.total_sub_standard_asset) +
                        Number(this.coop.loanee_profile.classification_of_asset.doubtful_asset.total_doubtful_asset) +
                        Number(this.coop.loanee_profile.classification_of_asset.loss_asset);
            }

            if (this.id === 'earnings') {
                this.coop.earnings.interest_on_investments.total_interest_on_investments = Number(this.coop.earnings.interest_on_investments.dividend) +
                        Number(this.coop.earnings.interest_on_investments.interest_received_on_standard_investments);
                this.coop.earnings.interest_discount_received = Number(this.coop.earnings.discount_received_on_loans_and_advances) +
                        Number(this.coop.earnings.interest_on_investments.total_interest_on_investments);

                this.coop.earnings.other_operating_income.total_other_operating_income = Number(this.coop.earnings.other_operating_income.fee_commission) +
                        Number(this.coop.earnings.other_operating_income.miscellaneous_income) +
                        Number(this.coop.earnings.other_operating_income.profit_loss);

                this.coop.earnings.total_operating_income = Number(this.coop.earnings.interest_discount_received) +
                        Number(this.coop.earnings.other_operating_income.total_other_operating_income) +
                        Number(this.coop.earnings.operating_income_trading_account);

                this.coop.earnings.non_operating_income.total_non_operating_income = Number(this.coop.earnings.non_operating_income.profit_loss_of_fixed_assets) +
                        Number(this.coop.earnings.non_operating_income.profit_loss_of_other_assets) +
                        Number(this.coop.earnings.non_operating_income.non_operating_income);

                this.coop.earnings.total_income = Number(this.coop.earnings.total_operating_income) +
                        Number(this.coop.earnings.non_operating_income.total_non_operating_income);

                this.coop.earnings.interest_paid.total_interest_paid = Number(this.coop.earnings.interest_paid.bank_borrowings) +
                        Number(this.coop.earnings.interest_paid.customer_deposits) +
                        Number(this.coop.earnings.interest_paid.others);

                this.coop.earnings.operating_expenses.total_operating_expenses = Number(this.coop.earnings.operating_expenses.auditors_fees) +
                        Number(this.coop.earnings.operating_expenses.directors_fees) +
                        Number(this.coop.earnings.operating_expenses.staff_expenses);

                this.coop.earnings.other_operating_expenses.total_other_operating_expenses = Number(this.coop.earnings.other_operating_expenses.advertisement) +
                        Number(this.coop.earnings.other_operating_expenses.depreciation) +
                        Number(this.coop.earnings.other_operating_expenses.insurance) +
                        Number(this.coop.earnings.other_operating_expenses.law_charges) +
                        Number(this.coop.earnings.other_operating_expenses.rent_taxes_lighting) +
                        Number(this.coop.earnings.other_operating_expenses.postage) +
                        Number(this.coop.earnings.other_operating_expenses.stationery) +
                        Number(this.coop.earnings.other_operating_expenses.repairs) +
                        Number(this.coop.earnings.other_operating_expenses.others);

                this.coop.earnings.total_operating_expenses = Number(this.coop.earnings.interest_paid.total_interest_paid) +
                        Number(this.coop.earnings.operating_expenses.total_operating_expenses) +
                        Number(this.coop.earnings.other_operating_expenses.total_other_operating_expenses) +
                        Number(this.coop.earnings.operating_expenses_from_trading_account);

                this.coop.earnings.operating_profit_loss = Number(this.coop.earnings.total_income) - Number(this.coop.earnings.total_operating_expenses);

                this.coop.earnings.write_off.total_write_off = Number(this.coop.earnings.write_off.bad_debts_written_off) +
                        Number(this.coop.earnings.write_off.other_assets_written_off) + Number(this.coop.earnings.write_off.capitalised_expenditure);

                this.coop.earnings.provision_against_risks.total_provision_against_risks = Number(this.coop.earnings.provision_against_risks.provision_for_loan_losses) +
                        Number(this.coop.earnings.provision_against_risks.provision_for_depreciation) +
                        Number(this.coop.earnings.provision_against_risks.other_risk) +
                        Number(this.coop.earnings.provision_against_risks.other_provisions);

                this.coop.earnings.net_operating_profit = Number(this.coop.earnings.operating_profit_loss) - Number(this.coop.earnings.write_off.total_write_off) - Number(this.coop.earnings.provision_against_risks.total_provision_against_risks);

                this.coop.earnings.net_profit = Number(this.coop.earnings.net_operating_profit) - Number(this.coop.earnings.provisions_for_taxes);

                this.coop.earnings.net_distributable_profit_loss.total_net_distributable_profit_loss = Number(this.coop.earnings.net_profit) +
                        Number(this.coop.earnings.balance_of_previous);

                this.coop.earnings.appropriation_of_profits.total_appropriation_of_profits = Number(this.coop.earnings.appropriation_of_profits.appropriation_towards_statutory_reserves) +
                        Number(this.coop.earnings.appropriation_of_profits.appropriation_towards_building_fund) +
                        Number(this.coop.earnings.appropriation_of_profits.appropriation_towards_dividend_equalisation_fund) +
                        Number(this.coop.earnings.appropriation_of_profits.appropriation_towards_other_reserves) +
                        Number(this.coop.earnings.appropriation_of_profits.dividend);

                this.coop.earnings.net_interest_income = Number(this.coop.earnings.interest_discount_received);
                this.coop.earnings.non_interest_income = Number(this.coop.earnings.other_operating_income.total_other_operating_income);
                this.coop.earnings.non_interest_expenditure = Number(this.coop.earnings.operating_expenses.total_operating_expenses) + Number(this.coop.earnings.other_operating_expenses.total_other_operating_expenses);
                this.coop.earnings.number_of_employees.total_number_of_employees = Number(this.coop.earnings.number_of_employees.permanent_employees) +
                        Number(this.coop.earnings.number_of_employees.casual_employees) +
                        Number(this.coop.earnings.number_of_employees.contractual_employees) +
                        Number(this.coop.earnings.number_of_employees.contingent_menials);
                this.coop.earnings.expenditure_for_salary.total_expenditure_for_salary = Number(this.coop.earnings.expenditure_for_salary.permanent_employees) +
                        Number(this.coop.earnings.expenditure_for_salary.casual_employees) +
                        Number(this.coop.earnings.expenditure_for_salary.contractual_employees) +
                        Number(this.coop.earnings.expenditure_for_salary.contingent_menials);
            }
        },
    }
}
</script>

<style scoped>

</style>
