<template>
    <job-list-template status="Completed" result="Failed" :header="$t('page.job.card_label.failed_jobs')">
        <template v-slot:table="{ jobs, get_jobs }">
            <table class="table mg-b-0">
                <thead class="thead-light">
                    <tr>
                        <th>{{ $t('page.job.file') }}</th>
                        <th class="text-center">{{ $t('page.job.deleted') }}</th>
                        <th>{{ $t('page.job.created_by') }}</th>
                        <th>{{ $t('page.job.created_at') }}</th>
                        <th>{{ $t('page.job.updated_at') }}</th>
                        <th class="text-center">{{ $t('page.job.action') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="job in jobs" :key="job.job_id">
                        <td>{{ $filters.filename(job.file_name) }}</td>
                        <td class="text-center">{{ job.is_file_deleted ? $t('page.job.yes') : $t('page.job.no') }}</td>
                        <td>{{ job.created_by_name }}</td>
                        <td>{{ $filters.date(job.created_at) }}</td>
                        <td>{{ $filters.date(job.updated_at) }}</td>
                        <td class="text-center mw-100">
                            <a class="text-secondary mr-3" @click="log_job(job, get_jobs)" :title="$t('page.job.log')"
                                href="javascript:void(0);">
                                <i class="fa fa-lg fa-scroll" />
                            </a>

                            <a class="text-info mr-3" @click="retry_job(job.job_id, get_jobs)" :title="$t('page.job.retry')"
                                href="javascript:void(0);">
                                <i class="fa fa-lg fa-redo-alt" />
                            </a>

                            <a class="text-danger mr-3" @click="delete_job(job.job_id, get_jobs)"
                                :title="$t('page.job.delete')" href="javascript:void(0);">
                                <i class="fa fa-lg fa-trash-alt" />
                            </a>

                            <a class="text-primary" @click="downloadAttachment(job.job_id, job.file_name)"
                                :title="$t('page.job.download')" href="javascript:void(0);">
                                <i class="fa fa-lg fa-download" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </job-list-template>

    <div id="jobRunModal" class="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">{{ selectedJob.file_name }}</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <table class="table mg-b-0">
                        <thead>
                            <tr>
                                <th>{{ $t('page.job.status') }}</th>
                                <th>{{ $t('page.job.message') }}</th>
                                <th>{{ $t('page.job.created_at') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="job_run in job_runs" :key="job_run.created_at">
                                <td>{{ job_run.status }}</td>
                                <td>{{ job_run.message }}</td>
                                <td>{{ $filters.date(job_run.created_at) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-indigo" @click="hide_log">{{ $t('page.job.done') }}</button>
                </div>
            </div>
        </div><!-- modal-dialog -->
    </div>
</template>

<script>
import JobListTemplate from './JobListTemplate.vue';

export default {
    name: "JobsList",
    components: { JobListTemplate },
    data() {
        return {
            selectedJob: {},
            job_runs: [],
        };
    },
    methods: {
        async log_job(job, get_jobs) {
            try {
                this.selectedJob = job;
                let { data } = await this.$api.get(`jobs/${job.job_id}`);
                this.job_runs = data;
                $('#jobRunModal').modal('show');
            } catch (e) {
                console.log(e);
            }
        },
        async hide_log() {
            $('#jobRunModal').modal('hide');
        },

        async retry_job(job_id, get_jobs) {
            try {
                await this.$api.patch(`jobs/${job_id}`);
            } catch (e) {
                console.log(e);
            }
            get_jobs();
        },
        async delete_job(job_id, get_jobs) {
            try {
                await this.$api.delete(`jobs/${job_id}`);
            } catch (e) {
                console.log(e);
            }
            get_jobs();
        },
        async downloadAttachment(job_id, file_name) {
            let { data } = await this.$api.get(`jobs/${job_id}/download`);
            let link = document.createElement('a');
            link.href = 'data:application/octet-stream;base64,' + data;
            link.download = file_name;
            link.click();
        }
    }
}
</script>

<style scoped></style>

