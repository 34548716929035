<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card card-dashboard-twelve">
                <div class="card-header border-bottom">
                    <h5 class="card-title">{{$t('page.coop.card_label.import_coop')}}</h5>
                    <p class="card-subtitle text-muted mg-b-15">{{$t('page.coop.card_label.import_sub')}}
                        <a download="" @click="downloadTemplate" href="javascript:void(0)" class="font-weight-bold ml-2" title="Download template">
                            <i class="fa fa-download"/>
                        </a>
                    </p>
                </div>
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label mg-b-0">{{$t('page.coop.select_files')}}</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" ref="file" @change="handleFileChange"
                                       id="customFile" accept=".xls,.xlsx" multiple>
                                <label class="custom-file-label" for="customFile" v-if="files.length>0">{{files.length}} {{$t('page.coop.file_selected')}}</label>
                                <label class="custom-file-label" for="customFile" v-else>{{$t('page.coop.choose_files')}}</label>
                            </div>
                        </div><!-- col -->
                    </div>

                    <div class="row mt-4" v-if="uploading">
                        <div class="col-12">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped" :style="`width:${stats.percentage}%`" role="progressbar" :aria-valuenow="stats.done" aria-valuemin="0" :aria-valuemax="stats.total"></div>
                            </div>
                        </div>
                        <div class="col-12 text-center" v-if="stats.total !== stats.done">
<!--                            Uploading {{stats.done}} of {{stats.total}} files-->
                            {{ $filters.interpolate($t('page.coop.uploading'), { done: stats.done, total: stats.total}) }}
                        </div>
                        <div class="col-12 text-center" v-else>
<!--                            Successfully uploaded {{stats.total}} files, failed {{stats.failed}} files-->
                            {{ $filters.interpolate($t('page.coop.upload_result'), { failed: stats.failed, total: stats.total}) }}
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right btn-toolbar">
                        <button class="btn btn-success mr-2" @click="upload" :disabled="uploading">{{$t('page.coop.button.submit')}}</button>
                        <button class="btn btn-light" @click="reset" :disabled="uploading && stats.done != stats.total">{{$t('page.coop.button.reset')}}</button>
                    </div>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div aria-hidden="true" class="modal fade" id="delModal" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Choose Type of Co-operative
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">Select Template</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-9 input-group">
                                <select class="form-control select2-no-search" v-model="selected_template">
                                    <option :value="template.id" v-for="template in templates">{{template.type_of_coop}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            <i class="fas fa-ban"></i>&nbsp;&nbsp;Cancel
                        </button>
                        <a :class="{'disabled': selected_template !== 24}" download="" @click="downloadTemplate" href="javascript:void(0)" class="btn btn-primary" type="button">
                            <i class="fas fa-download"></i>&nbsp;&nbsp;Download
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Add",
        data() {
            return {
                templates: [
                    {
                        "id": 1,
                        "type_of_coop": "(P)Bidi Makers’ Co-operative Society",
                        "abbreviation": "BMCS"
                    },
                    {
                        "id": 2,
                        "type_of_coop": "(P)Cold Storage Co-operative Society",
                        "abbreviation": "CSCS"
                    },
                    {
                        "id": 3,
                        "type_of_coop": "(P)Consumers’ Co-operative Store / Society",
                        "abbreviation": "CCSS"
                    },
                    {
                        "id": 4,
                        "type_of_coop": "(P)Employees’  Credit Cooperative Society ",
                        "abbreviation": "ECCS"
                    },
                    {
                        "id": 5,
                        "type_of_coop": "(P)Engineers’ Co-operative Society",
                        "abbreviation": "ENCS"
                    },
                    {
                        "id": 6,
                        "type_of_coop": "(P)Farmers’ Service Co-operative  Society (FSCS)",
                        "abbreviation": "FSCS"
                    },
                    {
                        "id": 7,
                        "type_of_coop": "(P)Fee Parking Co-operative Society",
                        "abbreviation": "FPCS"
                    },
                    {
                        "id": 8,
                        "type_of_coop": "(P)Fishermen’s  Cooperative Society *",
                        "abbreviation": "FICS"
                    },
                    {
                        "id": 9,
                        "type_of_coop": "(P)Fruit  & Vegetables Processing Co-operative Society",
                        "abbreviation": "FVPC"
                    },
                    {
                        "id": 10,
                        "type_of_coop": "(P)Hawkers’ Co-operative Society",
                        "abbreviation": "HACS"
                    },
                    {
                        "id": 11,
                        "type_of_coop": "(P)Horticulture & Plantation Cooperative Society*",
                        "abbreviation": "HPCS"
                    },
                    {
                        "id": 12,
                        "type_of_coop": "(P)Housing Co-operative Society ",
                        "abbreviation": "HOCS"
                    },
                    {
                        "id": 13,
                        "type_of_coop": "(P)Irrigation Cooperative Society",
                        "abbreviation": "IRCS"
                    },
                    {
                        "id": 14,
                        "type_of_coop": "(P)Labour Contract Co-operative Society",
                        "abbreviation": "LCCS"
                    },
                    {
                        "id": 15,
                        "type_of_coop": "(P)Large-sized Adivasi Multipurpose Cooperative Societies (LAMPS)*",
                        "abbreviation": "LAMS"
                    },
                    {
                        "id": 16,
                        "type_of_coop": "(P)Other  Agro-processing Co-operative Society",
                        "abbreviation": "OAPC"
                    },
                    {
                        "id": 17,
                        "type_of_coop": "(P)Other Agricultural Co-operative Society",
                        "abbreviation": "OACS"
                    },
                    {
                        "id": 18,
                        "type_of_coop": "(P)Other Agricultural Production Co-operative Society",
                        "abbreviation": "OAPS"
                    },
                    {
                        "id": 19,
                        "type_of_coop": "(P)Other Industrial Co-operative Society",
                        "abbreviation": "OICS"
                    },
                    {
                        "id": 20,
                        "type_of_coop": "(P)Other Multi-purpose Co-operative Society",
                        "abbreviation": "OMCS"
                    },
                    {
                        "id": 21,
                        "type_of_coop": "(P)Other Non-agricultural Credit Co-operative Society",
                        "abbreviation": "ONCS"
                    },
                    {
                        "id": 22,
                        "type_of_coop": "(P)Other Service Co-operative Society",
                        "abbreviation": "OSCS"
                    },
                    {
                        "id": 23,
                        "type_of_coop": "(P)Primary  Co-operative Agriculture and Rural Development Bank (PCARDB)",
                        "abbreviation": "PCAR"
                    },
                    {
                        "id": 24,
                        "type_of_coop": "(P)Primary Agricultural Co-operative Credit Society (PACS)",
                        "abbreviation": "PACS"
                    },
                    {
                        "id": 25,
                        "type_of_coop": "(P)Primary Agricultural Cooperative Marketing Society",
                        "abbreviation": "PACM"
                    },
                    {
                        "id": 26,
                        "type_of_coop": "(P)Primary Livestock Cooperative Society*",
                        "abbreviation": "PLCS"
                    },
                    {
                        "id": 27,
                        "type_of_coop": "(P)Primary Milk Producers’ Cooperative Society*",
                        "abbreviation": "PMPC"
                    },
                    {
                        "id": 28,
                        "type_of_coop": "(P)Primary Women’s Milk Producers’ Cooperative Society*",
                        "abbreviation": "PWMC"
                    },
                    {
                        "id": 29,
                        "type_of_coop": "(P)Rice Mill Co-operative Society",
                        "abbreviation": "RMCS"
                    },
                    {
                        "id": 30,
                        "type_of_coop": "(P)Road Transport Co-operative Society",
                        "abbreviation": "RTCS"
                    },
                    {
                        "id": 31,
                        "type_of_coop": "(P)Rural Electrification Co-operative Society",
                        "abbreviation": "RECS"
                    },
                    {
                        "id": 32,
                        "type_of_coop": "(P)Self Help Group Co-operative Society",
                        "abbreviation": "SHGS"
                    },
                    {
                        "id": 33,
                        "type_of_coop": "(P)Silk Weavers’ Cooperative Society  *",
                        "abbreviation": "SWCS"
                    },
                    {
                        "id": 34,
                        "type_of_coop": "(P)Talgur-Khejurgur Producers’ Co-operative Society",
                        "abbreviation": "TPCS"
                    },
                    {
                        "id": 35,
                        "type_of_coop": "(P)Urban Co-operative Bank",
                        "abbreviation": "URCB"
                    },
                    {
                        "id": 36,
                        "type_of_coop": "(P)Urban Credit Co-operative Societies",
                        "abbreviation": "UCCS"
                    },
                    {
                        "id": 37,
                        "type_of_coop": "(P)Water Transport Co-operative Society",
                        "abbreviation": "WTCS"
                    },
                    {
                        "id": 38,
                        "type_of_coop": "(P)Weavers’ Cooperative Society  *",
                        "abbreviation": "WECS"
                    },
                    {
                        "id": 39,
                        "type_of_coop": "(P)Women’s Co-operative Bank",
                        "abbreviation": "WOCB"
                    },
                    {
                        "id": 40,
                        "type_of_coop": "(P)Women’s Credit Co-operative Societies Urban Credit Co-operative Societies",
                        "abbreviation": "WCCS"
                    },
                    {
                        "id": 41,
                        "type_of_coop": "(C)Central Agricultural Cooperative Marketing Society",
                        "abbreviation": "CACS"
                    },
                    {
                        "id": 42,
                        "type_of_coop": "(C)Central Co-operative Bank ",
                        "abbreviation": "CCOB"
                    },
                    {
                        "id": 43,
                        "type_of_coop": "(C)Central Engineers’ Co-operative Society",
                        "abbreviation": "CECS"
                    },
                    {
                        "id": 44,
                        "type_of_coop": "(C)Central Fishermen’s Cooperative Societies *",
                        "abbreviation": "CFCS"
                    },
                    {
                        "id": 45,
                        "type_of_coop": "(C)Central Milk Producers’ Co-operative Union",
                        "abbreviation": "CMPU"
                    },
                    {
                        "id": 46,
                        "type_of_coop": "(C)District / Range Co-operative Union",
                        "abbreviation": "DRCU"
                    },
                    {
                        "id": 47,
                        "type_of_coop": "(C)Wholesale Consumers’ Co-operative Society",
                        "abbreviation": "WHCC"
                    },
                    {
                        "id": 48,
                        "type_of_coop": "(A)State Agricultural Marketing Co-operative Federation",
                        "abbreviation": "SAMF"
                    },
                    {
                        "id": 49,
                        "type_of_coop": "(A)State Consumers’ Co-operative Federation",
                        "abbreviation": "SCCF"
                    },
                    {
                        "id": 50,
                        "type_of_coop": "(A)State Co-operative Agriculture and Rural Development Bank (SCARDB)",
                        "abbreviation": "SCAR"
                    },
                    {
                        "id": 51,
                        "type_of_coop": "(A)State Co-operative Bank",
                        "abbreviation": "SCOB"
                    },
                    {
                        "id": 52,
                        "type_of_coop": "(A)State Co-operative Federation",
                        "abbreviation": "SCOF"
                    },
                    {
                        "id": 53,
                        "type_of_coop": "(A)State Co-operative Union",
                        "abbreviation": "SCOU"
                    },
                    {
                        "id": 54,
                        "type_of_coop": "(A)State Engineers’ Co-operative Federation",
                        "abbreviation": "SECF"
                    },
                    {
                        "id": 55,
                        "type_of_coop": "(A)State Federation of Urban Co-operative Credit Societies and Banks",
                        "abbreviation": "SFCB"
                    },
                    {
                        "id": 56,
                        "type_of_coop": "(A)State Fishermen’s Cooperative Federation*",
                        "abbreviation": "SFCF"
                    },
                    {
                        "id": 57,
                        "type_of_coop": "(A)State Milk Producers’ Co-operative Union",
                        "abbreviation": "SMPC"
                    },
                    {
                        "id": 58,
                        "type_of_coop": "(A)State Silk Weavers’ Co-operative Federation",
                        "abbreviation": "SSWC"
                    },
                    {
                        "id": 59,
                        "type_of_coop": "(A)State Talgur-Khejurgur Co-operative Federation",
                        "abbreviation": "STKF"
                    },
                    {
                        "id": 60,
                        "type_of_coop": "(A)State Tribal Deve-lopment Co-operative Corporation*",
                        "abbreviation": "STDC"
                    },
                    {
                        "id": 61,
                        "type_of_coop": "(A)State Weavers’ Co-operative Society (Federation)",
                        "abbreviation": "SWCF"
                    }
                ],
                selected_template: 24,
                template_path: '/FORMAT_for_CENSUS_PACS.xlsx',
                publicPath: process.env.BASE_URL,
                files: [],
                uploading: false,
                stats: {
                    total: 0,
                    success: 0,
                    failed: 0,
                    done: 0,
                    percentage: 0
                }
            }
        },
        methods: {
            handleFileChange() {
                this.files     = this.$refs.file.files;
                this.stats     = {
                    total: this.files.length,
                    success: 0,
                    failed: 0,
                    done: 0
                }
            },
            async upload() {
                if(this.files.length > 0) {
                    this.uploading = true;
                    for(let i = 0; i < this.files.length; i++) {
                        let form = new FormData();
                        form.append('file', this.files[i]);
                        try {
                            await this.$api.post('/coops/upload', form);
                            this.file = null;
                            this.stats.success++;
                        } catch(e) {
                            console.log(e);
                            this.stats.failed++;
                        }
                        this.stats.done++;
                        this.stats.percentage = Math.floor(this.stats.done * 100 / this.stats.total);
                    }
                    if(this.stats.success > 0) {
                        this.$toast.success(this.$filters.interpolate(this.$t('toastr.upload-file-msg'), {success: this.stats.success}));
                        await this.$router.push({name: 'jobs'});
                    }
                    if(this.stats.failed > 0) {
                        this.$toast.error(this.$filters.interpolate(this.$t('toastr.upload-file-err-msg'), {success: this.stats.success}));
                    }
                }
            },
            reset() {
                this.files     = [];
                this.uploading = false;
                this.stats     = {
                    total: 0,
                    success: 0,
                    failed: 0,
                    done: 0
                };
            },
            downloadTemplate() {
                const link = document.createElement('a');
                link.href = '/FORMAT_for_CENSUS_PACS.xlsx';
                link.download = 'FORMAT_for_CENSUS_PACS.xlsx';
                link.click();
            }
        }
    }
</script>

<style scoped>
    a.disabled {
        pointer-events: none;
        cursor: default;
    }
</style>