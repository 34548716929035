<template>
    <job-list-template :header="$t('page.job.card_label.pending_jobs')" status="Pending">
        <template v-slot:table="{jobs,get_jobs}">
            <table class="table mg-b-0">
                <thead class="thead-light">
                <tr>
                    <th>{{$t('page.job.file')}}</th>
                    <th class="text-center">{{$t('page.job.done')}}</th>
                    <th class="text-center">{{$t('page.job.deleted')}}</th>
                    <th>{{$t('page.job.created_by')}}</th>
                    <th>{{$t('page.job.created_at')}}</th>
                    <th>{{$t('page.job.updated_at')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="job in jobs" :key="job.job_id">
                    <td>{{ $filters.filename(job.file_name) }}</td>
                    <td class="text-center">{{job.is_done?$t('page.job.yes'):$t('page.job.no')}}</td>
                    <td class="text-center">{{job.is_file_deleted?$t('page.job.yes'):$t('page.job.no')}}</td>
                    <td>{{ job.created_by_name }}</td>
                    <td>{{ $filters.date(job.created_at)}}</td>
                    <td>{{ $filters.date(job.updated_at)}}</td>
                </tr>
                </tbody>
            </table>
        </template>
    </job-list-template>
</template>

<script>

import JobListTemplate from './JobListTemplate.vue';

export default {
  name: "PendingList",
  components: { JobListTemplate },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>
</style>