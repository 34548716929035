<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card card-dashboard-twelve">
                <div class="card-header">
                    <div class="row">
                        <div class="col-6 text-left">
                            <h5 class="card-title">Available Range Officers</h5>
                        </div>
                        <div class="col-6 text-right">
                            <router-link :to="{name: 'add-approver-to-range'}" class="btn btn-outline-primary" title="Add Range Officer"><i class="fas fa-plus-square mg-r-10"></i>Add Range Officer
                            </router-link>
                        </div>
                    </div>
                    <div class="row align-items-center mg-b-10">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">Select Range</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <Field as="select" class="form-control" name="range"
                                   rules="required" v-model="selected_range_for_approver">
                                <option :value="null">Select a range</option>
                                <option :value="range.range_id" v-for="range in ranges">{{range.name}}</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-hover mg-b-0" v-if="approvers.length>0">
                        <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Name</th>
                            <th class="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" class="pointer" v-for="(approver, index) in approvers">
                            <td>{{index+1}}</td>
                            <td>{{approver.email}}</td>
                            <td>{{approver.first_name}} {{approver.last_name}}</td>
                            <td class="text-center" style="cursor: auto">
                                <a @click="setApproverToRemove(approver)" data-target="#delModal" data-toggle="modal" href="javascript:void(0);">
                                    <i class="fa fa-trash-alt text-danger"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h5 class="text-center text-muted m-5" v-else>
                        No Range Officers Found
                    </h5>
                </div>
            </div>

            <div class="card card-dashboard-twelve mg-t-50">
                <div class="card-header">
                    <div class="row">
                        <div class="col-6 text-left">
                            <h5 class="card-title">Available CI Users</h5>
                        </div>
                        <div class="col-6 text-right">
                            <router-link :to="{name: 'add-clerk-to-range'}" class="btn btn-outline-primary" title="Add Range Officer"><i class="fas fa-plus-square mg-r-10"></i>Add CI User
                            </router-link>
                        </div>
                    </div>
                    <div class="row align-items-center mg-b-10">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">Select Range</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <Field as="select" class="form-control" name="range"
                                   rules="required" v-model="selected_range_for_clerk">
                                <option :value="null">Select a range</option>
                                <option :value="range.range_id" v-for="range in ranges">{{range.name}}</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-hover mg-b-0" v-if="clerks.length>0">
                        <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Name</th>
                            <th class="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" class="pointer" v-for="(clerk, index) in clerks">
                            <td>{{index+1}}</td>
                            <td>{{clerk.email}}</td>
                            <td>{{clerk.first_name}} {{clerk.last_name}}</td>
                            <td class="text-center" style="cursor: auto">
                                <a @click="setClerkToRemove(clerk)" data-target="#delClerkModal" data-toggle="modal" href="javascript:void(0);">
                                    <i class="fa fa-trash-alt text-danger"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h5 class="text-center text-muted m-5" v-else>
                        No CI Users Found
                    </h5>
                </div>
                <div class="card-footer">
                    <pagination v-if="clerks.length > 0" :page="page" :total="total" @pager-change="handlePagerChange"/>
                </div>
            </div>

            <div class="card card-dashboard-twelve mg-t-50">
                <div class="card-header">
                    <div class="row">
                        <div class="col-6 text-left">
                            <h5 class="card-title">Available Zone Officers</h5>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-hover mg-b-0" v-if="zone_officers.length>0">
                        <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Ranges</th>
                            <th class="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" class="pointer" v-for="(zone_officer, index) in zone_officers">
                            <td>{{index+1}}</td>
                            <td>{{zone_officer.email}}</td>
                            <td>{{zone_officer.first_name}} {{zone_officer.last_name}}</td>
                            <td>
                                <ul>
                                    <li v-for="range in zone_officer.ranges">{{range}}</li>
                                </ul>
                            </td>
                            <td class="text-center" style="cursor: auto">
                                <a @click="openModal(zone_officer, true, false)" href="javascript:void(0);">
                                    <i class="fa fa-lg fa-folder-plus text-primary"/>
                                </a>
                                <a @click="openRemoveRangeModal(zone_officer, true, false)" href="javascript:void(0);">
                                    <i class="fa fa-lg fa-folder-minus text-danger ml-3"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h5 class="text-center text-muted m-5" v-else>
                        No Zone Officers Found
                    </h5>
                </div>
            </div>

            <div class="card card-dashboard-twelve mg-t-50">
                <div class="card-header">
                    <div class="row">
                        <div class="col-6 text-left">
                            <h5 class="card-title">Available MIS Users</h5>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-hover mg-b-0" v-if="mis_users.length>0">
                        <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Ranges</th>
                            <th class="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" class="pointer" v-for="(mis_user, index) in mis_users">
                            <td>{{index+1}}</td>
                            <td>{{mis_user.email}}</td>
                            <td>{{mis_user.first_name}} {{mis_user.last_name}}</td>
                            <td>
                                <ul>
                                    <li v-for="range in mis_user.ranges">{{range}}</li>
                                </ul>
                            </td>
                            <td class="text-center" style="cursor: auto">
                                <a @click="openModal(mis_user, false, true)" href="javascript:void(0);">
                                    <i class="fa fa-lg fa-folder-plus text-primary"/>
                                </a>
                                <a @click="openRemoveRangeModal(mis_user, false, true)" href="javascript:void(0);">
                                    <i class="fa fa-lg fa-folder-minus text-danger ml-3"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h5 class="text-center text-muted m-5" v-else>
                        No MIS Users Found
                    </h5>
                </div>
            </div>
        </div>

        <div aria-hidden="true" class="modal fade" id="delModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remove Range Officer
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        You are about to remove this range officer. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            No
                        </button>
                        <button @click="removeApprover()" class="btn btn-primary" type="button">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div aria-hidden="true" class="modal fade" id="delClerkModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remove CI/Block User
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        You are about to remove this CI user. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            No
                        </button>
                        <button @click="removeClerk()" class="btn btn-primary" type="button">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div aria-hidden="true" class="modal fade" id="assignRangeModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Assign Range
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <Field as="select" class="form-control" name="range"
                                   rules="required" v-model="assign_range_json.range_id">
                                <option :value="null">Select a range</option>
                                <option :value="range.range_id" v-for="range in available_ranges_for_zone_officer">{{range.name}}</option>
                            </Field>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="assignRanges()" class="btn btn-primary" type="button">
                            Add
                        </button>
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div aria-hidden="true" class="modal fade" id="unassignRangeModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remove Zone Officer from Range
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <Field as="select" class="form-control" name="range"
                                   rules="required" v-model="unassign_range_json.range_id">
                                <option :value="null">Select a range</option>
                                <option :value="range.range_id" v-for="range in available_ranges_for_unassign">{{range.name}}</option>
                            </Field>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="unAssignRanges()" class="btn btn-danger" type="button">
                            Remove
                        </button>
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import Pagination from "../Pagination";

    export default {
        name: "UserManagement",
        watch: {
            selected_range_for_approver: function (n, o) {
                if (n !== o) {
                    this.getApprovers(n)
                }
            },
            selected_range_for_clerk: function (n, o) {
                if (n !== o) {
                    this.page = 0;
                    this.getClerks(n)
                }
            }
        },
        components: {Pagination, Form, Field},
        data() {
            return {
                page: 0,
                perPage: localStorage.getItem('pageSize') || 10,
                total: 0,
                selected_range_for_approver: null,
                selected_range_for_clerk: null,
                ranges: [],
                approvers: [],
                clerks: [],
                approver_id_to_remove: null,
                clerk_id_to_remove: null,
                zone_officers: [],
                mis_users: [],
                available_ranges_for_zone_officer: [],
                available_ranges_for_unassign: [],
                assign_range_json: {
                    user_id: null,
                    range_id: null
                },
                unassign_range_json: {
                    user_id: null,
                    range_id: null
                },
                isZoneModal: false,
                isMisModal: false
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                await this.getRanges();
                await Promise.all([
                    this.getApprovers(this.selected_range_for_approver),
                    this.getClerks(this.selected_range_for_clerk),
                    this.getZoneOfficers(),
                    this.getMisUsers()
                ])
            },
            async handlePagerChange(e) {
                this.page    = e.page;
                this.perPage = e.per_page;
                await this.getClerks(this.selected_range_for_clerk);
            },
            async getRanges() {
                try {
                    let {data} = await this.$api('coops/ranges');
                    this.ranges = data;
                    this.selected_range_for_approver = data.length > 0 ? data[0].range_id : null;
                    this.selected_range_for_clerk = data.length > 0 ? data[0].range_id : null;
                } catch (e) {

                }
            },
            async getApprovers(selected_range_for_approver) {
                if (selected_range_for_approver) {
                    try {
                        let {data} = await this.$api(`coops/ranges/${selected_range_for_approver}/approvers`);
                        this.approvers = data;
                    } catch (e) {

                    }
                }
            },
            async getClerks(selected_range_for_clerk) {
                if (selected_range_for_clerk) {
                    let params = {
                        page: this.page,
                        per_page: this.perPage
                    }
                    try {
                        let {data} = await this.$api(`coops/ranges/${selected_range_for_clerk}/clerks`, {
                            params: params
                        });
                        this.clerks = data.clerks;
                        this.total = data.total;
                    } catch (e) {

                    }
                }
            },
            async getZoneOfficers() {
                try {
                    let {data} = await this.$api(`coops/zone_officers`);
                    this.zone_officers = data;
                } catch (e) {

                }
            },
            async getMisUsers() {
                try {
                    let {data} = await this.$api(`coops/mis_users`);
                    this.mis_users = data;
                } catch (e) {

                }
            },
            openModal(zone_officer, isZoneModal, isMisModal) {
                this.isZoneModal = isZoneModal;
                this.isMisModal = isMisModal;
                this.assign_range_json.user_id = zone_officer.user_id;
                this.available_ranges_for_zone_officer = this.ranges.filter(r => !zone_officer.ranges.includes(r.name));
                $('#assignRangeModal').modal('show');
            },
            openRemoveRangeModal(zone_officer, isZoneModal, isMisModal) {
                this.isZoneModal = isZoneModal;
                this.isMisModal = isMisModal;
                this.unassign_range_json.user_id = zone_officer.user_id;
                this.available_ranges_for_unassign = this.ranges.filter(r => zone_officer.ranges.includes(r.name));
                $('#unassignRangeModal').modal('show');
            },
            async assignRanges() {
                try {
                    await this.$api.post(`/coops/assign_range_to_zone_officer`, this.assign_range_json);
                    $('#assignRangeModal').modal('hide');
                    this.$toast.success('Range successfully assigned');
                    if(this.isZoneModal) {
                        await this.getZoneOfficers();
                    }else if(this.isMisModal){
                        await this.getMisUsers()
                    }
                    this.assign_range_json = {
                        user_id: null,
                        range_id: null
                    };
                    this.available_ranges_for_zone_officer = [];
                } catch (e) {

                }
            },
            async unAssignRanges() {
                try {
                    await this.$api.delete(`/coops/remove_zone_officer_from_range`, {
                        data: this.unassign_range_json
                    });
                    $('#unassignRangeModal').modal('hide');
                    this.$toast.success('Range successfully removed');
                    if(this.isZoneModal) {
                        await this.getZoneOfficers();
                    }else if(this.isMisModal){
                        await this.getMisUsers()
                    }
                    this.unassign_range_json = {
                        user_id: null,
                        range_id: null
                    };
                    this.available_ranges_for_unassign = [];
                } catch (e) {

                }
            },
            setApproverToRemove(approver) {
                this.approver_id_to_remove = approver.user_id;
            },
            async removeApprover() {
                if (this.selected_range_for_approver) {
                    try {
                        await this.$api.delete(`/auth/${this.approver_id_to_remove}/remove_approver_from_range`, {
                            data: {
                                range_id: this.selected_range_for_approver
                            }
                        });
                        $('#delModal').modal('hide');
                        this.$toast.success('Range officer successfully removed');
                        await this.init();
                    } catch (e) {

                    }
                }
            },
            setClerkToRemove(clerk) {
                this.clerk_id_to_remove = clerk.user_id;
            },
            async removeClerk() {
                if (this.selected_range_for_clerk) {
                    try {
                        await this.$api.delete(`/auth/${this.clerk_id_to_remove}/remove_clerk_from_range`, {
                            data: {
                                range_id: this.selected_range_for_clerk
                            }
                        });
                        $('#delClerkModal').modal('hide');
                        this.$toast.success('CI user successfully removed');
                        await this.init();
                    } catch (e) {

                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>