<template>
    <div class="card card-dashboard-twelve">
        <div class="card-header">
            <div class="row">
                <div class="col-6 text-left">
                    <h6 class="card-title">Manage Accessibility & Password of Range Officers, CI Users & Zone Officers</h6>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row mb-1">
                <div class="col-lg-4 col-md-4 mb-1">
                    <input type="text" class="form-control" v-model="search" placeholder="Search By Username or Name">
                </div><!-- col -->
                <div class="col-lg-1 col-md-4 mb-1">
                    <button type="button" class="btn btn-info w-100" @click="getApprovers">
                        {{$t('page.coop.button.search')}}
                    </button>
                </div><!-- col -->
                <div class="col-lg-1 col-md-4 mb-1">
                    <button type="button" class="btn btn-secondary w-100" @click="resetSearch">
                        {{$t('page.coop.button.reset')}}
                    </button>
                </div><!-- col -->
            </div>
            <table class="table mg-b-0" v-if="approvers_and_clerks.length>0">
                <thead class="thead-light">
                <tr>
                    <th>#</th>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr :key="index" class="pointer" v-for="(user, index) in approvers_and_clerks">
                    <td>{{index+1}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.first_name}} {{user.last_name}}</td>
                    <td class="text-center" style="cursor: auto">
                        <div class="row">
                            <div class="col-md-1">
                                <button @click="setUserIdToReset(user.user_id)" class="btn btn-info btn-icon rounded" data-target="#delModal" data-toggle="modal" title="Reset Password to default"><i
                                        class="typcn typcn-arrow-repeat"></i></button>
                            </div>
                            <div class="col-md-2">
                                <label class="switch">
                                    <input type="checkbox" :checked="user.current_status === 'Active'"  @click="toggleUserStatus(user.user_id, user.current_status)">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <h5 class="text-center text-muted m-5" v-else>
                No Range Officer or CI User Found
            </h5>
        </div>
        <div class="card-footer">
            <pagination v-if="approvers_and_clerks.length > 0" :page="page" :total="total" @pager-change="handlePagerChange"/>
        </div>

        <div aria-hidden="true" class="modal fade" id="delModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Reset Password</h5>
                    </div>
                    <div class="modal-body">
                        You are about to reset the password. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.<br>
                        The new Password is <span style="font-weight: bold; font-size: 18px">{{new_password}}</span><br><br>
                        <p class="text-warning">N.B. Its not recoverable. Please store and keep it safe</p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            No
                        </button>
                        <button @click="resetPass()" class="btn btn-primary" type="button">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "../Pagination";

    export default {
        name: "PasswordManagement",
        components: {Pagination},
        data() {
            return {
                approvers_and_clerks: [],
                approvers_and_clerk_ids: [],
                user_id_to_reset_pass: null,
                new_password: null,
                page: 0,
                perPage: localStorage.getItem('pageSize') || 10,
                total: 0,
                search: null
            }
        },
        mounted() {
            this.getApprovers()
        },
        methods: {
            async getApprovers() {
                try {
                    let params = {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search
                    }
                    let {data} = await this.$api(`auth/approvers_and_clerks`, {params: params});
                    this.approvers_and_clerks = data.clerks_and_officers;
                    this.total = data.total;
                } catch (e) {

                }
            },
            setUserIdToReset(user_id) {
                this.user_id_to_reset_pass = user_id;
                this.new_password = this.generatePassword();
            },
            generatePassword() {
                let length = 8,
                        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                        retVal = "";
                for (let i = 0, n = charset.length; i < length; ++i) {
                    retVal += charset.charAt(Math.floor(Math.random() * n));
                }
                return retVal;
            },
            async resetPass() {
                try {
                    await this.$api.post(`auth/reset_password_to_default`, {
                        user_id: this.user_id_to_reset_pass,
                        password: this.new_password
                    });
                    $('#delModal').modal('hide');
                    this.user_id_to_reset_pass = null;
                    this.new_password = null;
                    this.$toast.success('Password successfully reset');
                } catch (e) {

                }
            },
            async toggleUserStatus(user_id, status) {
                try {
                    await this.$api.post(`auth/enable_disable_user`, {
                        user_id: user_id,
                        status: status === 'Active' ? 'InActive' : 'Active'
                    });
                    // await this.getApprovers()
                }catch (e) {

                }
            },
            async handlePagerChange(e) {
                this.page    = e.page;
                this.perPage = e.per_page;
                await this.getApprovers();
            },
            resetSearch() {
                this.search = null;
                this.getApprovers()
            }
        }
    }
</script>

<style scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>