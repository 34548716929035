<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card-header border-bottom d-flex justify-content-between">
                <h4 class="card-title">General
                    <coop-status :status="coop.status"/>
                </h4>
                <h4>{{coop.assessment_year}}</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.range" :mandatory="true" :pointer="null" label="page.coop.range"/>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.municipality" :mandatory="true" :pointer="null" label="page.coop.municipality"/>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-12">
                        <display-field :data="coop.coop_society_type" label="page.coop.type_of_coop"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.basic.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.name" :mandatory="true" label="page.coop.name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.address" :mandatory="true" label="page.coop.address"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.class" label="page.coop.class"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.area" label="page.coop.area"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.registration.pointer')}} {{$t('page.coop.registration.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.registration.number" :mandatory="true" label="page.coop.registration_number"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.registration.date" :mandatory="true" label="page.coop.registration_date"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.registration.addon_number" label="page.coop.registration_addon_number"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.registration.addon_date" label="page.coop.registration_addon_date"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.members_and_voters.pointer')}} {{$t('page.coop.members_and_voters.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-12">
                        <h5 class="form-label tx-14 mb-2 ml-1"><span class="form-order">{{$t('page.coop.members.pointer')}}</span>{{$t('page.coop.members.text')}}</h5>
                        <div class="row ml-2">
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.total" :mandatory="true" label="page.coop.individual_voters"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.nominal" label="page.coop.nominal"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.self_help_groups" label="page.coop.members_groups"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.coop_societies" label="page.coop.members_societies"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mg-t-10">
                        <display-field :data="coop.members.delegates" class="ml-1" label="page.coop.delegates"/>
                    </div>

                    <div class="col-12 mg-t-10">
                        <h5 class="form-label tx-14 mb-2 ml-1"><span class="form-order">{{$t('page.coop.genders.pointer')}}</span>{{$t('page.coop.genders.text')}}</h5>
                        <div class="row ml-2">
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.male" label="page.coop.voters_male"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.female" label="page.coop.voters_female"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mg-t-10">
                        <h5 class="form-label tx-14 mb-2 ml-1"><span class="form-order">{{$t('page.coop.castes.pointer')}}</span>{{$t('page.coop.castes.text')}}</h5>
                        <div class="row ml-2">
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.general" label="page.coop.voters_general"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.sc" label="page.coop.voters_sc"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.st" label="page.coop.voters_st"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.obc" label="page.coop.voters_obc"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.members.individual_voters.minority" label="page.coop.voters_minority"/>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.employees.pointer')}} {{$t('page.coop.employees.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-12">
                        <display-field :data="coop.employees.total" class="ml-1" label="page.coop.employees_total"/>
                    </div>

                    <div class="col-12 mg-t-10">
                        <h5 class="form-label tx-14 mb-2 ml-1"><span class="form-order">{{$t('page.coop.employees_division.pointer')}}</span>{{$t('page.coop.employees_division.text')}}</h5>
                        <div class="row ml-2">
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.employees.permanent" label="page.coop.employees_permanent"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.employees.temporary" label="page.coop.employees_temporary"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.employees.casual" label="page.coop.employees_casual"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.employees.adhoc" label="page.coop.employees_adhoc"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mg-t-10">
                        <h5 class="form-label tx-14 mb-2 ml-1"><span class="form-order">{{$t('page.coop.employees_training.pointer')}}</span>{{$t('page.coop.employees_training.text')}}</h5>
                        <div class="row ml-2">
                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.employees.trained" :mandatory="true" label="page.coop.employees_trained"/>
                            </div>

                            <div class="col-lg-3 col-md-4 col-sm-12">
                                <display-field :data="coop.employees.untrained" :mandatory="true" label="page.coop.employees_untrained"/>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.current_status.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.management_status" :mandatory="true" label="page.coop.status_management"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.last_agm_held" label="page.coop.status_agm_date"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.last_election_held" label="page.coop.status_election_date"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.audit_completed" :mandatory="true" label="page.coop.status_audit_upto"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.official_email" label="page.coop.status_email"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.chairman.pointer')}} {{$t('page.coop.chairman.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.chairman.name" :mandatory="true" label="page.coop.chairman_name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.chairman.mobile" label="page.coop.chairman_mobile"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.chairman.email" label="page.coop.chairman_email"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.vice_chairman.pointer')}} {{$t('page.coop.vice_chairman.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.vice_chairman.name" label="page.coop.vice_chairman_name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.vice_chairman.mobile" label="page.coop.vice_chairman_mobile"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.vice_chairman.email" label="page.coop.vice_chairman_email"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.secretary.pointer')}} {{$t('page.coop.secretary.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.secretary.name" label="page.coop.secretary_name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.secretary.mobile" label="page.coop.secretary_mobile"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.secretary.email" label="page.coop.secretary_email"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.highest_employee.pointer')}} {{$t('page.coop.highest_employee.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.hde.name" label="page.coop.highest_employee_name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.hde.designation" label="page.coop.highest_employee_designation"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.hde.mobile" label="page.coop.highest_employee_mobile"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.hde.email" label="page.coop.highest_employee_email"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.panel_chairman.pointer')}} {{$t('page.coop.panel_chairman.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.panel_chairman.name" label="page.coop.panel_chairman_name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.panel_chairman.mobile" label="page.coop.panel_chairman_mobile"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.panel_chairman.email" label="page.coop.panel_chairman_email"/>
                    </div>
                </div>

                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.govt_nominee.pointer')}} {{$t('page.coop.govt_nominee.text')}}</span></h6>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.govt_nominee.name" label="page.coop.govt_nominee_name"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.govt_nominee.mobile" label="page.coop.govt_nominee_mobile"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.govt_nominee.email" label="page.coop.govt_nominee_email"/>
                    </div>
                </div>
                <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.no_of_employee_with_qualification.pointer')}} {{$t('page.coop.no_of_employee_with_qualification.text')}}</span>
                </h6>
                <div class="row ml-1">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <display-field :data="getTotalQualified()" label="page.coop.total_qualified"/>
                    </div>
                </div>
                <div class="row ml-1">
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.no_of_employee_with_diploma" label="page.coop.no_of_employee_with_diploma"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.no_of_employee_with_graduate" label="page.coop.no_of_employee_with_graduate"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.no_of_employee_with_certificate_training" label="page.coop.no_of_employee_with_certificate_training"/>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <display-field :data="coop.no_of_employee_with_madhyamik_equivalent" label="page.coop.no_of_employee_with_madhyamik_equivalent"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ConfirmationModal from "../../../common/ConfirmationModal";
    import HistoryModal from "../../../common/HistoryModal";
    import CoopStatus from "../../../common/CoopStatus";
    import DisplayField from "../../../common/DisplayField";

    export default {
        name: "General",
        props: ["coop"],
        components: {DisplayField, ConfirmationModal, CoopStatus, HistoryModal},
        computed: {
            ...mapGetters(["currentUser"])
        },
        mounted() {
            this.confirmationModal = this.$refs.confirmationModal;
            this.historyModal = this.$refs.historyModal;
        },
        methods: {
            navigate(index) {
                this.activeTab = index;
            },
            showHistory() {
                this.historyModal.show();
            },
            getTotalQualified() {
                if (this.coop) {
                    return Number(this.coop.no_of_employee_with_diploma) + Number(this.coop.no_of_employee_with_graduate) + Number(this.coop.no_of_employee_with_certificate_training) + Number(this.coop.no_of_employee_with_madhyamik_equivalent)
                } else {
                    return 0;
                }
            }
        }
    }
</script>

<style scoped>

</style>