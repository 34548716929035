<template>
    <div class="row row-sm">
        <div class="col-12">
<!--            <Form ref="form" v-slot="{errors}">-->
                    <div class="card-header border-bottom">
                        <h5 class="card-title">General</h5>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.coop.range.text')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field @change="getBlocks(coop.range)" as="select" name="range" rules="required"
                                       class="form-control" v-model="coop.range"
                                       :class="{ 'is-invalid' : errors.range }">
                                    <option :value="null">{{$t('page.coop.range.placeholder')}}</option>
                                    <option :value="range.range_id" v-for="range in ranges">{{range.name}}</option>
                                </Field>
                                <span class="invalid-feedback" v-if="errors.range">{{ errors.range }}</span>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold required">{{$t('page.coop.municipality.text')}}</label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="select" name="municipality" rules="required"
                                       class="form-control" v-model="coop.municipality"
                                       :class="{ 'is-invalid' : errors.municipality }">
                                    <option :value="null">{{$t('page.coop.municipality.placeholder')}}</option>
                                    <option :value="block.name" v-for="block in blocks">{{block.name}}</option>
                                </Field>
                                <span class="invalid-feedback" v-if="errors.municipality">{{ errors.municipality }}</span>
                            </div>
                        </div>
                        <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.basic.text')}}</span></h6>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.name.pointer')}}</span>{{$t('page.coop.name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="name" rules="required"
                                       class="form-control" v-model="coop.name"
                                       :class="{ 'is-invalid' : errors.name }"
                                       :placeholder="$t('page.coop.name.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.name">{{ errors.name}}</span>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.address.pointer')}}</span>{{$t('page.coop.address.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="textarea" name="address" rules="required"
                                       class="form-control" rows="3" v-model="coop.address"
                                       :class="{ 'is-invalid' : errors.address }"
                                       :placeholder="$t('page.coop.address.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.address">{{ errors.address }}</span>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.class.pointer')}}</span>{{$t('page.coop.class.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="select"
                                       name="class"
                                       class="form-control"
                                       v-model="coop.class"
                                       :class="{ 'is-invalid' : errors.class }">
                                    <option :value="null">{{$t('page.coop.class.placeholder')}}</option>
                                    <option :value="classes" v-for="classes in society ">{{classes}}</option>
                                </Field>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.area.pointer')}}</span>{{$t('page.coop.area.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="area" class="form-control"
                                       v-model="coop.area" :placeholder="$t('page.coop.area.placeholder')"/>
                            </div>
                        </div>
                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.registration.pointer')}} {{$t('page.coop.registration.text')}}
                            </span>
                        </h6>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.registration_number.pointer')}}</span>{{$t('page.coop.registration_number.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="registration_number" rules="required"
                                       class="form-control" v-model="coop.registration.number"
                                       :class="{ 'is-invalid' : errors.registration_number }"
                                       :placeholder="$t('page.coop.registration_number.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.registration_number">{{ errors.registration_number }}</span>
                            </div>
                        </div>
                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.registration_date.pointer')}}</span>{{$t('page.coop.registration_date.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="date" name="registration_date" rules="required"
                                       class="form-control" v-model="coop.registration.date"
                                       :class="{ 'is-invalid' : errors.registration_date }"
                                       :placeholder="$t('page.coop.registration_date.pointer')"/>
                                <span class="invalid-feedback" v-if="errors.registration_date">{{ errors.registration_date }}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.registration_addon_number.pointer')}}</span>{{$t('page.coop.registration_addon_number.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="registration_addon_number" class="form-control"
                                       v-model="coop.registration.addon_number" :placeholder="$t('page.coop.registration_addon_number.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.registration_addon_date.pointer')}}</span>{{$t('page.coop.registration_addon_date.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="date" name="registration_addon_date" class="form-control"
                                       v-model="coop.registration.addon_date" :placeholder="$t('page.coop.registration_addon_date.placeholder')"/>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                              <span class="pl-0">
                                {{$t('page.coop.members_and_voters.pointer')}} {{$t('page.coop.members_and_voters.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.members.pointer')}}</span>{{$t('page.coop.members.text')}}
                                </label>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.individual_voters.pointer')}}</span>{{$t('page.coop.individual_voters.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="individual_voters" class="form-control"
                                       :class="{'is-invalid': errors.individual_voters }" rules="required|integer|min_value:0"
                                       v-model="coop.members.individual_voters.total"
                                       :placeholder="$t('page.coop.individual_voters.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.individual_voters">{{errors.individual_voters}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.nominal.pointer')}}</span>{{$t('page.coop.nominal.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="nominal" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.nominal }"
                                       v-model="coop.members.nominal" :placeholder="$t('page.coop.nominal.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.nominal">{{errors.nominal}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.members_groups.pointer')}}</span>{{$t('page.coop.members_groups.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="members_groups" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.members_groups }"
                                       v-model="coop.members.self_help_groups" :placeholder="$t('page.coop.members_groups.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.members_groups">{{errors.members_groups}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.members_societies.pointer')}}</span>{{$t('page.coop.members_societies.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="members_societies" class="form-control"
                                       :class="{'is-invalid': errors.members_societies }" rules="integer|min_value:0"
                                       v-model="coop.members.coop_societies" :placeholder="$t('page.coop.members_societies.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.members_societies">{{errors.members_societies}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10 mg-t-30">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.delegates.pointer')}}</span>{{$t('page.coop.delegates.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="delegates" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.delegates }"
                                       v-model="coop.members.delegates" :placeholder="$t('page.coop.delegates.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.delegates">{{errors.delegates}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10 mg-t-30">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.genders.pointer')}}</span>{{$t('page.coop.genders.text')}}
                                </label>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_male.pointer')}}</span>{{$t('page.coop.voters_male.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_male" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_male }"
                                       v-model="coop.members.individual_voters.male" :placeholder="$t('page.coop.voters_male.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_male">{{errors.voters_male}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_female.pointer')}}</span>{{$t('page.coop.voters_female.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_female" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_female }"
                                       v-model="coop.members.individual_voters.female" :placeholder="$t('page.coop.voters_male.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_female   ">{{errors.voters_female}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10 mg-t-30">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.castes.pointer')}}</span>{{$t('page.coop.castes.text')}}
                                </label>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_general.pointer')}}</span>{{$t('page.coop.voters_general.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_general" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_general }"
                                       v-model="coop.members.individual_voters.general" :placeholder="$t('page.coop.voters_general.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_general   ">{{errors.voters_general}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_sc.pointer')}}</span>{{$t('page.coop.voters_sc.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_sc" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_sc }"
                                       v-model="coop.members.individual_voters.sc" :placeholder="$t('page.coop.voters_sc.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_sc">{{errors.voters_sc}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_st.pointer')}}</span>{{$t('page.coop.voters_st.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_st" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_st }"
                                       v-model="coop.members.individual_voters.st" :placeholder="$t('page.coop.voters_st.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_st">{{errors.voters_st}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_obc.pointer')}}</span>{{$t('page.coop.voters_obc.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_obc" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_obc }"
                                       v-model="coop.members.individual_voters.obc" :placeholder="$t('page.coop.voters_obc.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_obc">{{errors.voters_obc}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.voters_minority.pointer')}}</span>{{$t('page.coop.voters_minority.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="voters_minority" rules="integer|min_value:0"
                                       class="form-control" :class="{'is-invalid': errors.voters_minority }"
                                       v-model="coop.members.individual_voters.minority" :placeholder="$t('page.coop.voters_minority.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.voters_minority">{{errors.voters_minority}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                              <span class="pl-0">
                                {{$t('page.coop.employees.pointer')}} {{$t('page.coop.employees.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.employees_total.pointer')}}</span>{{$t('page.coop.employees_total.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_total" :rules="employeeTotalValidation"
                                       class="form-control" :class="{'is-invalid': errors.employees_total }"
                                       v-model="coop.employees.total" :placeholder="$t('page.coop.employees_total.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_total">{{errors.employees_total}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10 mg-t-30">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.employees_division.pointer')}}</span>{{$t('page.coop.employees_division.text')}}
                                </label>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.employees_permanent.pointer')}}</span>{{$t('page.coop.employees_permanent.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_permanent" rules="integer|min_value:0" @input="calculateMaxLimOfTotalEmp()"
                                       class="form-control" :class="{'is-invalid': errors.employees_permanent }"
                                       v-model="coop.employees.permanent"
                                       :placeholder="$t('page.coop.employees_permanent.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_permanent">{{errors.employees_permanent}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.employees_temporary.pointer')}}</span>{{$t('page.coop.employees_temporary.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_temporary" rules="integer|min_value:0" @input="calculateMaxLimOfTotalEmp()"
                                       class="form-control" :class="{'is-invalid': errors.employees_temporary }"
                                       v-model="coop.employees.temporary"
                                       :placeholder="$t('page.coop.employees_temporary.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_temporary">{{errors.employees_temporary}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.employees_casual.pointer')}}</span>{{$t('page.coop.employees_casual.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_casual" rules="integer|min_value:0" @input="calculateMaxLimOfTotalEmp()"
                                       class="form-control" :class="{'is-invalid': errors.employees_casual }"
                                       v-model="coop.employees.casual"
                                       :placeholder="$t('page.coop.employees_casual.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_casual">{{errors.employees_casual}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.employees_adhoc.pointer')}}</span>{{$t('page.coop.employees_adhoc.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_adhoc" rules="integer|min_value:0|max_value:500"
                                       class="form-control" :class="{'is-invalid': errors.employees_adhoc }"
                                       v-model="coop.employees.adhoc"
                                       :placeholder="$t('page.coop.employees_adhoc.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_adhoc">{{errors.employees_adhoc}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10 mg-t-30">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label font-weight-bold">
                                    <span class="form-order">{{$t('page.coop.employees_training.pointer')}}</span>{{$t('page.coop.employees_training.text')}}
                                </label>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.employees_trained.pointer')}}</span>{{$t('page.coop.employees_trained.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_trained" rules="required|integer|min_value:0"  @input="calculateTotalEmp()"
                                       class="form-control" v-model="coop.employees.trained"
                                       :class="{'is-invalid': errors.employees_trained }"
                                       :placeholder="$t('page.coop.employees_trained.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_trained">{{errors.employees_trained}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.employees_untrained.pointer')}}</span>{{$t('page.coop.employees_untrained.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="number" name="employees_untrained" rules="required|integer|min_value:0"  @input="calculateTotalEmp()"
                                       class="form-control" v-model="coop.employees.untrained"
                                       :class="{'is-invalid': errors.employees_untrained }"
                                       :placeholder="$t('page.coop.employees_untrained.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.employees_untrained">{{errors.employees_untrained}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left"><span class="pl-0">{{$t('page.coop.current_status.text')}}</span></h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.status_management.pointer')}}</span>{{$t('page.coop.status_management.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="select" name="status_management" class="form-control" rules="required"
                                       v-model="coop.management_status"
                                       :class="{'is-invalid': errors.status_management }">
                                    <option :value="null">{{$t('page.coop.status_management.placeholder')}}</option>
                                    <option :value="status" v-for="status of managementStatuses">{{status}}</option>
                                </Field>
                                <span class="invalid-feedback" v-if="errors.status_management">{{errors.status_management}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.status_agm_date.pointer')}}</span>{{$t('page.coop.status_agm_date.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="date" name="status_agm_date" class="form-control"
                                       v-model="coop.last_agm_held"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.status_election_date.pointer')}}</span>{{$t('page.coop.status_election_date.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="date" name="status_election_date" class="form-control"
                                       v-model="coop.last_election_held"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.status_audit_upto.pointer')}}</span>{{$t('page.coop.status_audit_upto.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="select" name="status_audit_upto" class="form-control" rules="required"
                                       v-model="coop.audit_completed"
                                       :class="{'is-invalid': errors.status_audit_upto }">
                                    <option :value="null">{{$t('page.coop.status_audit_upto.placeholder')}}</option>
                                    <option :value="year" v-for="year of auditYears">{{year}}</option>
                                </Field>
                                <span class="invalid-feedback" v-if="errors.status_audit_upto">{{errors.status_audit_upto}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.status_email.pointer')}}</span>{{$t('page.coop.status_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="email" name="status_email" rules="email"
                                       :class="{'is-invalid': errors.status_email }"
                                       class="form-control" v-model="coop.official_email"
                                       :placeholder="$t('page.coop.status_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.status_email">{{errors.status_email}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.chairman.pointer')}} {{$t('page.coop.chairman.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label required">
                                    <span class="form-order">{{$t('page.coop.chairman_name.pointer')}}</span>{{$t('page.coop.chairman_name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="chairman_name" class="form-control" rules="required"
                                       v-model="coop.chairman.name"
                                       :class="{'is-invalid': errors.chairman_name}"
                                       :placeholder="$t('page.coop.chairman_name.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.chairman_name">{{errors.chairman_name}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.chairman_mobile.pointer')}}</span>{{$t('page.coop.chairman_mobile.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="chairman_mobile" class="form-control"
                                       v-model="coop.chairman.mobile" rules="digits:10"
                                       :class="{'is-invalid': errors.chairman_mobile}"
                                       :placeholder="$t('page.coop.chairman_mobile.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.chairman_mobile">{{errors.chairman_mobile}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.chairman_email.pointer')}}</span>{{$t('page.coop.chairman_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="email" name="chairman_email" rules="email"
                                       class="form-control" v-model="coop.chairman.email"
                                       :class="{'is-invalid': errors.chairman_email}"
                                       :placeholder="$t('page.coop.chairman_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.chairman_email">{{errors.chairman_email}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.vice_chairman.pointer')}} {{$t('page.coop.vice_chairman.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.vice_chairman_name.pointer')}}</span>{{$t('page.coop.vice_chairman_name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="text" name="vice_chairman_name" class="form-control"
                                       v-model="coop.vice_chairman.name" :placeholder="$t('page.coop.vice_chairman_name.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.vice_chairman_mobile.pointer')}}</span>{{$t('page.coop.vice_chairman_mobile.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="vice_chairman_mobile" class="form-control"
                                       v-model="coop.vice_chairman.mobile" rules="digits:10"
                                       :class="{'is-invalid': errors.vice_chairman_mobile}"
                                       :placeholder="$t('page.coop.vice_chairman_mobile.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.vice_chairman_mobile">{{errors.vice_chairman_mobile}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.vice_chairman_email.pointer')}}</span>{{$t('page.coop.vice_chairman_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="vice_chairman_email" class="form-control"
                                       v-model="coop.vice_chairman.email" rules="email"
                                       :class="{'is-invalid': errors.vice_chairman_email}"
                                       :placeholder="$t('page.coop.vice_chairman_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.vice_chairman_email">{{errors.vice_chairman_email}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.secretary.pointer')}} {{$t('page.coop.secretary.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.secretary_name.pointer')}}</span>{{$t('page.coop.secretary_name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="text" name="secretary_name" class="form-control"
                                       v-model="coop.secretary.name" :placeholder="$t('page.coop.secretary_name.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.secretary_mobile.pointer')}}</span>{{$t('page.coop.secretary_mobile.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="secretary_mobile" class="form-control"
                                       v-model="coop.secretary.mobile" rules="digits:10"
                                       :class="{'is-invalid': errors.secretary_mobile}"
                                       :placeholder="$t('page.coop.secretary_mobile.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.secretary_mobile">{{errors.secretary_mobile}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.secretary_email.pointer')}}</span>{{$t('page.coop.secretary_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="secretary_email" class="form-control"
                                       v-model="coop.secretary.email" rules="email"
                                       :class="{'is-invalid': errors.secretary_email}"
                                       :placeholder="$t('page.coop.secretary_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.secretary_email">{{errors.secretary_email}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.highest_employee.pointer')}} {{$t('page.coop.highest_employee.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.highest_employee_name.pointer')}}</span>{{$t('page.coop.highest_employee_name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="text" name="highest_employee_name" class="form-control"
                                       v-model="coop.hde.name" :placeholder="$t('page.coop.highest_employee_name.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.highest_employee_designation.pointer')}}</span>{{$t('page.coop.highest_employee_designation.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="text" name="highest_employee_designation" class="form-control"
                                       v-model="coop.hde.designation" :placeholder="$t('page.coop.highest_employee_designation.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.highest_employee_mobile.pointer')}}</span>{{$t('page.coop.highest_employee_mobile.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="highest_employee_mobile" class="form-control"
                                       v-model="coop.hde.mobile" rules="digits:10"
                                       :class="{'is-invalid': errors.highest_employee_mobile}"
                                       :placeholder="$t('page.coop.highest_employee_mobile.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.highest_employee_mobile">{{errors.highest_employee_mobile}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.highest_employee_email.pointer')}}</span>{{$t('page.coop.highest_employee_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="highest_employee_email" class="form-control"
                                       v-model="coop.hde.email" rules="email"
                                       :class="{'is-invalid': errors.highest_employee_email}"
                                       :placeholder="$t('page.coop.highest_employee_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.highest_employee_email">{{errors.highest_employee_email}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.panel_chairman.pointer')}} {{$t('page.coop.panel_chairman.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.panel_chairman_name.pointer')}}</span>{{$t('page.coop.panel_chairman_name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="text" name="panel_chairman_name" class="form-control"
                                       v-model="coop.panel_chairman.name" :placeholder="$t('page.coop.panel_chairman_name.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.panel_chairman_mobile.pointer')}}</span>{{$t('page.coop.panel_chairman_mobile.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="panel_chairman_mobile" class="form-control"
                                       v-model="coop.panel_chairman.mobile" rules="digits:10"
                                       :class="{'is-invalid': errors.panel_chairman_mobile}"
                                       :placeholder="$t('page.coop.panel_chairman_mobile.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.panel_chairman_mobile">{{errors.panel_chairman_mobile}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.panel_chairman_email.pointer')}}</span>{{$t('page.coop.panel_chairman_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="panel_chairman_email" class="form-control"
                                       v-model="coop.panel_chairman.email" rules="email"
                                       :class="{'is-invalid': errors.panel_chairman_email}"
                                       :placeholder="$t('page.coop.panel_chairman_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.panel_chairman_email">{{errors.panel_chairman_email}}</span>
                            </div>
                        </div>

                        <h6 class="text-divider text-left">
                            <span class="pl-0">
                                {{$t('page.coop.govt_nominee.pointer')}} {{$t('page.coop.govt_nominee.text')}}
                            </span>
                        </h6>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.govt_nominee_name.pointer')}}</span>{{$t('page.coop.govt_nominee_name.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" type="text" name="govt_nominee_name" class="form-control"
                                       v-model="coop.govt_nominee.name" :placeholder="$t('page.coop.govt_nominee_name.placeholder')"/>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.govt_nominee_mobile.pointer')}}</span>{{$t('page.coop.govt_nominee_mobile.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="govt_nominee_mobile" class="form-control"
                                       v-model="coop.govt_nominee.mobile" rules="digits:10"
                                       :class="{'is-invalid': errors.govt_nominee_mobile}"
                                       :placeholder="$t('page.coop.govt_nominee_mobile.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.govt_nominee_mobile">{{errors.govt_nominee_mobile}}</span>
                            </div>
                        </div>

                        <div class="row align-items-center mg-b-10">
                            <div class="col-sm-12 col-md-4 col-lg-3">
                                <label class="form-label">
                                    <span class="form-order">{{$t('page.coop.govt_nominee_email.pointer')}}</span>{{$t('page.coop.govt_nominee_email.text')}}
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-6">
                                <Field as="input" name="govt_nominee_email" class="form-control"
                                       v-model="coop.govt_nominee.email" rules="email"
                                       :class="{'is-invalid': errors.govt_nominee_email}"
                                       :placeholder="$t('page.coop.govt_nominee_email.placeholder')"/>
                                <span class="invalid-feedback" v-if="errors.govt_nominee_email">{{errors.govt_nominee_email}}</span>
                            </div>
                        </div>
                    </div>
<!--                </div>&lt;!&ndash; card &ndash;&gt;-->
<!--            </Form>-->
        </div><!-- col -->
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import ranges from "../../../assets/data/ranges.json";
    import society from "../../../assets/data/society.json";
    import managementStatuses from "../../../assets/data/managementStatuses.json";

    export default {
        name: "AddGeneral",
        components: {Form, Field},
        props: ["coop", "ranges"],
        data() {
            return {
                society: society,
                managementStatuses: managementStatuses,
                auditYears: [],
                maxLimOfTotalEmp: 0,
                blocks: []
            }
        },
        mounted() {
            this.$parent.$parent.title = this.$t('page.coop.page_label.add');
            this.getAuditYear();
            // this.$parent.coop = {
            //     "range": "Howrah",
            //     "municipality": "Kadamtala",
            //     "name": "Loha Banik",
            //     "address": "Kadamtala",
            //     "class": null,
            //     "area": null,
            //     "registration": {
            //         "number": "KD001",
            //         "date": "1999-10-17",
            //         "addon_number": null,
            //         "addon_date": null
            //     },
            //     "members": {
            //         "individual_voters": {
            //             "total": 12,
            //             "male": null,
            //             "female": null,
            //             "general": null,
            //             "sc": null,
            //             "st": null,
            //             "obc": null,
            //             "minority": null
            //         },
            //         "nominal": null,
            //         "self_help_groups": null,
            //         "coop_societies": null,
            //         "delegates": null
            //     },
            //     "employees": {
            //         "total": null,
            //         "permanent": null,
            //         "temporary": null,
            //         "casual": null,
            //         "adhoc": null,
            //         "trained": 12,
            //         "untrained": 0
            //     },
            //     "management_status": "Elected",
            //     "last_agm_held": null,
            //     "last_election_held": null,
            //     "audit_completed": "2014-2015",
            //     "official_email": null,
            //     "chairman": {
            //         "name": "Kashinath Das",
            //         "designation": null,
            //         "mobile": null,
            //         "email": null
            //     },
            //     "vice_chairman": {
            //         "name": "",
            //         "designation": null,
            //         "mobile": null,
            //         "email": null
            //     },
            //     "secretary": {
            //         "name": null,
            //         "designation": null,
            //         "mobile": null,
            //         "email": null
            //     },
            //     "hde": {
            //         "name": null,
            //         "designation": null,
            //         "mobile": null,
            //         "email": null
            //     },
            //     "panel_chairman": {
            //         "name": null,
            //         "designation": null,
            //         "mobile": null,
            //         "email": null
            //     },
            //     "govt_nominee": {
            //         "name": null,
            //         "designation": null,
            //         "mobile": null,
            //         "email": null
            //     }
            // }
        },
        methods: {
            async getBlocks(range_id) {
                if(range_id) {
                    this.blocks = [];
                    try {
                        let {data} = await this.$api.get(`/coops/ranges/${range_id}/blocks`);
                        this.blocks = data;
                    }catch (e) {

                    }
                }
            },
            getAuditYear() {
                let d     = new Date();
                let end   = d.getFullYear();
                let start = 2000;
                while(start < end) {
                    this.auditYears.push(`${start}-${(++start)}`)
                }
            },
            calculateTotalEmp() {
                this.coop.employees.total = Number(this.coop.employees.trained) + Number(this.coop.employees.untrained);
            },
            calculateMaxLimOfTotalEmp() {
                this.maxLimOfTotalEmp = Number(this.coop.employees.permanent) + Number(this.coop.employees.casual) + Number(this.coop.employees.temporary);
            },
            employeeTotalValidation(value) {
                value = Number(value);
                const regex = /^[1-9]\d*$/;
                if (!regex.test(value)) {
                    return 'Not a valid number';
                }else if(value <1){
                    return 'This field should be greater than 0'
                }else if(this.maxLimOfTotalEmp > 0 && (value>this.maxLimOfTotalEmp || value<this.maxLimOfTotalEmp)){
                    return 'This field should be equal to the sum of number of "Permanent", "Temporary" & "Casual / Contingent" employees.'
                }else if(value > 500){
                    return 'This field should be less than 500'
                }
                return true;
            }
        }
    }
</script>

<style scoped>

</style>