<template>
    <div class="row row-sm">
        <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div class="card card-stat">
                <div class="card-header">
                    <h5 class="card-title">{{$t('page.dashboard.card_1_title')}}</h5>
                    <span class="card-text">{{$t('page.dashboard.card_1_subtitle')}}</span>
                </div>

                <div class="card-body">
                    <h1><i class="fa fa-users text-primary"/> {{coops.total}}</h1>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div class="card card-stat">
                <div class="card-header card-h">
                    <h5 class="card-title">{{$t('page.dashboard.card_2_title')}}</h5>
                    <span class="card-text">{{$t('page.dashboard.card_2_subtitle')}}</span>
                </div>
                <div class="card-body">
                    <h1><i class="fa fa-thumbs-up text-success"/> {{coops.approved}}</h1>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div class="card card-stat">
                <div class="card-header card-h">
                    <h5 class="card-title">{{$t('page.dashboard.card_3_title')}}</h5>
                    <span class="card-text">{{$t('page.dashboard.card_3_subtitle')}}</span>
                </div>
                <div class="card-body">
                    <h1><i class="fa fa-clock text-warning"/> {{coops.pending}}</h1>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div class="card card-stat">
                <div class="card-header card-h">
                    <h5 class="card-title">{{$t('page.dashboard.card_4_title')}}</h5>
                    <span class="card-text">{{$t('page.dashboard.card_4_subtitle')}}</span>
                </div>
                <div class="card-body">
                    <h1><i class="fa fa-cogs text-primary"/> {{jobs.total}}</h1>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div class="card card-stat">
                <div class="card-header card-h">
                    <h5 class="card-title">{{$t('page.dashboard.card_5_title')}}</h5>
                    <span class="card-text">{{$t('page.dashboard.card_5_subtitle')}}</span>
                </div>
                <div class="card-body">
                    <h1><i class="fa fa-check-double text-success"/> {{jobs.processed}}</h1>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div class="card card-stat">
                <div class="card-header card-h">
                    <h5 class="card-title">{{$t('page.dashboard.card_6_title')}}</h5>
                    <span class="card-text">{{$t('page.dashboard.card_6_subtitle')}}</span>
                </div>
                <div class="card-body">
                    <h1><i class="fa fa-hourglass-half text-warning"/> {{jobs.pending}}</h1>
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <div aria-hidden="true" class="modal fade" id="delModal" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Terms and Conditions
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cursus mattis molestie a iaculis at. Vitae purus
                            faucibus ornare suspendisse. Eget felis eget nunc lobortis mattis aliquam faucibus. Est lorem ipsum dolor sit. Sed blandit libero volutpat sed cras ornare arcu dui. Eu
                            volutpat odio facilisis mauris sit amet massa. In dictum non consectetur a erat nam at lectus. Curabitur vitae nunc sed velit dignissim sodales ut eu. Diam donec adipiscing
                            tristique risus nec feugiat in. Vitae semper quis lectus nulla at volutpat. Etiam erat velit scelerisque in dictum non consectetur a. Est ante in nibh mauris cursus mattis
                            molestie a. Pellentesque dignissim enim sit amet venenatis urna.</p>
                        <p>Risus commodo viverra maecenas accumsan lacus vel. Facilisis leo vel fringilla est ullamcorper eget nulla facilisi. Sit amet nisl suscipit adipiscing bibendum est. Senectus
                            et netus et malesuada fames ac turpis egestas integer. Quisque id diam vel quam elementum pulvinar etiam non. Euismod in pellentesque massa placerat duis. Pretium lectus
                            quam id leo. Gravida in fermentum et sollicitudin. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Malesuada fames ac turpis egestas integer eget
                            aliquet. Justo nec ultrices dui sapien eget mi proin.</p>
                        <p>Imperdiet nulla malesuada pellentesque elit eget gravida cum. Habitant morbi tristique senectus et netus et malesuada fames. Vehicula ipsum a arcu cursus. At in tellus
                            integer feugiat scelerisque varius morbi enim nunc. Diam phasellus vestibulum lorem sed risus ultricies tristique. Lacus sed viverra tellus in hac habitasse platea dictumst
                            vestibulum. Placerat orci nulla pellentesque dignissim enim sit amet venenatis. Pulvinar sapien et ligula ullamcorper malesuada proin libero nunc consequat. Ultricies leo
                            integer malesuada nunc vel risus commodo. Egestas pretium aenean pharetra magna ac placerat. Nibh mauris cursus mattis molestie. Ultricies lacus sed turpis tincidunt id
                            aliquet risus feugiat. Id donec ultrices tincidunt arcu non sodales neque sodales. At imperdiet dui accumsan sit. Nulla pellentesque dignissim enim sit. Nisl vel pretium
                            lectus quam id leo. Vulputate dignissim suspendisse in est ante in.</p>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button @click="acceptTermsAndCondition" class="btn btn-primary" data-dismiss="modal" type="button">
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Home",
        computed: {
            ...mapGetters(["termsAndConditionSeen"])
        },
        data() {
            return {
                coops: {
                    total: 0,
                    approved: 0,
                    pending: 0
                },
                jobs: {
                    total: 0,
                    processed: 0,
                    pending: 0
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                // await this.getTermsAndCondition();
                await Promise.all([
                    this.getCoopsCount(),
                    this.getPendingCoopsCount(),
                    this.getApprovedCoopsCount(),
                    this.getJobsCount(),
                    this.getPendingJobsCount(),
                    this.getProcessedJobsCount()
                ])
            },
            async getTermsAndCondition() {
                try {
                    let {data} = await this.$api.get('/auth/terms_and_conditions');
                    if (data && !data.is_accepted_terms_and_condition && !this.termsAndConditionSeen) {
                        $('#delModal').modal('show');
                        this.$store.commit('TERMS_AND_CONDITIONS_SEEN', true)
                    }
                } catch (e) {

                }
            },
            async getCoopsCount() {
                try {
                    let {data} = await this.$api('coops', {
                        params: {
                            page: 0,
                            per_page: 1
                        }
                    });
                    this.coops.total = data.total;
                } catch (e) {
                    console.error(e);
                }
            },
            async getPendingCoopsCount() {
                let statuses = ['Draft', 'AwaitingApproval', 'Revision'];
                try {
                    for (let i = 0; i < statuses.length; i++) {
                        let {data} = await this.$api('coops', {
                            params: {
                                page: 0,
                                per_page: 1,
                                status: statuses[i]
                            }
                        });
                        this.coops.pending = Number(this.coops.pending) + data.total;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            async getApprovedCoopsCount() {
                try {
                    let {data} = await this.$api('coops', {
                        params: {
                            page: 0,
                            per_page: 1,
                            status: 'Approved'
                        }
                    });
                    this.coops.approved = data.total;
                } catch (e) {

                }
            },
            async getJobsCount() {
                try {
                    let {data} = await this.$api('jobs', {
                        params: {
                            page: 0,
                            per_page: 1
                        }
                    });
                    this.jobs.total = data.total;
                } catch (e) {

                }
            },
            async getPendingJobsCount() {
                try {
                    let {data} = await this.$api('jobs', {
                        params: {
                            page: 0,
                            per_page: 1,
                            status: 'Pending'
                        }
                    });
                    this.jobs.pending = data.total;
                } catch (e) {

                }
            },
            async getProcessedJobsCount() {
                try {
                    let {data} = await this.$api('jobs', {
                        params: {
                            page: 0,
                            per_page: 1,
                            status: 'Completed',
                            result:'Failed'
                        }
                    });
                    this.jobs.processed = data.total;
                } catch (e) {

                }
            },
            async acceptTermsAndCondition() {
                try {
                    await this.$api.post('/auth/terms_and_conditions');
                    this.$store.commit('TERMS_AND_CONDITIONS_SEEN', true)
                } catch (e) {

                } finally {
                    $('#delModal').modal('hide');
                }
            }
        }
    }
</script>

<style scoped>
    .card-stat {
        height: 200px;
        position: relative;
    }

    .card-stat > .card-body {
        position: absolute;
        bottom: 0;
    }
</style>
