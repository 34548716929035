<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card card-dashboard-twelve">
                <div class="card-header border-bottom">
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="card-title">{{ $t('page.coop.card_label.available_coops') }}</h5>
                        </div>
                        <div class="col-md-6 text-right">
                            <CSVExport v-if="coops_for_export.length > 0" :data="coops_for_export"></CSVExport>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!--<h6>{{$t('page.coop.search_coop')}}</h6>-->

                    <div class="form-row mb-1">
                        <div class="col-lg-3 col-md-4 mb-1">
                            <select class="form-control" v-model="range" @change="getBlocks(range)">
                                <option :value="null">{{ $t('page.coop.range.placeholder') }}</option>
                                <option :value="range.range_id" v-for="range in ranges">{{ range.name }}</option>
                            </select>
                        </div><!-- col -->
                        <div class="col-lg-3 col-md-4 mb-1">
                            <select class="form-control" v-model="municipality">
                                <option :value="null">{{ $t('page.coop.municipality.placeholder') }}</option>
                                <option :value="block.name" v-for="block in blocks">{{ block.name }}</option>
                            </select>
                            <!--                            <input type="text" class="form-control" v-model="municipality" :placeholder="$t('page.coop.municipality.placeholder')">-->
                        </div><!-- col -->
                        <div class="col-lg-3 col-md-4 mb-1">
                            <select class="form-control flex-1" v-model="status">
                                <option :value="null">{{ $t('statuses.placeholder') }}</option>
                                <option value="Draft">{{ $t('statuses.draft') }}</option>
                                <option value="Cancelled">{{ $t('statuses.cancelled') }}</option>
                                <option value="AwaitingApproval">{{ $t('statuses.awaiting_approval') }}</option>
                                <option value="Revision">{{ $t('statuses.revision') }}</option>
                                <option value="Approved">{{ $t('statuses.approved') }}</option>
                                <option value="Rejected">{{ $t('statuses.rejected') }}</option>
                            </select>
                        </div><!-- col -->
                        <div class="col-lg-3 col-md-4 mb-1">
                            <div class="border d-flex justify-content-around form-control">
                                <label class="rdiobox mt-1">
                                    <input type="radio" name="validity" v-model="is_valid" :value="null"><span>{{
                                        $t('page.coop.all') }}</span>
                                </label>
                                <label class="rdiobox mt-1">
                                    <input type="radio" name="validity" v-model="is_valid" :value="true"><span>{{
                                        $t('page.coop.is_valid') }}</span>
                                </label>
                                <label class="rdiobox mt-1">
                                    <input type="radio" name="validity" v-model="is_valid" :value="false"><span>{{
                                        $t('page.coop.is_invalid') }}</span>
                                </label>
                            </div>
                        </div><!-- col -->
                        <div class="col-lg-3 col-md-8 mb-1">
                            <input type="text" class="form-control" v-model="search"
                                :placeholder="$t('page.coop.search_wildcard')">
                        </div><!-- col -->
                        <div class="col-lg-3 col-md-8 mb-1">
                            <Field as="select" class="form-control" name="template" rules="required"
                                v-model="selected_template">
                                <option :value="null">{{ $t('page.coop.coop_society_type.text') }}</option>
                                <option :value="template.type_of_coop" v-for="template in templates">
                                    {{ template.type_of_coop }}</option>
                            </Field>
                        </div>
                        <div class="col-lg-3 col-md-8 mb-1">
                            <input type="text" class="form-control" v-model="search_addon_number"
                                :placeholder="$t('page.coop.search_addon_number')">
                        </div><!-- col -->
                        <div class="col-lg-1 col-md-4 mb-1">
                            <button type="button" class="btn btn-info w-100" @click="searchCoop">
                                {{ $t('page.coop.button.search') }}
                            </button>
                        </div><!-- col -->
                        <div class="col-lg-1 col-md-4 mb-1">
                            <button type="button" class="btn btn-secondary w-100" @click="resetSearchCriteria">
                                {{ $t('page.coop.button.reset') }}
                            </button>
                        </div><!-- col -->
                        <div class="col-lg-1 col-md-4 mb-1">
                            <button type="button" class="btn btn-success w-100" :disabled="!is_valid"
                                @click="confirmSubmitAll">
                                {{ $t('page.coop.button.submit_all') }}
                            </button>
                        </div><!-- col -->
                    </div>

                    <div class="table-responsive" v-if="total > 0">
                        <table class="table table-hover mg-b-0">
                            <thead class="thead-light">
                                <tr>
                                    <!--                                <th>{{$t('page.coop.case_number.text')}}</th>-->
                                    <th>{{ $t('page.coop.coop_id.text') }}</th>
                                    <th>{{ $t('page.coop.registration_addon_number.text') }}</th>
                                    <th>{{ $t('page.coop.registration_number.text') }}</th>
                                    <th>{{ $t('page.coop.financial_year.text') }}</th>
                                    <th>{{ $t('page.coop.coop_society_type.text') }}</th>
                                    <th>{{ $t('page.coop.name.text') }}</th>
                                    <th>{{ $t('page.coop.range.text') }}</th>
                                    <th>{{ $t('page.coop.municipality.text') }}</th>
                                    <th>{{ $t('page.coop.created_by') }}</th>
                                    <th>{{ $t('page.coop.file_name') }}</th>
                                    <th>{{ $t('page.job.created_at') }}</th>
                                    <th class="text-center">{{ $t('page.coop.validity') }}</th>
                                    <th>{{ $t('page.coop.status') }}</th>
                                    <th>{{ $t('page.job.action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="coop in coops" class="pointer">
                                    <!--                                <td @click="viewCoop(coop)">{{getCaseId(coop)}}</td>-->
                                    <td @click="viewCoop(coop)">{{ coop.coop_human_id }}</td>
                                    <td @click="viewCoop(coop)">{{ coop.registration.addon_number }}</td>
                                    <td @click="viewCoop(coop)">{{ coop.registration ? coop.registration.number : null }}
                                    </td>
                                    <td @click="viewCoop(coop)">{{ coop.assessment_year }}</td>
                                    <td @click="viewCoop(coop)">{{ getCoopSocietyShortName(coop.coop_society_type) }}</td>
                                    <td @click="viewCoop(coop)">{{ coop.name }}</td>
                                    <td @click="viewCoop(coop)">{{ coop.range }}</td>
                                    <td @click="viewCoop(coop)">{{ coop.municipality }}</td>
                                    <td @click="viewCoop(coop)">{{ coop.created_by_name }}</td>
                                    <td v-if="coop.file_name" @click="viewCoop(coop)">{{ coop.file_name }}</td>
                                    <td @click="viewCoop(coop)" v-else>NA</td>
                                    <td @click="viewCoop(coop)">{{ $filters.date(coop.created_at) }}</td>
                                    <td @click="viewCoop(coop)" class="text-center">
                                        <i class="fa fa-check-circle text-success" v-if="coop.is_valid"
                                            :title="$t('page.coop.is_valid')" />
                                        <i class="fa fa-times-circle text-danger" v-else
                                            :title="$t('page.coop.is_invalid')" />
                                    </td>
                                    <td @click="viewCoop(coop)">
                                        <coop-status :status="coop.status" />
                                    </td>
                                    <td style="cursor: auto">
                                        <a href="javascript:void(0);" @click="viewHistory(coop.coop_id, coop.name)">
                                            <i class="fa fa-history mr-3" />
                                        </a>
                                        <a @click="coop_id_to_remove = coop.coop_id"
                                            v-if="coop.status === 'Draft' && !currentUser.roles.includes('ZoneOfficer')"
                                            href="javascript:void(0);" data-toggle="modal" data-target="#delModal">
                                            <i class="text-danger fa fa-trash-alt" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <template v-else>
                        <h5 class="text-center text-muted mt-5">
                            {{ $t('page.coop.not_found') }}
                        </h5>
                        <h6 class="text-center text-muted mb-5">
                            {{ $t('page.coop.not_found_sub') }}
                        </h6>
                    </template>
                </div>
                <div class="card-footer">
                    <pagination v-if="coops.length > 0" :page="page" :total="total" @pager-change="handlePagerChange" />
                </div>
            </div><!-- card -->
        </div><!-- col -->

        <confirmation-modal :header="header" :message="message" :status="status" :with-remarks="true"
            ref="confirmationModal" @confirm="submitAll" />
        <history-modal :coop-id="selectedCoopId" :history="history" :coop-name="selectedCoopName" ref="historyModal"
            @close="closeHistory" />

        <div aria-hidden="true" class="modal fade" id="delModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remove Co-operative
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        You are about to remove this <b>Co-operative</b>. Are you sure? Click <b>Yes</b> to proceed, click
                        <b>No</b> to cancel.
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            No
                        </button>
                        <button @click="removeCoop(coop_id_to_remove)" class="btn btn-primary" type="button">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CoopStatus from "../../common/CoopStatus";
import Pagination from "../../Pagination";
import ranges from "../../../assets/data/ranges.json";
import ConfirmationModal from "../../common/ConfirmationModal";
import HistoryModal from "../../common/HistoryModal";
import pad from "pad";
import { mapGetters } from "vuex";
import { Form, Field } from "vee-validate";
import CSVExport from "../../common/CSVExport.vue";
import moment from "moment";

export default {
    name: "List",
    components: { CoopStatus, Pagination, ConfirmationModal, HistoryModal, Form, Field, CSVExport },
    computed: {
        ...mapGetters(["currentUser"])
    },
    data() {
        return {
            coops: [],
            ranges: [],
            blocks: [],
            search: null,
            search_addon_number: null,
            range: null,
            municipality: null,
            status: null,
            is_valid: null,
            page: 0,
            perPage: localStorage.getItem('pageSize') || 10,
            total: 0,
            confirmationModal: null,
            header: null,
            message: null,
            selectedCoopId: null,
            selectedCoopName: null,
            historyModal: null,
            coop_id_to_remove: null,
            selected_template: null,
            templates: [
                {
                    "id": 1,
                    "type_of_coop": "(P)Bidi Makers’ Co-operative Society",
                    "abbreviation": "BMCS"
                },
                {
                    "id": 2,
                    "type_of_coop": "(P)Cold Storage Co-operative Society",
                    "abbreviation": "CSCS"
                },
                {
                    "id": 3,
                    "type_of_coop": "(P)Consumers’ Co-operative Store / Society",
                    "abbreviation": "CCSS"
                },
                {
                    "id": 4,
                    "type_of_coop": "(P)Employees’  Credit Cooperative Society ",
                    "abbreviation": "ECCS"
                },
                {
                    "id": 5,
                    "type_of_coop": "(P)Engineers’ Co-operative Society",
                    "abbreviation": "ENCS"
                },
                {
                    "id": 6,
                    "type_of_coop": "(P)Farmers’ Service Co-operative  Society (FSCS)",
                    "abbreviation": "FSCS"
                },
                {
                    "id": 7,
                    "type_of_coop": "(P)Fee Parking Co-operative Society",
                    "abbreviation": "FPCS"
                },
                {
                    "id": 8,
                    "type_of_coop": "(P)Fishermen’s  Cooperative Society *",
                    "abbreviation": "FICS"
                },
                {
                    "id": 9,
                    "type_of_coop": "(P)Fruit  & Vegetables Processing Co-operative Society",
                    "abbreviation": "FVPC"
                },
                {
                    "id": 10,
                    "type_of_coop": "(P)Hawkers’ Co-operative Society",
                    "abbreviation": "HACS"
                },
                {
                    "id": 11,
                    "type_of_coop": "(P)Horticulture & Plantation Cooperative Society*",
                    "abbreviation": "HPCS"
                },
                {
                    "id": 12,
                    "type_of_coop": "(P)Housing Co-operative Society ",
                    "abbreviation": "HOCS"
                },
                {
                    "id": 13,
                    "type_of_coop": "(P)Irrigation Cooperative Society",
                    "abbreviation": "IRCS"
                },
                {
                    "id": 14,
                    "type_of_coop": "(P)Labour Contract Co-operative Society",
                    "abbreviation": "LCCS"
                },
                {
                    "id": 15,
                    "type_of_coop": "(P)Large-sized Adivasi Multipurpose Cooperative Societies (LAMPS)*",
                    "abbreviation": "LAMS"
                },
                {
                    "id": 16,
                    "type_of_coop": "(P)Other  Agro-processing Co-operative Society",
                    "abbreviation": "OAPC"
                },
                {
                    "id": 17,
                    "type_of_coop": "(P)Other Agricultural Co-operative Society",
                    "abbreviation": "OACS"
                },
                {
                    "id": 18,
                    "type_of_coop": "(P)Other Agricultural Production Co-operative Society",
                    "abbreviation": "OAPS"
                },
                {
                    "id": 19,
                    "type_of_coop": "(P)Other Industrial Co-operative Society",
                    "abbreviation": "OICS"
                },
                {
                    "id": 20,
                    "type_of_coop": "(P)Other Multi-purpose Co-operative Society",
                    "abbreviation": "OMCS"
                },
                {
                    "id": 21,
                    "type_of_coop": "(P)Other Non-agricultural Credit Co-operative Society",
                    "abbreviation": "ONCS"
                },
                {
                    "id": 22,
                    "type_of_coop": "(P)Other Service Co-operative Society",
                    "abbreviation": "OSCS"
                },
                {
                    "id": 23,
                    "type_of_coop": "(P)Primary  Co-operative Agriculture and Rural Development Bank (PCARDB)",
                    "abbreviation": "PCAR"
                },
                {
                    "id": 24,
                    "type_of_coop": "(P)Primary Agricultural Co-operative Credit Society (PACS)",
                    "abbreviation": "PACS"
                },
                {
                    "id": 25,
                    "type_of_coop": "(P)Primary Agricultural Cooperative Marketing Society",
                    "abbreviation": "PACM"
                },
                {
                    "id": 26,
                    "type_of_coop": "(P)Primary Livestock Cooperative Society*",
                    "abbreviation": "PLCS"
                },
                {
                    "id": 27,
                    "type_of_coop": "(P)Primary Milk Producers’ Cooperative Society*",
                    "abbreviation": "PMPC"
                },
                {
                    "id": 28,
                    "type_of_coop": "(P)Primary Women’s Milk Producers’ Cooperative Society*",
                    "abbreviation": "PWMC"
                },
                {
                    "id": 29,
                    "type_of_coop": "(P)Rice Mill Co-operative Society",
                    "abbreviation": "RMCS"
                },
                {
                    "id": 30,
                    "type_of_coop": "(P)Road Transport Co-operative Society",
                    "abbreviation": "RTCS"
                },
                {
                    "id": 31,
                    "type_of_coop": "(P)Rural Electrification Co-operative Society",
                    "abbreviation": "RECS"
                },
                {
                    "id": 32,
                    "type_of_coop": "(P)Self Help Group Co-operative Society",
                    "abbreviation": "SHGS"
                },
                {
                    "id": 33,
                    "type_of_coop": "(P)Silk Weavers’ Cooperative Society  *",
                    "abbreviation": "SWCS"
                },
                {
                    "id": 34,
                    "type_of_coop": "(P)Talgur-Khejurgur Producers’ Co-operative Society",
                    "abbreviation": "TPCS"
                },
                {
                    "id": 35,
                    "type_of_coop": "(P)Urban Co-operative Bank",
                    "abbreviation": "URCB"
                },
                {
                    "id": 36,
                    "type_of_coop": "(P)Urban Credit Co-operative Societies",
                    "abbreviation": "UCCS"
                },
                {
                    "id": 37,
                    "type_of_coop": "(P)Water Transport Co-operative Society",
                    "abbreviation": "WTCS"
                },
                {
                    "id": 38,
                    "type_of_coop": "(P)Weavers’ Cooperative Society  *",
                    "abbreviation": "WECS"
                },
                {
                    "id": 39,
                    "type_of_coop": "(P)Women’s Co-operative Bank",
                    "abbreviation": "WOCB"
                },
                {
                    "id": 40,
                    "type_of_coop": "(P)Women’s Credit Co-operative Societies Urban Credit Co-operative Societies",
                    "abbreviation": "WCCS"
                },
                {
                    "id": 41,
                    "type_of_coop": "(C)Central Agricultural Cooperative Marketing Society",
                    "abbreviation": "CACS"
                },
                {
                    "id": 42,
                    "type_of_coop": "(C)Central Co-operative Bank ",
                    "abbreviation": "CCOB"
                },
                {
                    "id": 43,
                    "type_of_coop": "(C)Central Engineers’ Co-operative Society",
                    "abbreviation": "CECS"
                },
                {
                    "id": 44,
                    "type_of_coop": "(C)Central Fishermen’s Cooperative Societies *",
                    "abbreviation": "CFCS"
                },
                {
                    "id": 45,
                    "type_of_coop": "(C)Central Milk Producers’ Co-operative Union",
                    "abbreviation": "CMPU"
                },
                {
                    "id": 46,
                    "type_of_coop": "(C)District / Range Co-operative Union",
                    "abbreviation": "DRCU"
                },
                {
                    "id": 47,
                    "type_of_coop": "(C)Wholesale Consumers’ Co-operative Society",
                    "abbreviation": "WHCC"
                },
                {
                    "id": 48,
                    "type_of_coop": "(A)State Agricultural Marketing Co-operative Federation",
                    "abbreviation": "SAMF"
                },
                {
                    "id": 49,
                    "type_of_coop": "(A)State Consumers’ Co-operative Federation",
                    "abbreviation": "SCCF"
                },
                {
                    "id": 50,
                    "type_of_coop": "(A)State Co-operative Agriculture and Rural Development Bank (SCARDB)",
                    "abbreviation": "SCAR"
                },
                {
                    "id": 51,
                    "type_of_coop": "(A)State Co-operative Bank",
                    "abbreviation": "SCOB"
                },
                {
                    "id": 52,
                    "type_of_coop": "(A)State Co-operative Federation",
                    "abbreviation": "SCOF"
                },
                {
                    "id": 53,
                    "type_of_coop": "(A)State Co-operative Union",
                    "abbreviation": "SCOU"
                },
                {
                    "id": 54,
                    "type_of_coop": "(A)State Engineers’ Co-operative Federation",
                    "abbreviation": "SECF"
                },
                {
                    "id": 55,
                    "type_of_coop": "(A)State Federation of Urban Co-operative Credit Societies and Banks",
                    "abbreviation": "SFCB"
                },
                {
                    "id": 56,
                    "type_of_coop": "(A)State Fishermen’s Cooperative Federation*",
                    "abbreviation": "SFCF"
                },
                {
                    "id": 57,
                    "type_of_coop": "(A)State Milk Producers’ Co-operative Union",
                    "abbreviation": "SMPC"
                },
                {
                    "id": 58,
                    "type_of_coop": "(A)State Silk Weavers’ Co-operative Federation",
                    "abbreviation": "SSWC"
                },
                {
                    "id": 59,
                    "type_of_coop": "(A)State Talgur-Khejurgur Co-operative Federation",
                    "abbreviation": "STKF"
                },
                {
                    "id": 60,
                    "type_of_coop": "(A)State Tribal Deve-lopment Co-operative Corporation*",
                    "abbreviation": "STDC"
                },
                {
                    "id": 61,
                    "type_of_coop": "(A)State Weavers’ Co-operative Society (Federation)",
                    "abbreviation": "SWCF"
                }
            ],
            history: [],
            coops_for_export: []
        };
    },
    mounted() {
        this.confirmationModal = this.$refs.confirmationModal;
        this.historyModal = this.$refs.historyModal;
        this.init();
    },
    methods: {
        async init() {
            await Promise.all([
                this.getRanges(),
                this.getCoops()
            ]);
            await this.getAllCoopsForExport();
        },
        async getRanges() {
            try {
                let { data } = await this.$api('coops/ranges');
                this.ranges = data;
            } catch (e) {

            }
        },
        async getCoops() {
            try {
                let params = {
                    page: this.page,
                    per_page: this.perPage
                }

                if (this.search) {
                    params.search = this.search;
                }

                if (this.range) {
                    params.range = this.ranges.find(r => r.range_id === this.range).name;
                }

                if (this.municipality) {
                    params.municipality = this.municipality;
                }

                if (this.status) {
                    params.status = this.status;
                }

                if (this.is_valid !== null) {
                    params.is_valid = this.is_valid;
                }

                if (this.selected_template !== null) {
                    params.type_of_society = this.selected_template;
                }

                if (this.search_addon_number) {
                    params.addon_number = this.search_addon_number;
                }

                let { data } = await this.$api('coops', { params: params });
                this.coops = data.coops;
                this.total = data.total;
            } catch (e) {
                console.error(e);
                this.$toast.error(this.$t('toastr.no-coop-msg'));
            }
        },
        async getAllCoopsForExport() {
            try {
                let params = {
                    page: this.page,
                    per_page: this.total
                }

                if (this.search) {
                    params.search = this.search;
                }

                if (this.range) {
                    params.range = this.ranges.find(r => r.range_id === this.range).name;
                }

                if (this.municipality) {
                    params.municipality = this.municipality;
                }

                if (this.status) {
                    params.status = this.status;
                }

                if (this.is_valid !== null) {
                    params.is_valid = this.is_valid;
                }

                if (this.selected_template !== null) {
                    params.type_of_society = this.selected_template;
                }

                if (this.search_addon_number) {
                    params.addon_number = this.search_addon_number;
                }
                const { data } = await this.$api('coops', { params });
                this.coops_for_export = data.coops.map(coop => ({
                    "E-District Id": '\t' + coop.registration.addon_number.toString(),
                    "Name": coop.name,
                    "Registration Number": coop.registration.number,
                    "Financial Year": coop.assessment_year,
                    "Address": coop.address,
                    "Status": coop.status,
                    "File Name": coop.file_name,
                    "Created At": moment(coop.created_at).format("DD-MMM-YY, h:mm a")
                }))
            } catch (e) {
                console.error(e)
            }
        },
        async getBlocks(range_id) {
            if (range_id) {
                this.blocks = [];
                try {
                    let { data } = await this.$api.get(`/coops/ranges/${range_id}/blocks`);
                    this.blocks = data;
                } catch (e) {

                }
            }
        },
        async handlePagerChange(e) {
            this.page = e.page;
            this.perPage = e.per_page;
            await this.getCoops();
        },
        viewCoop(coop) {
            this.$router.push({ name: 'viewcoop', params: { coopid: coop.coop_id, coopnum: coop.coop_human_id } });
        },
        async searchCoop() {
            this.page = 0;
            await this.getCoops();
            await this.getAllCoopsForExport();
        },
        async resetSearchCriteria() {
            this.page = 0;
            this.name = null;
            this.range = null;
            this.municipality = null;
            this.status = null;
            this.is_valid = null;
            this.selected_template = null;
            this.search_addon_number = null;
            await this.getCoops();
            await this.getAllCoopsForExport();
        },
        confirmSubmitAll() {
            this.header = this.$t('page.coop.modal.submit_all.header');
            this.message = this.$filters.interpolate(this.$t('page.coop.modal.submit_all.body'));
            this.confirmationModal.show();
        },
        async submitAll(remarks) {
            try {
                await this.$api.post(`coops/submit_all_for_approval`, { remarks: remarks.remarks });
                this.$toast.success(this.$t('toastr.submit-all-valid-coop-msg'));
                await this.getCoops();
            } catch (e) {
                console.error(e)
                this.$toast.error(this.$t('toastr.submit-all-valid-coop-err-msg'));
            } finally {
                this.confirmationModal.hide();
            }
        },
        async viewHistory(id, name) {
            try {
                this.selectedCoopId = id;
                this.selectedCoopName = name;
                let { data } = await this.$api.get(`/coops/${id}/history`);
                this.history = data.history;
                this.historyModal.show();
            } catch (e) {

            }
        },
        closeHistory() {
            this.selectedCoopId = null;
        },
        async removeCoop(coop_id) {
            try {
                await this.$api.delete(`coops/${coop_id}`);
                $('#delModal').modal('hide');
                this.$toast.success('Co-operative successfully removed');
                await this.getCoops();
            } catch (e) {

            }
        },
        getCaseId(coop) {
            return `${coop.registration.number}/${coop.registration_year}/${pad(6, coop.id, '0')}`
        },
        getCoopId() {

        },
        getCoopSocietyShortName(society_name) {
            let found = this.templates.find(t => t.type_of_coop === society_name)
            return found ? found.abbreviation : society_name;
        }
    }
}
</script>

<style scoped></style>