<template>
    <div id="confirmationModal" class="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">{{header}}</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p v-html="message"></p>
                    <div class="row form-group mt-3 mb-0" v-if="withRemarks">
                        <div class="col-12" id="remarks">
                            <textarea class="form-control" v-model="remarks" :placeholder="$t('page.coop.modal.remarks')"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-indigo" @click="confirm">{{$t('page.coop.button.confirm')}}</button>
                    <button type="button" class="btn btn-outline-light" @click="cancel" data-dismiss="modal">{{$t('page.coop.button.cancel')}}</button>
                </div>
            </div>
        </div><!-- modal-dialog -->
    </div>
</template>

<script>

    /**
     * Usage:
     * <confirmation-modal ref="modal"></confirmation-modal>
     *
     * this.$refs.modal.show();
     * this.$refs.modal.hide();
     */
    export default {
        name: "ConfirmationModal",
        props: ["header", "message", "status", "withRemarks"],
        data() {
            return {
                remarks: null
            }
        },
        mounted() {
            let self = this;
            $(document).ready(function() {
                $("#confirmationModal").on('hide.bs.modal', function() {
                    self.remarks = null;
                });
            });
        },
        methods: {
            confirm() {
                this.$emit('confirm', { status: this.status , remarks: this.remarks });
            },
            cancel() {
                this.$emit('cancel');
            },
            show() {
                $('#confirmationModal').modal('show');
            },
            hide() {
                $('#confirmationModal').modal('hide');
            }
        }
    }
</script>

<style scoped>

</style>