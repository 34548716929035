<template>
    <div class="row row-sm">
        <div class="col-12">
            <Form @submit="submit" ref="form" v-slot="{errors}">
                <div class="card card-dashboard-twelve">
                <div class="card-body">
                    <div class="row align-items-center mg-b-10 mg-t-10 ">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">Range</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <Field as="select" name="range" rules="required"
                                   class="form-control" v-model="range_id"
                                   :class="{ 'is-invalid' : errors.range }">
                                <option :value="null">{{$t('page.coop.range.placeholder')}}</option>
                                <option :value="range.range_id" v-for="range in ranges">{{range.name}}</option>
                            </Field>
                            <span class="invalid-feedback" v-if="errors.range">{{ errors.range }}</span>
                        </div>
                    </div>
                    <div class="row align-items-center mg-b-10">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">CI/Block User</label>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-6">
                            <Field as="select" name="user" rules="required"
                                   class="form-control" v-model="user_id"
                                   :class="{ 'is-invalid' : errors.user }">
                                <option :value="null">Select CI/Block User</option>
                                <option :value="clerk.user_id" v-for="clerk in clerks">{{clerk.first_name}} {{clerk.last_name}}</option>
                            </Field>
                            <span class="invalid-feedback" v-if="errors.user">{{ errors.user }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right btn-toolbar">
                        <button class="btn btn-success mr-2" type="submit">{{$t('page.coop.button.submit')}}</button>
                        <button class="btn btn-light" type="reset">{{$t('page.coop.button.reset')}}</button>
                    </div>
                </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";

    export default {
        name: "AddClerk",
        components: {Form, Field},
        data() {
            return {
                ranges: [],
                clerks: [],
                range_id: null,
                user_id: null
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getRanges(),
                    this.getClerks()
                ])
            },
            async getRanges() {
                try {
                    let {data} = await this.$api('coops/ranges');
                    this.ranges = data;
                } catch (e) {

                }
            },
            async getClerks() {
                try {
                    let {data} = await this.$api('auth/clerks');
                    this.clerks = data;
                } catch (e) {

                }
            },
            async submit() {
                try {
                    await this.$api.post(`/auth/${this.user_id}/add_clerk_to_range`, {
                        range_id: this.range_id
                    });
                    this.$toast.success('CI/Block user successfully added');
                    await this.$router.push({name: 'user-management'})
                }catch (e) {

                }
            }
        }
    }
</script>

<style scoped>

</style>