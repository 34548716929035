import {defineRule, configure, extend} from "vee-validate";
import {required, email, integer, min_value, max_value, digits} from '@vee-validate/rules';
import {localize} from '@vee-validate/i18n';

defineRule('required', required);
defineRule('email', value => {
    if(!value) {
        return true;
    }
    return email(value);
});
defineRule('integer', value => {
    if(!value) {
        return true;
    }
    return integer(value);
});
defineRule('min_value', (value, params) => {
    if(!value) {
        return true;
    }
    return min_value(value, params);
});
defineRule('max_value', (value,params) => {
    if(!value) {
        return true;
    }
    return max_value(value,params);
});
defineRule('digits', (value,params) => {
    if(!value) {
        return true;
    }
    return digits(value,params);
});


configure({
    // Generates an English message locale generator
    generateMessage: localize({
        en: {
            messages: {
                required: 'This field is required',
                email: 'This field is not a valid email',
                integer: 'This field not a valid integer',
                min_value: 'This field should be greater than 0:{min_value}',
                max_value: 'This field should be less than 0:{max_value}',
                digits: 'This field must be numeric and exactly contain 0:{length} digits'
            },
        },
        en1: {
            messages: {
                required: 'This field is required',
                email: 'This field is not a valid email',
                integer: 'This field not a valid integer',
                min_value: 'This field should be greater than 0:{min_value}',
                max_value: 'This field should be less than 0:{max_value}',
                digits: 'This field must be numeric and exactly contain 0:{length} digits'
            },
        },
        bn: {
            messages: {
                required: 'এই ক্ষেত্রটি অবশ্যক',
                email: 'এই ক্ষেত্রটি সঠিক ইমেইল নয়',
                integer: 'এই ক্ষেত্রটি সঠিক পূর্ণসংখ্যা নয়',
                min_value: 'এই ক্ষেত্রটি 0:{min_value} চেয়ে বড় হওয়া উচিত',
                max_value: 'এই ক্ষেত্রটি 0:{max_value} পরবর্তী সংখ্যার চেয়ে কম হওয়া উচিত; ',
                digits: 'এই ক্ষেত্রটি সংখ্যা হতে হবে এবং ঠিক 0:{length} অঙ্ক থাকতে হবে'
            },
        },
    }),
});