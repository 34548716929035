window.$ = window.jQuery = require('jquery');

import 'jquery';
import 'popper.js';
import 'bootstrap';

import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './validation-rules';
import i18n from "./i18n";
import VueHtmlToPaper from 'vue-html-to-paper';

/* Styles */
require("./assets/lib/@fortawesome/fontawesome-free/css/all.min.css");
require("./assets/lib/ionicons/css/ionicons.min.css");
require("./assets/lib/jqvmap/jqvmap.min.css");
require("./assets/lib/typicons.font/typicons.css");
require("./assets/css/azia.css");
require("@vueform/multiselect/themes/default.css");
require("./assets/css/custom.css");
require("./assets/lib/spectrum-colorpicker/spectrum.css");
require("./assets/lib/ion-rangeslider/css/ion.rangeSlider.css");
require("./assets/lib/ion-rangeslider/css/ion.rangeSlider.skinFlat.css");
require("./assets/lib/amazeui-datetimepicker/css/amazeui.datetimepicker.css");
require("./assets/lib/jquery-simple-datetimepicker/jquery.simple-dtpicker.css");
require("./assets/lib/pickerjs/picker.min.css");

/* Scripts */
require("./assets/lib/jquery-steps/jquery.steps.min");
require("./assets/lib/jquery.flot/jquery.flot.js");
require("./assets/lib/jquery.flot/jquery.flot.resize.js");
require("./assets/lib/peity/jquery.peity.min.js");
require("./assets/lib/ionicons/ionicons.js");
require("./assets/lib/jquery-steps/jquery.steps.min");
require("./assets/lib/perfect-scrollbar/perfect-scrollbar.min");
require("./assets/js/azia.js");
require("./assets/js/chart.flot.sampledata.js");
require("./assets/lib/amazeui-datetimepicker/js/amazeui.datetimepicker.min.js");
require("./assets/lib/jquery-ui/ui/widgets/datepicker.js");
require("./assets/lib/jquery.maskedinput/jquery.maskedinput.js");
require("./assets/lib/spectrum-colorpicker/spectrum.js");
require("./assets/lib/select2/js/select2.min.js");
require("./assets/lib/ion-rangeslider/js/ion.rangeSlider.min.js");
require("./assets/lib/jquery-simple-datetimepicker/jquery.simple-dtpicker.js");
require("./assets/lib/pickerjs/picker.min.js");
require("./assets/lib/datatables.net/js/jquery.dataTables.min");

/* Plugins */
import axios from 'axios';
import moment from "moment";
import Toaster from '@meforma/vue-toaster';

const app = createApp(App);

app.config.globalProperties.$api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("coops-token")
    },
});

app.config.globalProperties.$api.interceptors.request.use((config) => {
    let token                    = localStorage.getItem("coops-token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function(error) {
    return Promise.reject(error)
});


app.config.globalProperties.$api.interceptors.response.use((res) => {
    if(res.headers["x-token"]) {
        store.commit("REFRESH", res.headers["x-token"]);
    }
    return res;
}, function(error) {
    if(error.response && error.response.status === 401) {
        router.push({name: 'signin'});
        localStorage.removeItem("token");
    }
    return Promise.reject(error)
});

app.config.globalProperties.$filters = {
    date(dateString) {
        if(!dateString) return '';
        return moment(dateString).format("Do MMM YYYY");
    },

    timestamp(dateString) {
        if(!dateString) return '';
        // return new Date(dateString+'Z').toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
        return moment.utc(dateString).local().format("DD-MMM-YY, h:mm:ss a");
    },

    shorttimestamp(dateString) {
        if(!dateString) return '';
        return moment(dateString).format("DD-MMM-YY, h:mm a");
    },

    filename(filepath) {
        if(!filepath) return '';
        let tokens = filepath.split('/');
        return tokens[tokens.length - 1];
    },

    interpolate(str, params) {
        let op = str;
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                let val = params[key];
                op = op.replace(`{${key}}`,val);
            }
        }

        return op;
    }
}

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=no'
    ],
    scripts: [
        'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/js/all.min.js'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css'
    ]
}

app
.use(router)
.use(store)
.use(Toaster, {position: 'top-right'})
.use(i18n)
.mount('#app');