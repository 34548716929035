<template>
    <div class="az-signin-wrapper">
        <!-- az-card-signin -->
        <div class="az-card-signin">
            <div class="az-signin-header mx-auto mg-b-20">
                <img alt="homepage" class="custom-logo az-logo" src="../../assets/coop.svg">
<!--                <img alt="" class="az-card-signin-logo mx-auto" src="../../assets/logo.png" height="50" width="50">-->
            </div>

            <h4 class="text-center mb-4" style="color: #3a86ff">Co-operative Census</h4>
            <h5 class="mb-3">Please sign in to continue</h5>

            <form @submit.prevent="login">
                <div class="form-group text-left">
                    <label for="email">Username</label>
                    <input v-model="credentials.email" type="text" id="email" class="form-control" required>
                </div><!-- form-group -->
                <div class="form-group text-left">
                    <label for="password">Password</label>
                    <input v-model="credentials.password" type="password" minlength="8" id="password" class="form-control" required>
                </div><!-- form-group -->
                <div class="form-group text-left mb-1" v-if="err_msg">
                    <label class="text-danger">{{err_msg}}</label>
                </div>
                <button class="btn btn-az-secondary btn-block rounded">Sign In</button>
            </form>

<!--            <div class="az-signin-footer text-left">-->
<!--                <p><a href="">Forgot password?</a></p>-->
<!--&lt;!&ndash;                <p>Don't have an account?&ndash;&gt;-->
<!--&lt;!&ndash;                    <a href="javascript:void(0);">Create an Account</a>&ndash;&gt;-->
<!--&lt;!&ndash;                </p>&ndash;&gt;-->
<!--            </div>&lt;!&ndash; az-signin-footer &ndash;&gt;-->
        </div>

    </div><!-- az-signin-wrapper -->
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                credentials: {
                    email: '',
                    password: ''
                },
                err_msg: null
            };
        },
        mounted() {
        },
        methods: {
            async login() {
                this.submitted = true;

                if(this.credentials.email && this.credentials.password) {
                    try {
                        this.err_msg = null;
                        let {data}   = await this.$api.post('/auth/login', this.credentials);
                        let token    = data.token;
                        if(!token) {
                            this.$toast.error('Login Failed');
                            return;
                        }
                        this.$store.commit("LOGIN", data);
                        this.$toast.success('Login Success');
                        await this.$router.replace(this.$route.query.redirect || '/');
                    } catch(e) {
                        this.err_msg = e.response ? e.response.data : e.message;
                        this.$store.dispatch('logout');
                        delete localStorage["coops-token"];
                        this.$toast.error(this.err_msg);
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .custom-logo {
        height: 188px;
    }

    .az-card-signin {
        height: 580px !important;
    }
</style>
