<template>
    <div class="az-header shadow-none d-print-none">
        <div class="container-fluid">
            <div class="az-header-left">
                <a @click="toHome()" class="az-logo" href="javascript:void(0)">
                    <img alt="homepage" class="mg-t-10 custom-logo az-logo" src="../../assets/coop_sidebar.svg">
                </a>
                <a class="az-header-menu-icon d-lg-none" href="" id="azNavShow"><span></span></a>
            </div><!-- az-header-left -->
            <div class="az-header-center">
                <input :placeholder="$t('layout.search_coop')" aria-expanded="false" aria-haspopup="true" class="form-control"
                       data-toggle="dropdown" type="text" v-model="search">
                <div :class="{ 'show': coops.length> 0 }" class="dropdown-menu w-100">
                    <div class="dropdown-item" v-if="searching === 'IDLE'">{{$t('layout.start_typing')}} ...</div>
                    <div class="dropdown-item" href="javascript:void(0);" v-else-if="searching === 'IN_PROGRESS'">
                        <i class="fa fa-spin fa-spinner mr-2"/>{{$t('layout.searching')}}
                    </div>
                    <template v-else-if="searching === 'DONE'">
                        <template v-if="total > 0">
                            <router-link :to="{name: 'viewcoop', params: {coopid: coop.coop_id, coopnum: coop.coop_human_id}}"
                                         class="dropdown-item coop-link"
                                         v-for="coop in coops">
                                <b>{{$t('layout.name')}}:</b> {{coop.name||'N/A'}}<br>
                                <b>{{$t('layout.addon_no')}}:</b> {{coop.registration.addon_number || 'N/A'}}
                                <b class="ml-2">{{$t('layout.reg_year')}}:</b> {{coop.registration_year || 'N/A'}}
                                <b class="ml-2">{{$t('layout.file')}}:</b> {{coop.file_name || 'N/A'}}
                            </router-link>
                            <span class="dropdown-item" v-if="total > 5">
                                {{ $filters.interpolate($t('layout.items_more'), { count: (total-5)})}}
                            </span>
                        </template>
                        <span class="dropdown-item" v-else>
                            {{$t('layout.coop_not_found')}}
                        </span>
                    </template>
                </div>
                <button class="btn"><i class="fas fa-search"></i></button>
            </div><!-- az-header-center -->
            <div class="az-header-right">
                <div class="dropdown lan-dropdown">
                    <button :title="$t('layout.change_language')" @click="toggleProfile"
                            class="btn btn-sm btn-outline-secondary dropdown-toggle" data-toggle="dropdown" style="width: 80px;" type="button">
                        {{$t('language')}}
                    </button>
                    <div class="dropdown-menu lan-dropdown-menu tx-14" x-placement="bottom-start">
                        <!--                        <a class="dropdown-item" href="javascript:void(0)" :class="{'disabled':language === 'en'}" @click="changeLocale('en')">English</a>-->
                        <button :class="{'disabled':language === 'bn'}" @click="changeLocale('bn')" class="dropdown-item" href="javascript:void(0)">বাংলা</button>
                        <button :class="{'disabled':language === 'en1'}" @click="changeLocale('en1')" class="dropdown-item" href="javascript:void(0)">English</button>
                    </div>
                </div>
                <div class="dropdown az-profile-menu">
                    <a class="az-img-user border text-center pt-1" href="javascript:void(0)" @click="toggleLanDropdown">
                        <i class="fa fa-lg fa-user-shield" v-if="currentUser && currentUser.roles.length>0 && currentUser.roles[0] === 'Admin'"/>
                        <i class="fa fa-lg fa-user" v-else/>
                    </a>
                    <div class="dropdown-menu">
                        <div class="az-dropdown-header d-sm-none">
                            <a class="az-header-arrow" href=""><i class="icon ion-md-arrow-back"></i></a>
                        </div>
                        <div class="az-header-profile custom-padding">
                            <div class="az-img-user text-center">
                                <i class="fa fa-4x fa-user-shield" v-if="currentUser && currentUser.roles.length>0 && currentUser.roles[0] === 'Admin'"/>
                                <i class="fa fa-4x fa-user" v-else/>
                            </div><!-- az-img-user -->
                            <h6>{{currentUser.name}}</h6>
                            <span>{{currentUser && currentUser.roles.length>0 ? getUserRole(currentUser.roles[0]): ''}}</span>
                        </div><!-- az-header-profile -->

                        <a class="dropdown-item" href="javascript:void(0);"><i class="typcn typcn-user-outline"></i> {{$t('layout.my_profile')}}</a>
                        <router-link :to="{name: 'user-management'}" class="dropdown-item" v-if="currentUser && currentUser.roles.length>0 && currentUser.roles[0] === 'Admin'"><i
                                class="typcn typcn-group-outline"></i> User Management
                        </router-link>
                        <router-link :to="{name: 'password-management'}" class="dropdown-item" v-if="currentUser && currentUser.roles.length>0 && currentUser.roles[0] === 'Admin'"><i
                                class="typcn typcn-key-outline"></i> User Access Control
                        </router-link>
                        <router-link :to="{name: 'change-password'}" class="dropdown-item"><i
                                class="typcn typcn-lock-open"></i> Change Password
                        </router-link>
                        <a @click="signout" class="dropdown-item" href="javascript:void(0);"><i class="typcn typcn-power-outline"></i> {{$t('layout.sign_out')}}</a>
                    </div><!-- dropdown-menu -->
                </div>
            </div><!-- az-header-right -->
        </div><!-- container -->
    </div><!-- az-header -->
</template>

<script>
    import {mapGetters} from "vuex";
    import {setLocale} from '@vee-validate/i18n';
    import i18n from "../../i18n";

    export default {
        name: "Header",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                searching: 'IDLE',
                search: null,
                coops: [],
                total: 0,
                notifications: [],
                language: null,
            }
        },
        watch: {
            search: function (n, o) {
                this.coop = [];
                this.total = 0;
                this.searching = 'IDLE';
                if (n && n.length > 0) {
                    this.searchCoop();
                }
            }
        },
        mounted() {
            this.language = this.$i18n.getLocale();
            setLocale(this.$i18n.getLocale());
            this.init();
        },
        methods: {
            async init() {
                $(function () {
                    'use strict';
                    //bootstrap conflicts bugfix
                    let bootstrapEventsNotAttached = !$._data($('.az-header .dropdown > a')[0], "events");

                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('.az-navbar .active').removeClass('show');
                        $('.az-header-menu .active').removeClass('show');
                    }

                    if (bootstrapEventsNotAttached) {
                        // Shows header dropdown while hiding others;
                        $('.az-header .dropdown > a').on('click', function (e) {
                            e.preventDefault();
                            $(this).parent().toggleClass('show');
                            $(this).parent().siblings().removeClass('show');
                        });
                    }

                    $('.dropdown-item').on('click', function (e) {
                        $('.az-profile-menu').removeClass('show');
                    })


                    // Showing submenu in navbar while hiding previous open submenu
                    $('.az-navbar .with-sub').on('click', function (e) {
                        e.preventDefault();
                        $(this).parent().toggleClass('show');
                        $(this).parent().siblings().removeClass('show');
                    });

                    // this will hide dropdown menu from open in mobile
                    $('.dropdown-menu .az-header-arrow').on('click', function (e) {
                        e.preventDefault();
                        $(this).closest('.dropdown').removeClass('show');
                    });

                    // this will show navbar in left for mobile only
                    $('#azNavShow, #azNavbarShow').on('click', function (e) {
                        e.preventDefault();
                        $('body').addClass('az-navbar-show');
                    });

                    // this will hide currently open content of page
                    // only works for mobile
                    $('#azContentLeftShow').on('click touch', function (e) {
                        e.preventDefault();
                        $('body').addClass('az-content-left-show');
                    });

                    // This will hide left content from showing up in mobile only
                    $('#azContentLeftHide').on('click touch', function (e) {
                        e.preventDefault();
                        $('body').removeClass('az-content-left-show');
                    });

                    // this will hide content body from showing up in mobile only
                    $('#azContentBodyHide').on('click touch', function (e) {
                        e.preventDefault();
                        $('body').removeClass('az-content-body-show');
                    });

                    // navbar backdrop for mobile only
                    $('body').append('<div class="az-navbar-backdrop"></div>');
                    $('.az-navbar-backdrop').on('click touchstart', function () {
                        $('body').removeClass('az-navbar-show');
                    });

                    // Close dropdown menu of header menu
                    $(document).on('click touchstart', function (e) {
                        e.stopPropagation();

                        // closing of dropdown menu in header when clicking outside of it
                        var dropTarg = $(e.target).closest('.az-header .dropdown').length;
                        if (!dropTarg) {
                            $('.az-header .dropdown').removeClass('show');
                        }

                        // closing nav sub menu of header when clicking outside of it
                        if (window.matchMedia('(min-width: 992px)').matches) {

                            // Navbar
                            var navTarg = $(e.target).closest('.az-navbar .nav-item').length;
                            if (!navTarg) {
                                $('.az-navbar .show').removeClass('show');
                            }

                            // Header Menu
                            var menuTarg = $(e.target).closest('.az-header-menu .nav-item').length;
                            if (!menuTarg) {
                                $('.az-header-menu .show').removeClass('show');
                            }

                            if ($(e.target).hasClass('az-menu-sub-mega')) {
                                $('.az-header-menu .show').removeClass('show');
                            }

                        } else {

                            //
                            if (!$(e.target).closest('#azMenuShow').length) {
                                var hm = $(e.target).closest('.az-header-menu').length;
                                if (!hm) {
                                    $('body').removeClass('az-header-menu-show');
                                }
                            }
                        }
                    });

                    $('#azMenuShow').on('click', function (e) {
                        e.preventDefault();
                        $('body').toggleClass('az-header-menu-show');
                    });

                    $('.az-header-menu .with-sub').on('click', function (e) {
                        e.preventDefault();
                        $(this).parent().toggleClass('show');
                        $(this).parent().siblings().removeClass('show');
                    });

                    $('.az-header-menu-header .close').on('click', function (e) {
                        e.preventDefault();
                        $('body').removeClass('az-header-menu-show');
                    });

                    $('.nav-link:not(.with-sub)').click(function () {
                        $('.nav-item').removeClass('show');
                    });
                });
                // await this.getNotifications();
            },
            async signout() {
                this.$store.commit('LOGOUT');
                await this.$router.replace({name: 'signin'});
            },
            changeLocale(locale) {
                this.language = locale;
                i18n.setLocale(locale);
                localStorage.setItem("locale", locale);
                setLocale(locale);
            },
            async searchCoop() {
                this.coops = []
                this.searching = 'IN_PROGRESS';
                try {
                    let {data} = await this.$api('coops', {
                        params: {
                            search: this.search,
                            page: 0,
                            per_page: 5
                        }
                    });
                    this.coops = data.coops;
                    this.total = data.total;
                } catch (e) {
                    console.log(e);
                    if (e.response.status !== 404) {
                        this.$toast.error(this.$t('toastr.search-err-msg'));
                    }
                } finally {
                    this.searching = 'DONE';
                }
            },
            async getNotifications() {
                // TODO: implement notification API, integrated with socket
                this.notifications = [
                    {
                        name: "Chashighar",
                        coop_id: "2ed56a4c-3ee2-11eb-8904-0242ac110002",
                        created_at: "2020-12-11T14:31:13.418232"
                    },
                    {
                        name: "Test",
                        coop_id: "80390528-3ee8-11eb-97c3-0242ac110002",
                        created_at: "2020-12-12T14:31:13.418232"
                    },
                    {
                        name: "Shantiniketon Artisans",
                        coop_id: "9ebe6336-3f6e-11eb-83a1-0242ac110002",
                        created_at: "2020-12-13T14:31:13.418232"
                    }
                ]
            },
            getAlertText(count) {
                return this.$filters.interpolate(this.$t('notification.alert'), {count: count})
            },
            getNotificationText(name) {
                return this.$filters.interpolate(this.$t('notification.text'), {name: name})
            },
            toHome() {
                this.$router.push({name: 'home'});
            },
            getUserRole(role) {
                switch (role) {
                    case 'Admin':
                        return 'Administrator';
                    case 'Approver':
                        return 'Range Officer';
                    case 'Clerk':
                        return 'CI/Block User';
                    case 'ZoneOfficer':
                        return 'Zone Officer';
                    default:
                        return ''
                }
            },
            toggleLanDropdown() {
                $('.lan-dropdown-menu').removeClass('show');
                $('.lan-dropdown').removeClass('show');
            },
            toggleProfile() {
                $('.az-profile-menu').removeClass('show');
            }
        }
    }
</script>

<style scoped>

    .custom-logo {
        width: 220px;
    }

    .az-img-user:hover {
        color: #3a86ff;
    }

    .coop-link:hover {
        color: #3366ff;
    }

    .custom-padding {
        padding: 8px !important;
    }
</style>
