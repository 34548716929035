<template>
    <div class="az-footer ht-40">
        <div class="container-fluid pd-t-0-f ht-100p">
            <span>© 2020 - {{currentYear}} WB Co-operative Census</span>
            <span>Contact Us: +916289212080&nbsp;(wbfederationcoop@gmail.com)</span>
            <span>Powered by: <a href="https://www.borlaug.ws/" target="_blank"><img src="../../assets/bws-logo.svg" style="height: 16px;"> Borlaug Web Services ™</a></span>
        </div><!-- container -->
    </div><!-- az-footer -->
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                currentYear: null,
            }
        },
        mounted() {
            const d          = new Date();
            this.currentYear = d.getFullYear();
        }
    }
</script>

<style scoped>

</style>