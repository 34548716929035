<template>
    <div class="az-body">
        <div class="az-signup-wrapper">
            <div class="az-column-signup">
                <h1 class="az-logo">
                    <img alt="homepage" class="az-logo" src="../assets/coop.svg">
                </h1>
                <div class="az-signup-header">
                    <div class="form-group">
                        <label>Password</label>
                        <input v-model="password" class="form-control" placeholder="Enter your password" type="password">
                    </div><!-- form-group -->
                    <button @click="next()" class="btn btn-az-primary btn-block">Next</button>
                </div><!-- az-signup-header -->
                <div class="az-signup-footer"></div><!-- az-signin-footer -->
            </div><!-- az-column-signup -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "Welcome",
        data() {
            return {
                password: null
            }
        },
        methods: {
            next() {
                if (this.password === 'wbcoop123'){
                    this.$router.push({name: 'signin'})
                }else {
                    this.$toast.error("Password doesn't match");
                }
            }
        }
    }
</script>

<style scoped>
    .custom-logo {
        height: 188px;
    }

</style>