<template>
    <div class="row row-sm">
        <div class="col-12">
            <Form @submit="submit" ref="form" v-slot="{errors}">
                <div class="card card-dashboard-twelve">
                <div class="card-body">
                    <div class="row align-items-center mg-b-10 mg-t-10">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">Range</label>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-6">
                            <Field as="select" name="range" rules="required"
                                   class="form-control" v-model="range_id"
                                   :class="{ 'is-invalid' : errors.range }">
                                <option :value="null">Select Range</option>
                                <option :value="range.range_id" v-for="range in ranges">{{range.name}}</option>
                            </Field>
                            <span class="invalid-feedback" v-if="errors.range">{{ errors.range }}</span>
                        </div>
                    </div>
                    <div class="row align-items-center mg-b-10">
                        <div class="col-sm-12 col-md-4 col-lg-3">
                            <label class="form-label font-weight-bold required">Range Officer</label>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-6">
                            <Field as="select" name="user" rules="required"
                                   class="form-control" v-model="user_id"
                                   :class="{ 'is-invalid' : errors.user }">
                                <option :value="null">Select Range Officer</option>
                                <option :value="approver.user_id" v-for="approver in approvers">{{approver.first_name}} {{approver.last_name}}</option>
                            </Field>
                            <span class="invalid-feedback" v-if="errors.user">{{ errors.user }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right btn-toolbar">
                        <button class="btn btn-success mr-2" type="submit">{{$t('page.coop.button.submit')}}</button>
                        <button class="btn btn-light" type="reset">{{$t('page.coop.button.reset')}}</button>
                    </div>
                </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form, Field} from "vee-validate";

    export default {
        name: "AddApprover",
        components: {Form, Field},
        data() {
            return {
                ranges: [],
                approvers: [],
                range_id: null,
                user_id: null
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                        this.getRanges(),
                        this.getApprovers()
                ])
            },
            async getRanges() {
                try {
                    let {data} = await this.$api('coops/ranges');
                    this.ranges = data;
                } catch (e) {

                }
            },
            async getApprovers() {
                    try {
                        let {data} = await this.$api('auth/approvers');
                        this.approvers = data;
                    } catch (e) {

                    }
            },
            async submit() {
                try {
                    await this.$api.post(`/auth/${this.user_id}/add_approver_to_range`, {
                        range_id: this.range_id
                    });
                    this.$toast.success('Range officer successfully added');
                    await this.$router.push({name: 'user-management'})
                }catch (e) {
                    
                }
            }
        }
    }
</script>

<style scoped>

</style>