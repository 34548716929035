<template>
    <div class="az-body">
        <page-header/>
        <page-sidebar/>
        <div class="az-content az-content-dashboard-four">
        <page-content/>
        </div>
        <page-footer/>
<!--        <div class="az-content az-content-dashboard-five">-->
<!--            <page-header/>-->
<!--            <page-content/>-->
<!--            <page-footer/>-->
<!--        </div>-->
    </div>
</template>

<script>
    import PageSidebar from "./Navbar";
    import PageHeader from "./Header";
    import PageFooter from "./Footer";
    import PageContent from "./Content";

    export default {
        name: "Layout",
        components: {PageSidebar, PageHeader, PageFooter, PageContent},
        mounted() {
            $(function(){
                'use strict'

                $('.az-sidebar .with-sub').on('click', function(e){
                    e.preventDefault();
                    $(this).parent().toggleClass('show');
                    //$(this).parent().siblings().removeClass('show');
                })

                $(document).on('click touchstart', function(e){
                    e.stopPropagation();

                    // closing of sidebar menu when clicking outside of it
                    if(!$(e.target).closest('.az-header-menu-icon').length) {
                        var sidebarTarg = $(e.target).closest('.az-sidebar').length;
                        if(!sidebarTarg) {
                            $('body').removeClass('az-sidebar-show');
                        }
                    }
                });


                $('#azSidebarToggle').on('click', function(e){
                    e.preventDefault();

                    if(window.matchMedia('(min-width: 992px)').matches) {
                        $('.az-sidebar').toggle();
                    } else {
                        $('body').toggleClass('az-sidebar-show');
                    }
                });
            });
        }
    }
</script>

<style scoped>

</style>
