import JwtDecode from 'jwt-decode';

export default class User {
    constructor({id, email, first_name, last_name, roles}) {
        this.id        = id;
        this.email     = email;
        this.firstName = first_name;
        this.lastName  = last_name;
        this.name      = this.firstName + " " + this.lastName;
        this.roles      = roles;
    }

    isClerk(){
        return this.roles.includes('Clerk');
    }

    isAdmin(){
        return this.roles.includes('Admin');
    }

    isApprover(){
        return this.roles.includes('Approver');
    }

    static from(token) {
        try {
            let obj = JwtDecode(token);
            return new User(obj);
        } catch(_) {
            return null;
        }
    }
}
