<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card-header border-bottom d-flex justify-content-between">
                <h4 class="card-title">{{name}}
                    <coop-status :status="coop.status"/>
                </h4>
                <h4>{{coop.assessment_year}}</h4>
            </div>

            <div class="card-body">
                <!--       SINGLE LABEL         -->
                <template v-for="field in fields">
                    <div class="row" v-if="field.type !== 'json'">
                        <div class="col-lg-8 col-md-4 col-sm-12">
                            <display-json-field :data="coop[id]? coop[id][field.db] : null" :description="field.description" :label="field.label"
                                                :mandatory="false" :pointer="null"/>
                        </div>
                    </div>
                    <h6 class="text-divider text-left ml-1" v-else><span class="pl-0">{{field.label}}</span></h6>
                    <template v-for="sub_field in field.fields">
                        <div class="row ml-2" v-if="sub_field.type !== 'json'">
                            <div class="col-lg-8 col-md-4 col-sm-12">
                                <display-json-field :data="coop[id] && coop[id][field.db] ? coop[id][field.db][sub_field.db] : null" :description="sub_field.description" :label="sub_field.label"
                                                    :mandatory="false" :pointer="null"/>
                            </div>
                        </div>
                        <h6 class="text-divider text-left ml-3" v-else><span class="pl-0">{{sub_field.label}}</span></h6>
                        <template v-for="sub_sub_field in sub_field.fields">
                            <div class="row ml-4" v-if="sub_sub_field.type !== 'json'">
                                <div class="col-lg-8 col-md-4 col-sm-12">
                                    <display-json-field :data="coop[id] && coop[id][field.db] && coop[id][field.db][sub_field.db] ? coop[id][field.db][sub_field.db][sub_sub_field.db] : null" :description="sub_sub_field.description"
                                                        :label="sub_sub_field.label" :mandatory="false"
                                                        :pointer="null"/>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>




<!--                <template v-for="index in Math.ceil(fields.length/2)" v-if="fields">-->
<!--                    <div class="row">-->
<!--                        <div class="col-lg-4 col-md-4 col-sm-12" v-if="fields[(index-1)*2] && fields[(index-1)*2].type !== 'json'">-->
<!--                            <display-json-field :data="''" :description="fields[(index-1)*2].description" :label="fields[(index-1)*2].label"-->
<!--                                                :mandatory="false" :pointer="null"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <h6 class="text-divider text-left" v-if="fields[(index-1)*2] && fields[(index-1)*2].type === 'json'"><span class="pl-0">{{fields[(index-1)*2].label}}</span></h6>-->
<!--                    <template v-for="index2 in Math.ceil(fields[(index-1)*2].fields.length/2)" v-if="fields[(index-1)*2] && fields[(index-1)*2].fields">-->
<!--                        <div class="row ml-1">-->
<!--                            <div class="col-lg-4 col-md-4 col-sm-12" v-if="fields[(index-1)*2].fields[(index2-1)*2] && fields[(index-1)*2].fields[(index2-1)*2].type !== 'json'">-->
<!--                                {{coop[id][fields[(index-1)*2].fields[(index2-1)*2].db]}}-->
<!--                                <display-json-field :data="''" :description="fields[(index-1)*2].fields[(index2-1)*2].description" :label="fields[(index-1)*2].fields[(index2-1)*2].label"-->
<!--                                                    :mandatory="false" :pointer="null"/>-->
<!--                            </div>-->
<!--                            <div class="col-lg-4 col-md-4 col-sm-12" v-if="fields[(index-1)*2].fields[(index2-1)*2+1] && fields[(index-1)*2].fields[(index2-1)*2+1].type !== 'json'">-->
<!--&lt;!&ndash;                                {{coop[id][fields[(index-1)*2].fields[(index2-1)*2+1].db]}}&ndash;&gt;-->
<!--                                <display-json-field :data="''" :description="fields[(index-1)*2].fields[(index2-1)*2+1].description" :label="fields[(index-1)*2].fields[(index2-1)*2+1].label"-->
<!--                                                    :mandatory="false" :pointer="null"/>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <h6 class="text-divider text-left ml-3" v-if="fields[(index-1)*2].fields[(index2-1)*2] && fields[(index-1)*2].fields[(index2-1)*2].type === 'json'">-->
<!--                            <span class="pl-0">{{fields[(index-1)*2].fields[(index2-1)*2].label}}</span>-->
<!--                        </h6>-->
<!--                        <template v-for="index5 in Math.ceil(fields[(index-1)*2].fields[(index2-1)*2].fields.length/2)"-->
<!--                                  v-if="fields[(index-1)*2].fields[(index2-1)*2] && fields[(index-1)*2].fields[(index2-1)*2].fields">-->
<!--                            <div class="row ml-4">-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2] && fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2].description"-->
<!--                                                        :label="fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2+1] && fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2+1].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2+1].description"-->
<!--                                                        :label="fields[(index-1)*2].fields[(index2-1)*2].fields[(index5-1)*2+1].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <h6 class="text-divider text-left ml-3" v-if="fields[(index-1)*2].fields[(index2-1)*2+1] && fields[(index-1)*2].fields[(index2-1)*2+1].type === 'json'">-->
<!--                            <span class="pl-0">{{fields[(index-1)*2].fields[(index2-1)*2+1].label}}</span>-->
<!--                        </h6>-->
<!--                        <template v-for="index4 in Math.ceil(fields[(index-1)*2].fields[(index2-1)*2+1].fields.length/2)"-->
<!--                                  v-if="fields[(index-1)*2].fields[(index2-1)*2+1] && fields[(index-1)*2].fields[(index2-1)*2+1].fields">-->
<!--                            <div class="row ml-4">-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2] && fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2].description"-->
<!--                                                        :label="fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2+1] && fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2+1].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2+1].description"-->
<!--                                                        :label="fields[(index-1)*2].fields[(index2-1)*2+1].fields[(index4-1)*2+1].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </template>-->

<!--                    <div class="row">-->
<!--                        <div class="col-lg-4 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1] && fields[(index-1)*2+1].type !== 'json'">-->
<!--                            <display-json-field :data="''" :description="fields[(index-1)*2+1].description" :label="fields[(index-1)*2+1].label"-->
<!--                                                :mandatory="false" :pointer="null"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <h6 class="text-divider text-left" v-if="fields[(index-1)*2+1] && fields[(index-1)*2+1].type === 'json'"><span class="pl-0">{{fields[(index-1)*2+1].label}}</span></h6>-->
<!--                    <template v-for="index3 in Math.ceil(fields[(index-1)*2+1].fields.length/2)" v-if="fields[(index-1)*2+1] && fields[(index-1)*2+1].fields">-->
<!--                        <div class="row ml-1">-->
<!--                            <div class="col-lg-4 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1].fields[(index3-1)*2] && fields[(index-1)*2+1].fields[(index3-1)*2].type !== 'json'">-->
<!--                                <display-json-field :data="''" :description="fields[(index-1)*2+1].fields[(index3-1)*2].description" :label="fields[(index-1)*2+1].fields[(index3-1)*2].label"-->
<!--                                                    :mandatory="false" :pointer="null"/>-->
<!--                            </div>-->
<!--                            <div class="col-lg-4 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1].fields[(index3-1)*2+1] && fields[(index-1)*2+1].fields[(index3-1)*2+1].type !== 'json'">-->
<!--                                <display-json-field :data="''" :description="fields[(index-1)*2+1].fields[(index3-1)*2+1].description" :label="fields[(index-1)*2+1].fields[(index3-1)*2+1].label"-->
<!--                                                    :mandatory="false" :pointer="null"/>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <h6 class="text-divider text-left ml-3" v-if="fields[(index-1)*2+1].fields[(index3-1)*2] && fields[(index-1)*2+1].fields[(index3-1)*2].type === 'json'">-->
<!--                            <span class="pl-0">{{fields[(index-1)*2+1].fields[(index3-1)*2].label}}</span>-->
<!--                        </h6>-->
<!--                        <template v-for="index7 in Math.ceil(fields[(index-1)*2+1].fields[(index3-1)*2].fields.length/2)"-->
<!--                                  v-if="fields[(index-1)*2+1].fields[(index3-1)*2] && fields[(index-1)*2+1].fields[(index3-1)*2].fields">-->
<!--                            <div class="row ml-4">-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2] && fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2].description"-->
<!--                                                        :label="fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2+1] && fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2+1].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2+1].description"-->
<!--                                                        :label="fields[(index-1)*2+1].fields[(index3-1)*2].fields[(index7-1)*2+1].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <h6 class="text-divider text-left ml-3" v-if="fields[(index-1)*2+1].fields[(index3-1)*2+1] && fields[(index-1)*2+1].fields[(index3-1)*2+1].type === 'json'">-->
<!--                            <span class="pl-0">{{fields[(index-1)*2+1].fields[(index3-1)*2+1].label}}</span>-->
<!--                        </h6>-->
<!--                        <template v-for="index6 in Math.ceil(fields[(index-1)*2+1].fields[(index3-1)*2+1].fields.length/2)"-->
<!--                                  v-if="fields[(index-1)*2+1].fields[(index3-1)*2+1] && fields[(index-1)*2+1].fields[(index3-1)*2+1].fields">-->
<!--                            <div class="row ml-4">-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2] && fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2].description"-->
<!--                                                        :label="fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                                <div class="col-lg-3 col-md-4 col-sm-12" v-if="fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2+1] && fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2+1].type !== 'json'">-->
<!--                                    <display-json-field :data="''" :description="fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2+1].description"-->
<!--                                                        :label="fields[(index-1)*2+1].fields[(index3-1)*2+1].fields[(index6-1)*2+1].label" :mandatory="false"-->
<!--                                                        :pointer="null"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </template>-->
<!--                </template>-->
            </div>
        </div>
    </div>
</template>

<script>
    import DisplayJsonField from "../../common/DisplayJsonField";
    import CoopStatus from "../../common/CoopStatus";

    export default {
        name: "TabContent",
        props: ["name", "id", "fields", "coop"],
        components: {DisplayJsonField, CoopStatus}
    }
</script>

<style scoped>

</style>