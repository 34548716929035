import Vuex from 'vuex';
import User from './models/User';

export const LOGIN   = 'LOGIN';
export const LOGOUT  = 'LOGOUT';
export const REFRESH = 'REFRESH';
export const TERMS_AND_CONDITIONS_SEEN = 'TERMS_AND_CONDITIONS_SEEN'

const state = {
    user: User.from(localStorage["coops-token"]),
    token: localStorage.getItem("coops-token"),
    isLoggedIn: !!localStorage.getItem("coops-token")
};

const mutations = {
    [LOGIN]: (state, data) => {
        state.user     = User.from(data.token);
        state.orgIndex = 0;
        state.is_terms_and_condition_seen = false;
        localStorage.setItem("coops-token", data.token);
    },
    [LOGOUT]: (state) => {
        state.token = null;
        state.user  = null;
        state.is_terms_and_condition_seen = false;
        localStorage.clear();
    },
    [REFRESH]: (state, token) => {
        localStorage.setItem("coops-token", token);
        state.user     = User.from(token);
        state.orgIndex = 0;
    },
    [TERMS_AND_CONDITIONS_SEEN]: (state, is_seen) => {
        state.is_terms_and_condition_seen = is_seen
    },
};

const getters = {
    currentUser: state => {
        return state.user
    },
    isLoggedIn: state => {
        return state.isLoggedIn
    },
    termsAndConditionSeen: state => state.is_terms_and_condition_seen,
};

const actions = {
    login({commit}) {
        commit(LOGIN)
    },

    logout({commit}) {
        commit(LOGOUT)
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});