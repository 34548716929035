import {createWebHistory, createRouter} from "vue-router";
import User from "./models/User";

import AuthLayout from "./components/auth/Layout";
import SignIn from "./components/auth/SignIn";
import Welcome from "./components/Welcome";

import WorkspaceLayout from "./components/protected/Layout";
import Home from "./components/protected/Home";
import About from "./components/About";
import NotFound from "./components/NotFound";

import UserManagement from "./components/auth/UserManagement";
import PasswordManagement from "./components/auth/PasswordManagement";
import AddApprover from "./components/auth/AddApprover";
import AddClerk from "./components/auth/AddClerk";
import ChangePassword from "./components/auth/ChangePassword";

import CoopList from "./components/protected/coops/List";
import CoopImport from "./components/protected/coops/Import";
import CoopAdd from "./components/protected/coops/Add";
import CoopView from "./components/protected/coops/View";
import CoopEdit from "./components/protected/coops/Edit";

import AllList from "./components/protected/jobs/AllList";
import CompletedList from "./components/protected/jobs/CompletedList";
import FailedList from "./components/protected/jobs/FailedList";
import PendingList from "./components/protected/jobs/PendingList";

import ShgList from "./components/protected/shgs/ShgList";
import AddShg from "./components/protected/shgs/AddShg";
import ShgImport from "./components/protected/shgs/ImportShgs";
import EditShg from "./components/protected/shgs/EditShg";

import ReportLayout from "./components/protected/coops/reports/ReportLayout";
import ViewReport from "./components/protected/coops/reports/ViewReport";


const routes = [
    {
        path: "/",
        component: WorkspaceLayout,
        children: [
            {
                path: "",
                name: "home",
                component: Home,
                meta: {
                    title: 'page.dashboard.page_label',
                }
            },
            {
                path: "usermanagement",
                name: "user-management",
                component: UserManagement,
                meta: {
                    title: 'page.coop.page_label.user_management',
                }
            },
            {
                path: "passwordmanagement",
                name: "password-management",
                component: PasswordManagement,
                meta: {
                    title: 'page.coop.page_label.password_management',
                }
            },
            {
                path: "changepassword",
                name: "change-password",
                component: ChangePassword,
                meta: {
                    title: 'page.coop.page_label.change_password',
                }
            },
            {
                path: "addapprover",
                name: "add-approver-to-range",
                component: AddApprover,
                meta: {
                    title: 'page.coop.page_label.add_approver',
                }
            },
            {
                path: "addclerk",
                name: "add-clerk-to-range",
                component: AddClerk,
                meta: {
                    title: 'page.coop.page_label.add_clerk',
                }
            },
            {
                path: "coops",
                name: "coops",
                component: CoopList,
                meta: {
                    title: 'page.coop.page_label.coops',
                }
            },
            {
                path: "coops/report",
                name: "coops-report",
                component: ViewReport,
                meta: {
                    title: 'page.coop.page_label.report',
                }
            },
            {
                path: "coops/import",
                name: "importcoop",
                component: CoopImport,
                meta: {
                    title: 'page.coop.page_label.import',
                }
            },
            {
                path: "coops/add",
                name: "addcoop",
                component: CoopAdd,
                meta: {
                    title: 'page.coop.page_label.add',
                }
            },
            {
                path: "coops/:coopid/:coopnum",
                name: "viewcoop",
                component: CoopView,
                props: true,
                meta: {
                    title: 'page.coop.page_label.view',
                }
            },
            {
                path: "coops/:coopid/edit",
                name: "editcoop",
                component: CoopEdit,
                props: true,
                meta: {
                    title: 'page.coop.page_label.edit',
                }
            },
            {
                path: "jobs",
                name: "jobs",
                component: AllList,
                meta: {
                    title: 'page.job.page_label.jobs',
                }
            },
            {
                path: "jobs/completed",
                name: "jobscompleted",
                component: CompletedList,
                meta: {
                    title: 'page.job.page_label.completed_jobs',
                }
            },
            {
                path: "jobs/failed",
                name: "jobsfailed",
                component: FailedList,
                meta: {
                    title: 'page.job.page_label.failed_jobs',
                }
            },
            {
                path: "jobs/pending",
                name: "jobspending",
                component: PendingList,
                meta: {
                    title: 'page.job.page_label.pending_jobs',
                }
            },
            {
                path: "about",
                name: "about",
                component: About,
                meta: {
                    title: 'About Page title',
                }
            },
            {
                path: "shgs/add",
                name: "addshg",
                component: AddShg,
                meta: {
                    title: 'page.shg.page_label.add_shg',
                }
            },
            {
                path: "shgs",
                name: "shgs",
                component: ShgList,
                meta: {
                    title: 'page.shg.page_label.shgs',
                }
            },
            {
                path: "shgs/import",
                name: "importshg",
                component: ShgImport,
                meta: {
                    title: 'page.shg.page_label.import',
                }
            },
            {
                path: "shgs/:shgid/edit",
                name: "editshg",
                props: true,
                component: EditShg,
                meta: {
                    title: 'page.shg.page_label.edit_shg',
                }
            },
        ]
    },
    // {
    //     path: "/welcome",
    //     name: "welcome",
    //     component: Welcome,
    //     meta: {
    //         unsecured: true
    //     }
    // },
    {
        path: "/auth",
        component: AuthLayout,
        children: [
            {
                path: "signin",
                name: "signin",
                component: SignIn,
                meta: {
                    unsecured: true
                }
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
        meta: {
            unsecured: true
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if(!to.meta.unsecured) {
        let user = User.from(localStorage["coops-token"]);
        if(user) {
            next();
        } else {
            next({
                name: 'signin'
            });
        }
    } else {
        next();
    }
});

export default router;