<template>
    <div id="historyModal" class="modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">{{$filters.interpolate($t('page.coop.modal.history.header'), { coop: coopName || coopId})}}</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive" v-if="history.length > 0">
                        <table class="table mg-b-0">
                            <thead class="thead-light">
                            <tr>
                                <th class="text-center">{{$t('page.coop.status')}}</th>
                                <th>{{$t('page.coop.modal.remarks')}}</th>
                                <th>{{$t('page.coop.created_by')}}</th>
                                <th>{{$t('page.coop.modal.history.timestamp')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="h in history" class="pointer">
                                <td class="text-center"><coop-status :status="h.status"/></td>
                                <td>{{h.remarks}}</td>
                                <td>{{h.created_by_name}}</td>
                                <td>{{$filters.timestamp(h.created_at)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <template v-else>
                        <h5 class="text-center text-muted mt-5 mb-5">
                            {{$t('page.coop.modal.history.not_found')}}
                        </h5>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cancel" data-dismiss="modal">{{$t('page.coop.modal.history.close')}}</button>
                </div>
            </div>
        </div><!-- modal-dialog -->
    </div>
</template>

<script>
    import CoopStatus from "./CoopStatus";
    /**
     * Usage:
     * <history-modal ref="modal"></confirmation-modal>
     *
     * this.$refs.modal.show();
     * this.$refs.modal.hide();
     */
    export default {
        name: "HistoryModal",
        props: ["coopId", "history", "coopName"],
        components: {CoopStatus},
        mounted() {
            let self = this;
            $(document).ready(function() {
                $("#historyModal").on('hide.bs.modal', function() {
                    self.remarks = null;
                });
            });
        },
        methods: {
            cancel() {
                this.$emit('close');
            },
            show() {
                $('#historyModal').modal('show');
            },
            hide() {
                $('#historyModal').modal('hide');
            },
        }
    }
</script>

<style scoped>

</style>