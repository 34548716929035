<template>
    <div>
        <button class="btn btn-dark" @click="exportToCSV">Export to CSV</button>
    </div>
</template>
  
<script>
import Papa from 'papaparse';
import moment from "moment";

export default {
    props: ['data'],
    methods: {
        exportToCSV() {
            // Convert the data to CSV format using PapaParse
            const csv = Papa.unparse(this.data);

            // Create a Blob object with the CSV data
            const blob = new Blob([csv], { type: 'text/csv' });

            // Create a download link for the Blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `coop-${moment(new Date()).format("DD-MMM-YY h:mm a")}.csv`;

            // Trigger a click event on the download link
            link.click();
        },
    },
};
</script>
  