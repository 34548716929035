<template>
    <div class="az-body">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "Layout",
        data() {
            return {
                version: ""
            };
        },
        mounted() {
            // console.log(this.$parent);
        },
        methods: {
            release() {
                this.version = "1.0";
            }
        }
    }
</script>

<style scoped>
</style>
