<template>
    <div class="row row-sm">
        <div class="col-12">
            <div class="card card-dashboard-twelve">
                <div class="card-header border-bottom">
                    <h5 class="card-title">{{$t('page.shg.card_label.available_shgs')}}</h5>
                </div>
                <div class="card-body">
                    <div class="form-row mb-1">
                        <div class="col-lg-3 col-md-4 mb-1">
                            <Multiselect :close-on-select="true"
                                         @select="getShgs"
                                         :options="coops"
                                         :placeholder="$t('page.shg.coop.placeholder')"
                                         :searchable="true"
                                         @search-change="getCoops($event)"
                                         class="multiselect-blue"
                                         v-model="coop_id"/>
                        </div><!-- col -->
                        <div @click="resetFilter()" class="col-lg-3 col-md-4 mb-1">
                            <button class="btn btn-secondary h-75" type="button">
                                {{$t('page.coop.button.reset')}}
                            </button>
                        </div><!-- col -->
                    </div>
                    <div class="table-responsive" v-if="total > 0">
                        <table class="table table-hover mg-b-0">
                            <thead>
                            <tr>
                                <th>{{$t('page.shg.name.text')}}</th>
                                <th>{{$t('page.shg.coop.text')}}</th>
                                <th>{{$t('page.shg.contact_no.text')}}</th>
                                <th>{{$t('page.shg.no_of_members.text')}}</th>
                                <th>{{$t('page.shg.created_by')}}</th>
                                <th>{{$t('page.shg.created_at')}}</th>
                                <th>{{$t('page.shg.action')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :class="{'selected-row': selectedRow === index}" @click="getShg(index, shg)" class="pointer" v-bind:key="index"
                                v-for="(shg, index) in shgs">
                                <td>{{shg.name}}</td>
                                <td>{{shg.coop_name}}&nbsp;({{shg.coop_registration.number}})</td>
                                <td>{{shg.contact_number}}</td>
                                <td>{{shg.no_of_members}}</td>
                                <td>{{shg.created_by_name}}</td>
                                <td>{{ $filters.timestamp(shg.created_at)}}</td>
                                <td>
                                    <a @click="shg_id_to_delete = shg.shg_id" class="text-danger" data-target="#deleteConfirmModal" data-toggle="modal" href="javascript:void(0);">
                                        <i class="fa fa-trash-alt"/>
                                    </a>
                                    <router-link :to="{name: 'editshg', params: {shgid: shg.shg_id}}" class="ml-3 text-info">
                                        <i class="fa fa-pencil-alt"/>
                                    </router-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <template v-else>
                        <h5 class="text-center text-muted mt-5">
                            {{$t('page.shg.not_found')}}
                        </h5>
                        <h6 class="text-center text-muted mb-5">
                            {{$t('page.shg.not_found_sub')}}
                        </h6>
                    </template>
                </div>
                <div class="card-footer">
                    <pagination :page="page" :total="total" @pager-change="handlePagerChange" v-if="shgs.length > 0"/>
                </div>
            </div>
        </div>

        <div class="col-12 mg-t-15" v-if="shg.shg_id">
            <div class="card card-dashboard-twelve">
                <div class="card-header border-bottom d-flex justify-content-between">
                    <h5 class="card-title">{{shg.name }}</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.name" :mandatory="true" :pointer="null" label="page.shg.name"/>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.contact_number" :mandatory="true" :pointer="null" label="page.shg.contact_no"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.coop_name" :mandatory="true" :pointer="null" label="page.shg.coop_name"/>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.coop_registration.number" :mandatory="true" :pointer="null" label="page.shg.coop_registration"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.no_of_members" :mandatory="true" :pointer="null" label="page.shg.no_of_members"/>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.created_by_name" :mandatory="true" :pointer="null" label="page.shg.created_by_name"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.address" :mandatory="true" :pointer="null" label="page.shg.address"/>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <display-field :data="shg.notes" :mandatory="true" :pointer="null" label="page.shg.notes"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div aria-hidden="true" class="modal fade" id="deleteConfirmModal" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remove SHG
                        </h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{$t('page.shg.delete_message')}}
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" type="button">
                            No
                        </button>
                        <button @click="removeShg" class="btn btn-primary" type="button">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "../../Pagination";
    import DisplayField from "../../common/DisplayField";
    import Multiselect from '@vueform/multiselect'

    export default {
        name: "ShgList",
        components: {Pagination, DisplayField, Multiselect},
        data() {
            return {
                options: [
                    {
                        value: 'captainamerica',
                        name: 'Captain America',
                        icon: 'https://cdn2.iconfinder.com/data/icons/avengers-filled/48/03_-_Captain_America_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png'
                    },
                    {
                        value: 'spiderman',
                        name: 'Spiderman',
                        icon: 'https://cdn2.iconfinder.com/data/icons/avengers-filled/48/12_-_Spiderman_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png'
                    },
                    {
                        value: 'ironman',
                        name: 'Iron Man',
                        icon: 'https://cdn2.iconfinder.com/data/icons/avengers-filled/48/02_-_IRONMAN_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png'
                    },
                ],
                shgs: [],
                page: 0,
                perPage: localStorage.getItem('pageSize') || 10,
                total: 0,
                coops: [],
                coop_id: null,
                shg_id_to_delete: null,
                selected_shg: null,
                shg: {
                    shg_id: null,
                    coop_name: null,
                    coop_registration: {
                        number: null
                    },
                    name: null,
                    address: null,
                    contact_number: null,
                    no_of_members: null,
                    created_by_name: null,
                    notes: null
                },
                selectedRow: null
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getCoops(),
                    this.getShgs()
                ])
            },
            async getCoops(search) {
                try {
                    let {data} = await this.$api('coops', {
                        params: {
                            is_valid: true,
                            page: 0,
                            per_page: 1000,
                            search: search
                        }
                    });
                    this.coops = data.coops.map(coop=> ({
                        value: coop.coop_id,
                        label: coop.name+' ('+coop.registration.number+')'
                    }));
                } catch (e) {

                }
            },
            async getShgs() {
                try {
                    let params = {
                        page: this.page,
                        per_page: this.perPage,
                        coop_id: this.coop_id
                    }
                    let {data} = await this.$api('shgs', {params: params});
                    this.shgs = data.shgs;
                    this.total = data.total;
                    this.shg = this.shgs.length > 0 ? this.shgs[0] : this.shg;
                    this.selectedRow = 0;
                } catch (e) {
                    console.error(e);
                    this.$toast.error(this.$t('toastr.no-shg-msg'));
                }
            },
            async handlePagerChange(e) {
                this.page = e.page;
                this.perPage = e.per_page;
                await this.getShgs();
            },
            resetFilter() {
                this.coop_id = null;
                this.getShgs();
            },
            async removeShg() {
                try {
                    await this.$api.delete(`/shgs/${this.shg_id_to_delete}`);
                    this.$toast.success(this.$t('toastr.remove-shg-msg'));
                    await this.getShgs();
                } catch (e) {
                    this.$toast.success(this.$t('toastr.remove-shg-err-msg'));
                } finally {
                    $('#deleteConfirmModal').modal('hide');
                }
            },
            getShg(index, shg) {
                if (this.selectedRow === index) {
                    this.selectedRow = -1;
                    this.shg = {
                        shg_id: null,
                        coop_name: null,
                        coop_registration: {
                            number: null
                        },
                        name: null,
                        address: null,
                        contact_number: null,
                        no_of_members: null,
                        created_by_name: null,
                        notes: null
                    };
                } else {
                    this.shg = shg;
                    this.selectedRow = index;
                }
            },
        }
    }
</script>

<style scoped>
    .selected-row {
        background-color: #ccd2cb;
    }

</style>